import React from 'react';
import { Row, Col, Modal, Button, Divider, Form, Input } from 'antd';
import useForm from '../hooks/useForm';
import { SelectPelanggaranSiswa, SelectRombel, SelectSemester, SelectTahunAjaran } from 'components/select';
import { Date } from 'components/datetime';
import { useParams } from 'react-router-dom';
import { _fmtRupiah } from 'lib/Helper';
import { UploadFile } from 'components/upload-files';

const { TextArea } = Input;
const ModalForm = ({ data = {}, detailState = {}, visible, hideModal, fetchTable }) => {
    const [form] = Form.useForm(); // Initialize form instance
    const { tahun_ajaran_id, semester_id, rombel_id } = useParams();

    const {
        values,
        loading,
        handleChangeSetValue,
        handleSubmit,
    } = useForm(data, detailState, fetchTable, hideModal);

    return (
        <Modal
            maskClosable={false}
            keyboard={false}
            title="Form Pelanggaran Siswa"
            open={visible}
            onCancel={hideModal}
            closable={false}
            footer={null} // Remove footer from Modal
        >
            <Form
                form={form}
                name="basic"
                autoComplete="off"
                layout="vertical"
                onFinish={handleSubmit}
                loading={loading}
                initialValues={values}
            >
                <Row gutter={[16, 0]}>
                    <Col xs={24}>
                        <Form.Item
                            label="Tahun Ajaran"
                            name="tahun_ajaran_id"
                            rules={[{ required: true, message: 'Tahun Ajaran harus diisi!' }]}>
                            <SelectTahunAjaran
                                allowClear
                                disabled={true}
                                defaultValue={tahun_ajaran_id || data.tahun_ajaran_id || null}
                                onChange={(val) => {
                                    handleChangeSetValue("tahun_ajaran_id", val || null);
                                    handleChangeSetValue("semester_id", null);
                                    handleChangeSetValue("rombel_id", null);
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Semester"
                            name="semester_id"
                            rules={[{ required: true, message: 'Semester harus diisi!' }]}>
                            <SelectSemester
                                allowClear
                                tahunAjaranId={tahun_ajaran_id || data.tahun_ajaran_id || null}
                                disabled={true}
                                defaultValue={semester_id || data.semester_id || null}
                                onChange={(val) => {
                                    handleChangeSetValue("semester_id", val || null);
                                    handleChangeSetValue("rombel_id", null);
                                }}
                            />
                        </Form.Item>
                        <Form.Item label="Rombel" name="rombel_id" rules={[{ required: true, message: 'Rombel harus diisi!' }]}>
                            <SelectRombel
                                allowClear
                                tahunAjaranId={tahun_ajaran_id || data.tahun_ajaran_id || null}
                                semesterId={semester_id || data.semester_id || null}
                                disabled={true}
                                defaultValue={rombel_id || data.rombel_id || null}
                                onChange={(val) => {
                                    handleChangeSetValue("rombel_id", val || null);
                                }}
                            />
                        </Form.Item>
                        <Form.Item label="Tanggal" name="tgl" rules={[{ required: true, message: 'Tanggal harus diisi!' }]}>
                            <Date onChange={(val) => handleChangeSetValue('tgl', val)} />
                        </Form.Item>
                        <Form.Item label="Pelanggaran Siswa" name="pelanggaran_id" rules={[{ required: true, message: 'Pelanggarang harus dipilih!' }]}>
                            <SelectPelanggaranSiswa onChange={(val, selectedData) => {
                                const newPoint = selectedData.data.poin ? parseInt(selectedData.data.poin) : 0
                                let existingPoint = (detailState.total_poin ? parseInt(detailState.total_poin) : 0) - (data.poin ? parseInt(data.poin) : 0)
                                existingPoint = existingPoint < 0 ? 0 : existingPoint
                                handleChangeSetValue("poin", newPoint)
                                handleChangeSetValue("total_poin", newPoint + existingPoint)
                                handleChangeSetValue('pelanggaran_id', val)
                            }} />
                        </Form.Item>
                        <Form.Item label="Poin">
                            <Input
                                style={{ width: '100%' }}
                                name="poin"
                                placeholder="0"
                                disabled
                                value={values.poin ? _fmtRupiah(values.poin) : null}
                                onChange={(e) => {
                                    handleChangeSetValue("poin", e.target.value)
                                }} />
                        </Form.Item>
                        <Form.Item label="Total Poin">
                            <Input
                                style={{ width: '100%' }}
                                name="total_poin"
                                placeholder="0"
                                disabled
                                value={values.total_poin ? _fmtRupiah(values.total_poin) : null}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Keterangan"
                            name="keterangan">
                            <TextArea rows={4} onChange={(e) => handleChangeSetValue('keterangan', e.target.value)} />
                        </Form.Item>
                        <Form.Item label="File" name="file" rules={[{ required: data.id ? false : true, message: 'File harus diisi!' }]}>
                            <UploadFile
                                isValidation={true}
                                type="jpg, png & pdf"
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Divider />
                        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
                            <Button onClick={hideModal} disabled={loading}>Batal</Button>
                            <Button type="primary" htmlType="submit" disabled={loading} loading={loading}>Simpan</Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default ModalForm;