import React from 'react';
import { Table, Row, Col, Pagination } from 'antd';
import { getParamTable } from '../../lib/Helper';

class DesktopOnly extends React.Component {
  
  componentDidMount() {
    const {isFetchFirst = true} = this.props;
    if(isFetchFirst){
      this.props.fetchTable();
    }
  }

  render() {
    const {columns, state, isPagination = true} = this.props;
    const { table } = state;

    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Table
              columns={columns}
              rowKey={record => record.id}
              dataSource={table.data}
              pagination={false}
              loading={table.loading}
              size="small"
              rowClassName={(record, index) => {
                return record[table.flagRed] && record[table.flagRed]?.toLowerCase() === table.flagRedValue?.toLowerCase() ? "table-row-red" : ""
              }} 
              scroll={table.scroll ? { x: table.scroll?.x, y: table.scroll?.y } : false}
            />
          </Col>
          {isPagination && <Col xs={24} className="table-pagination">
            <Pagination
              size="small"
              current={table.pagination.current}
              pageSize={table.pagination.pageSize}
              total={table.pagination.total}
              showTotal={() => `Total ${table.pagination.total} items`}
              showSizeChanger
              onChange={(current, pageSize) => {
                const pagination = { current: current, pageSize: pageSize, total: table.pagination.total };
                const params = getParamTable("change", table, null, null, pagination);
                this.props.fetch(params);
              }}
            />
          </Col>}
        </Row>
      </>
    );
  }
}

export default DesktopOnly;
