import { _tableLogActivity } from "lib/Helper";
import Action from "./Action";
import { SettingOutlined } from "@ant-design/icons";

const columns = (action, modalForm, modalDelete) => [
  {
    title: 'No',
    dataIndex: 'rownum',
    sorter: false,
    width: '2%',
  }, {
    title: 'Tahun Pelajaran',
    dataIndex: 'tahun_ajaran',
    sorter: false,
  }, {
    title: 'Semester',
    dataIndex: 'tipe_semester',
    sorter: false,
  }, {
    title: 'Tingkat',
    dataIndex: 'tingkatan_kelas',
    sorter: false,
  }, {
    title: <SettingOutlined />,
    align: "center",
    key: 'operation',
    width: '2%',
    render: (a, row) => <Action row={row} action={action} modalForm={modalForm} modalDelete={modalDelete}/>,
  },
  {
    title: 'LogActivity',
    key: 'operation',
    width: '10%',
    render: (a, row) => _tableLogActivity(row),
  },
];

export default columns