import React, { useState } from 'react';
import { Row, Col, Modal, Button, Divider, Form, InputNumber, Checkbox, Input } from 'antd';
import useForm from '../hooks/useForm';
import { SelectMataPelajaran } from 'components/select';
import { regexAddComma, regexRemoveComma } from 'utils/utils_regex';
const ModalForm = ({ data = {}, visible, hideModal, fetchTable }) => {
    const [form] = Form.useForm(); // Initialize form instance
    const [options, setOptions] = useState([]);
    const predikat = ["A", "B", "C", "D"];

    const {
        values,
        loading,
        handleChangeSetValue,
        handleSubmit,
    } = useForm(data, fetchTable, hideModal, form);

    console.log("values", values);
    return (
        <Modal
            maskClosable={false}
            keyboard={false}
            title="Form Nilai KKM/KTTP"
            open={visible}
            onCancel={hideModal}
            closable={false}
            footer={null}
        >
            <Form
                form={form}
                name="basic"
                autoComplete="off"
                layout="vertical"
                onFinish={handleSubmit}
                loading={loading}
                initialValues={values}
            >
                <Row gutter={[16, 0]}>
                    <Col xs={24}>
                        <Form.Item
                            label="Nilai KKM/KTTP"
                            name={"nilai"}
                            rules={[{ required: true, message: 'Nilai KKM/KTTP harus diisi' }]}>
                            <InputNumber
                                style={{ width: '100%' }}
                                placeholder='0'
                                controls={false}
                                formatter={regexAddComma}
                                parser={regexRemoveComma}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Mata Pelajaran"
                            name="list_mata_pelajaran"
                            extra={<>
                                Klik checkbox untuk memilih semua mata pelajaran
                                <Checkbox
                                    style={{ marginLeft: '4px' }}
                                    onChange={(e) => {
                                        const checked = e.target.checked;
                                        form.setFieldValue("list_mata_pelajaran", checked ? options.map(option => option.value) : []);
                                    }}
                                />
                            </>}
                            rules={[{ required: true, message: 'Tingkatan Kelas harus dipilih!' }]}>
                            <SelectMataPelajaran
                                mode="multiple"
                                setOptions={setOptions}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        {predikat.map((item) => (
                            <Form.Item key={item} label={`Predikat ${item}`} required>
                                <Row gutter={16}>
                                    <Col span={11}>
                                        <Form.Item
                                            name={[item, "start_value"]}
                                            rules={[{ required: true, message: `Masukkan Start Value untuk Predikat ${item}` }]}
                                            noStyle
                                        >
                                            <InputNumber
                                                placeholder="0"
                                                style={{ width: "100%" }}
                                                min={0}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={2} style={{ textAlign: "center" }}>
                                        <span> - </span>
                                    </Col>
                                    <Col span={11}>
                                        <Form.Item
                                            name={[item, "end_value"]}
                                            rules={[{ required: true, message: `Masukkan End Value untuk Predikat ${item}` }]}
                                            noStyle
                                        >
                                            <InputNumber
                                                placeholder="0"
                                                style={{ width: "100%" }}
                                                min={0}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form.Item>
                        ))}
                    </Col>
                    <Col xs={24}>
                        <Divider />
                        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
                            <Button onClick={hideModal} disabled={loading}>Batal</Button>
                            <Button type="primary" htmlType="submit" disabled={loading} loading={loading}>Simpan</Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default ModalForm;