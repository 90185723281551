import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { _setAxios } from 'lib/Helper';

async function fetchList(isBantaraCompleted = false) {

  return await _setAxios("kaldik-kegiatan/pramuka/master-sku/dropdown", "POST", {
    agama: "Islam",
    jenis_penegak: isBantaraCompleted ? "Penegak Laksana" : "Penegak Bantara"
  })
    .then(body => {
      let results = []
      body.data?.data?.forEach(row => {
        results.push({
          value: row.id,
          label: row.uraian_kecakapan
        })
      });

      return results
    })
}

const SelectKecakapan = ({ ...props }) => {
  const [options, setOptions] = useState([]);
  let isBantaraCompleted = false;
  if (props.total_bantara) {
    const [start, end] = props.total_bantara.split("/").map(Number);
    if (start === end) {
      isBantaraCompleted = true;
    }
  }

  useEffect(() => {
    fetchList(isBantaraCompleted).then((newOptions) => {
      setOptions(newOptions);
    });
  }, [isBantaraCompleted]);

  return (
    <Select
      {...props}
      allowClear
      showSearch
      placeholder="Pilih kecakapan"
      optionFilterProp="children"
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      style={{
        width: '100%',
      }}
      options={options}
    />
  );
};
export default SelectKecakapan;
