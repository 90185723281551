import React from 'react';
import { Table, Input, Row, Col } from 'antd';
import './style.css';

const columns = [
  {
    title: 'Predikat',
    dataIndex: 'label',
    key: 'label',
  },
  {
    title: 'Nilai',
    children: [
      {
        title: 'Min',
        dataIndex: 'start_value',
        key: 'start_value',
      },
      {
        title: 'Maks',
        dataIndex: 'end_value',
        key: 'end_value',
      },
    ],
  },
];

const NilaiKKM = ({ kkmData }) => {
  return (
    <div className="container">
      <span>Nilai KKM Tingkat ditentukan oleh Admin, Jika predikat belum muncul, konfirmasi ke Admin.</span>
      <Row className="input-row" gutter={[16, 16]}>
        <Col span={12}>
          <span>KKM/KTTP :</span>
        </Col>
        <Col span={12}>
          <Input className="input-box" value={kkmData?.predikat?.nilai || 0} readOnly />
        </Col>
      </Row>
      <Table
        size='small'
        className="grade-table"
        dataSource={kkmData?.predikat?.list || []}
        columns={columns}
        pagination={false}
        bordered
      />
    </div>
  )
};

export default NilaiKKM;