import React from 'react';
import { Button, Dropdown } from 'antd';
import { DownCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';

class Action extends React.Component {
    render() {
        const { row, action } = this.props;
        const items = [];

        if (!action.detail) {
            return "-"
        }

        if (action.detail) {
            items.push({
                key: '3',
                label: "Detail",
                icon: <InfoCircleOutlined />,
                onClick: () => {
                    this.props.history.push("/kesiswaan/penghargaan-siswa/detail/" + row.id + "/" + row.tahun_ajaran_id + "/" + row.semester_id + "/" + row.rombel_id)
                },
            })
        }

        return (
            <Dropdown
                trigger={['click']}
                menu={{ items }}
            >
                <Button type="primary">
                    Opsi <DownCircleOutlined style={{ fontSize: 11 }} />
                </Button>
            </Dropdown >
        );
    }
}


export default React.memo(withRouter(Action));
