import React from 'react';
import { Row, Col, Modal, Button, Divider, Form } from 'antd';
import useForm from '../hooks/useForm';
import { SelectTingkatanKelas, SelectSemester, SelectTahunAjaran } from 'components/select';
const ModalForm = ({ data = {}, visible, hideModal, fetchTable }) => {
    const [form] = Form.useForm(); // Initialize form instance

    const {
        values,
        loading,
        handleChangeSetValue,
        handleSubmit,
    } = useForm(data, fetchTable, hideModal);

    return (
        <Modal
            maskClosable={false}
            keyboard={false}
            title="Form KKM/KTTP"
            open={visible}
            onCancel={hideModal}
            closable={false}
            footer={null}
        >
            <Form
                form={form}
                name="basic"
                autoComplete="off"
                layout="vertical"
                onFinish={handleSubmit}
                loading={loading}
                initialValues={values}
            >
                <Row gutter={[16, 0]}>
                    <Col xs={24}>
                        <Form.Item
                            label="Tahun Ajaran"
                            name="tahun_ajaran_id"
                            rules={[{ required: true, message: 'Tahun Ajaran harus dipilih!' }]}>
                            <SelectTahunAjaran
                                disabled={data.id ? true : false}
                                onChange={(val) => {
                                    handleChangeSetValue("tahun_ajaran_id", val || null);
                                    handleChangeSetValue("semester_id", null);
                                    form.setFieldValue('semester_id', null);
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Semester"
                            name="semester_id"
                            rules={[{ required: true, message: 'Semester harus dipilih!' }]}>
                            <SelectSemester
                                tahunAjaranId={values.tahun_ajaran_id}
                                disabled={!values.tahun_ajaran_id || data.id ? true : false}
                            />
                        </Form.Item>
                        <Form.Item label="Tingkatan Kelas" name="tingkatan_kelas" rules={[{ required: true, message: 'Tingkatan Kelas harus dipilih!' }]}>
                            <SelectTingkatanKelas/>
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Divider />
                        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
                            <Button onClick={hideModal} disabled={loading}>Batal</Button>
                            <Button type="primary" htmlType="submit" disabled={loading} loading={loading}>Simpan</Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default ModalForm;