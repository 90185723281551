import React from 'react';
import { Select } from 'antd';

const SelectModulNotif = ({ ...props }) => {
  return (
    <Select
      placeholder="Pilih modul"
      style={{
        width: '100%',
      }}
      options={[
        {
          value: 'FORGOT PASSWORD',
          label: 'Forgot Password',
        }, {
          value: 'VERIFIKASI ALUMNI',
          label: 'Verifikasi Alumni',
        }
      ]}
      {...props}
    />
  );
};
export default SelectModulNotif;
