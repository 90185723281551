// useForm.js
import { useState } from 'react';
import { _setAxios, _success } from 'lib/Helper';

const useLockUnlock = (data, fetchTable, hideModal) => {
    const [loading, setLoading] = useState(false);

    const handleSubmit = (val) => {
        setLoading(true);
        let endpoint = "penilaian/mapel/status/master/editable";
        let method = "POST";

        _setAxios(endpoint, method, {
            "tahun_ajaran_id" : data.tahun_ajaran_id,
            "semester_id" : data.semester_id,
            "rombel_id" : data.rombel_id,
            "status" : data.nilai_status_edit?.toLowerCase() === "nilai belum terkunci" ? "0" : "1"
        }).then(resp => {
            if (resp.status) {
                _success('topRight', 'Success', resp.data.message)
                fetchTable(true);
                hideModal();
            }
            setLoading(false);
        }).catch(err => {
            setLoading(false);
        });
    };

    return {
        loading,
        handleSubmit,
    };
};

export default useLockUnlock;
