import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, BackTop, Breadcrumb, Alert, Badge } from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';
import { TableList } from 'components/list';
import Filter from '../modal/Filter';
import ModalLockUnlock from '../modal/LockUnlock';
import SearchTable from 'components/search-table/SearchTable';
import { PageHeader } from 'components/header';
import { SortingTableWithFilter } from 'components/sorting-table';
import columns from './Columns';
import useList from '../hooks/useList';
import { isAuth } from 'lib/Helper';
import AuthRedirect from 'components/AuthRedirect';

const sortFields = [{
  value: "id",
  label: "Tanggal Pembuatan",
}];

const PenilaianStatusNilai = ({ privilege }) => {
  const {
    action,
    table,
    filterState,
    lockUnlockState,
    fetchTable,
    fetchData,
    setTempTableFilter,
    setTableFilterFromTemp,
    modalFilter,
    modalLockUnlock,
  } = useList(privilege);

  if (!isAuth(privilege) || (action === undefined)) {
    return <AuthRedirect />
  }

  return (
    <>
      <PageHeader
        style={{
          padding: 0
        }}
        onBack={() => window.history.back()}
        title={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
          <Breadcrumb.Item>Penilaian</Breadcrumb.Item>
          <Breadcrumb.Item>Status Nilai </Breadcrumb.Item>
        </Breadcrumb>}
      />
      <Alert type="info" message="Data bertanda merah menunjukkan adanya guru mapel yang belum mengirimkan penilaian di rombel tersebut. Klik Detail untuk melihat mata pelajaran yang belum dinilai, lalu hubungi guru terkait agar segera menyelesaikan proses penilaian." />

      <div className="site-layout-background" style={{ padding: 24, minHeight: 360, marginTop: 16 }}>

        <PageHeader
          className="site-page-header"
          title="Status Nilai"
          subTitle="Data status nilai" />

        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Badge color={"#ff000057"} text={"Guru mapel yang belum mengirimkan penilaian"} />
          </Col>
          <Col xs={24} sm={18} md={19}>
            <SearchTable table={table} fetch={fetchData} />
          </Col>
          <Col xs={24} sm={6} md={5}>
            <SortingTableWithFilter
              table={table}
              fetch={fetchData}
              modalFilter={modalFilter}
              sortFields={sortFields}
            />
          </Col>
          <Col xs={24}>
            <Alert type="info" message="Pilih semua filter terlebih dahulu, untuk menampilkan data." />
            <TableList
              state={{ table, filterState }}
              fetch={fetchData}
              fetchTable={fetchTable}
              columns={columns(action, modalLockUnlock)}
            />
          </Col>
        </Row>
      </div>
      {
        filterState.visible && (
          <Filter
            visible={filterState.visible}
            hideModal={() => modalFilter(false)}
            setTempTableFilter={setTempTableFilter}
            setTableFilterFromTemp={setTableFilterFromTemp}
            values={filterState.values}
          />
        )
      }
      {
        lockUnlockState.visible && (
          <ModalLockUnlock
            data={lockUnlockState.data}
            visible={lockUnlockState.visible}
            hideModal={() => modalLockUnlock(false)}
            fetchTable={fetchTable}
          />
        )
      }
      <BackTop />
    </>
  );
};

const mapStateToProps = (state) => ({
  privilege: state.privilege,
});

export default connect(mapStateToProps)(PenilaianStatusNilai);