import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, BackTop, Breadcrumb, Badge } from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';
import { TableList } from 'components/list';
import Filter from '../modal/Filter';
import ModalSend from '../modal/Send';
import SearchTable from 'components/search-table/SearchTable';
import { PageHeader } from 'components/header';
import { FilterTable } from 'components/sorting-table';
import columns from './Columns';
import useList from '../hooks/useList';
import { isAuth } from 'lib/Helper';
import AuthRedirect from 'components/AuthRedirect';

const sortFields = [{
  value: "id",
  label: "Tanggal Pembuatan",
}];

const PenilaianNilaiMapel = ({ privilege }) => {
  const {
    action,
    table,
    filterState,
    sendState,
    fetchTable,
    fetchData,
    setTempTableFilter,
    setTableFilterFromTemp,
    modalFilter,
    modalSend,
  } = useList(privilege);

  if (!isAuth(privilege) || (action === undefined)) {
    return <AuthRedirect />
  }

  return (
    <>
      <PageHeader
        style={{
          padding: 0
        }}
        onBack={() => window.history.back()}
        title={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
          <Breadcrumb.Item>Penilaian</Breadcrumb.Item>
          <Breadcrumb.Item>Nilai Mapel </Breadcrumb.Item>
        </Breadcrumb>}
      />

      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>

        <PageHeader
          className="site-page-header"
          title="Nilai Mapel"
          subTitle="Data nilai mata pelajaran" />

        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Badge color={"#ff000057"} text={"Guru mapel yang belum mengirimkan penilaian"} />
          </Col>

          <Col xs={24} sm={18} md={19}>
            <SearchTable table={table} fetch={fetchData} />
          </Col>
          <Col xs={24} sm={6} md={5}>
            <FilterTable
              table={table}
              fetch={fetchData}
              modalFilter={modalFilter}
              sortFields={sortFields}
              isSortingDisabled={true}
            />
          </Col>
          <Col xs={24}>
            <TableList
              state={{ table, filterState }}
              fetch={fetchData}
              fetchTable={fetchTable}
              columns={columns(action, modalSend)}
            />
          </Col>
        </Row>
      </div>
      {
        filterState.visible && (
          <Filter
            visible={filterState.visible}
            hideModal={() => modalFilter(false)}
            setTempTableFilter={setTempTableFilter}
            setTableFilterFromTemp={setTableFilterFromTemp}
            values={filterState.values}
          />
        )
      }
      {
        sendState.visible && (
          <ModalSend
            data={sendState.data}
            visible={sendState.visible}
            hideModal={() => modalSend(false)}
            fetchTable={fetchTable}
          />
        )
      }
      <BackTop />
    </>
  );
};

const mapStateToProps = (state) => ({
  privilege: state.privilege,
});

export default connect(mapStateToProps)(PenilaianNilaiMapel);