import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, BackTop, Button, Breadcrumb } from 'antd';
import { DoubleRightOutlined, PlusOutlined } from '@ant-design/icons';
import { TableList } from 'components/list';
import ModalForm from '../modal/Form';
import Filter from '../modal/Filter';
import ModalDelete from '../modal/Delete';
import SearchTable from 'components/search-table/SearchTable';
import { PageHeader } from 'components/header';
import columns from './Columns';
import useList from '../hooks/useList';
import { isAuth } from 'lib/Helper';
import AuthRedirect from 'components/AuthRedirect';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { SortingTableOnly } from 'components/sorting-table';
import HeaderKKM from '../../list/HeaderKKM';

const sortFields = [{
  value: "id",
  label: "Tanggal Pembuatan",
}];

const PenilaianKkmKttpDetail = ({ privilege, match }) => {
  const {
    action,
    table,
    formState,
    filterState,
    deleteState,
    fetchTable,
    fetchData,
    setTempTableFilter,
    setTableFilterFromTemp,
    modalFilter,
    modalForm,
    modalDelete,
  } = useList(privilege, match);

  if (!isAuth(privilege) || (action === undefined || action.detail === undefined)) {
    return <AuthRedirect />
  }

  return (
    <>
      <PageHeader
        style={{
          padding: 0
        }}
        onBack={() => window.history.back()}
        title={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
          <Breadcrumb.Item>Penilaian</Breadcrumb.Item>
          <Breadcrumb.Item><Link to="/penilaian/kkm-kttp">KKM/KTTP</Link> </Breadcrumb.Item>
          <Breadcrumb.Item>Detail </Breadcrumb.Item>
        </Breadcrumb>}
      />

      <HeaderKKM />
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>

        <PageHeader
          className="site-page-header"
          title="Detail Nilai KKM"
          extra={[
            action.create && (
              <Button
                type='primary'
                onClick={() => modalForm(true)}
                icon={<PlusOutlined />}>
                Tambah
              </Button>
            ),
          ]} />

        <Row gutter={[16, 16]}>
          <Col xs={24} sm={18} md={19}>
            <SearchTable table={table} fetch={fetchData} />
          </Col>
          <Col xs={24} sm={6} md={5}>
            <SortingTableOnly
              table={table}
              fetch={fetchData}
              modalFilter={modalFilter}
              sortFields={sortFields}
            />
          </Col>
          <Col xs={24}>
            <TableList
              state={{ table, filterState }}
              fetch={fetchData}
              fetchTable={fetchTable}
              columns={columns(action, modalForm, modalDelete)}
            />
          </Col>
        </Row>
      </div>
      {
        formState.visible && (
          <ModalForm
            data={formState.data}
            visible={formState.visible}
            hideModal={() => modalForm(false)}
            fetchTable={fetchTable}
          />
        )
      }
      {
        filterState.visible && (
          <Filter
            visible={filterState.visible}
            hideModal={() => modalFilter(false)}
            setTempTableFilter={setTempTableFilter}
            setTableFilterFromTemp={setTableFilterFromTemp}
            values={filterState.values}
          />
        )
      }
      {
        deleteState.visible && (
          <ModalDelete
            data={deleteState.data}
            visible={deleteState.visible}
            hideModal={() => modalDelete(false)}
            fetchTable={fetchTable}
          />
        )
      }
      <BackTop />
    </>
  );
};

const mapStateToProps = (state) => ({
  privilege: state.privilege,
});

export default connect(mapStateToProps)(PenilaianKkmKttpDetail);