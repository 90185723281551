import { useCallback, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { getParamTable, getParamTempTableFiltering, _setAxios } from 'lib/Helper';

const initialTableState = () => ({
    loading_first: false,
    loading: false,
    data: [],
    search: "",
    pagination: {
        current: 1,
        pageSize: 10,
        total: null,
    },
    sorting: [],
    filtering: {
    },
    flagRed: "status",
    flagRedValue: "-"
});

const useList = (privilege) => {
    const { tahun_ajaran, semester, access } = privilege
    const { action } = access["/penilaian/nilai-mapel"] || {}

    const [table, setTable] = useState({
        ...initialTableState(),
        filtering: {
            ...initialTableState().filtering,
            tahun_ajaran_id: tahun_ajaran?.id,
            semester_id: semester?.id
        },
    });
    const [filterState, setFilterState] = useState({
        values: {
            ...table.filtering
        }, visible: false
    });
    const [sendState, setSendState] = useState({ visible: false, data: {} });

    const fetchTable = (isNew = false) => {
        const params = getParamTable("default", table);
        fetchData(params, isNew);
    };

    const fetchData = useCallback(async (params = {}, isNew = false) => {
        updateTableLoadingState(isNew);
        params.pagination.current = isMobile ? (isNew ? 1 : params.pagination.current) : params.pagination.current;

        let resp = await _setAxios("penilaian/mapel/rombel/table", "POST", params);
        if (resp.status) {
            // resp.data = {
            //     "nilai_status_terkirim": true,
            //     "status_code": 200,
            //     "message": "Data berhasil ditampilkan",
            //     "info": {
            //         "page": 1,
            //         "total": 3,
            //         "total_filtered": 3
            //     },
            //     "list": [
            //         {
            //             "rownum": "1",
            //             "tahun_ajaran_id": "1",
            //             "tahun_ajaran": "2024-2025",
            //             "semester_id": "1",
            //             "tipe_semester": "Ganjil",
            //             "rombel_id": "5",
            //             "rombel_nama": "X - 2",
            //             "tingkatan_kelas": "11",
            //             "mapel_id": "20",
            //             "mapel_nama": "Matematika",
            //             "kurikulum_nama": "K13 Revisi",
            //             "total_penilaian_siswa": "0/36",
            //             "nilai_status_terkirim": "-",
            //             "tugas_id": "37"
            //         },
            //         {
            //             "rownum": "2",
            //             "tahun_ajaran_id": "1",
            //             "tahun_ajaran": "2024-2025",
            //             "semester_id": "1",
            //             "tipe_semester": "Ganjil",
            //             "rombel_id": "6",
            //             "rombel_nama": "X - 3",
            //             "tingkatan_kelas": "11",
            //             "mapel_id": "20",
            //             "mapel_nama": "Matematika",
            //             "kurikulum_nama": "K13 Revisi",
            //             "total_penilaian_siswa": "0/33",
            //             "nilai_status_terkirim": "-",
            //             "tugas_id": "38"
            //         },
            //         {
            //             "rownum": "3",
            //             "tahun_ajaran_id": "1",
            //             "tahun_ajaran": "2024-2025",
            //             "semester_id": "1",
            //             "tipe_semester": "Ganjil",
            //             "rombel_id": "4",
            //             "rombel_nama": "X - 1",
            //             "tingkatan_kelas": "10",
            //             "mapel_id": "20",
            //             "mapel_nama": "Matematika",
            //             "kurikulum_nama": "K13 Revisi",
            //             "total_penilaian_siswa": "4/33",
            //             "nilai_status_terkirim": "Nilai Terkirim",
            //             "tugas_id": "36"
            //         }
            //     ],
            //     "message_original ": "Data berhasil ditampilkan"
            // }
            setTimeout(() => {
                updateTableData(resp.data, params, isNew);
            }, 0);
        } else {
            resetTableLoadingState();
        }
    }, []);

    const updateTableLoadingState = (isNew) => {
        setTable(prev => ({
            ...prev,
            loading_first: isMobile ? isNew : false,
            loading: true
        }));
    };

    const resetTableLoadingState = () => {
        setTable(prev => ({ ...prev, loading_first: false, loading: false }));
    };

    const updateTableData = (data, params, isNew) => {
        const updatedData = isNew ? [] : params.data;
        const current = params.pagination.current;
        const newCurrent = isMobile ? current + 1 : current;

        setTable({
            ...params,
            loading_first: false,
            loading: false,
            data: isMobile ? [...updatedData, ...data?.list] : data?.list,
            pagination: {
                ...params.pagination,
                current: newCurrent,
                total: data.info.total,
            },
        });
    };

    const setTempTableFilter = (name, value) => {
        setFilterState(prev => ({
            ...prev,
            values: { ...prev.values, [name]: value }
        }));
        setTable(prev => ({ ...prev, filtering: { ...prev.filtering, [name]: value } }));
    };

    const setTableFilterFromTemp = () => {
        const params = getParamTempTableFiltering(table, filterState.values);
        fetchData(params, isMobile);
        modalFilter(false);
    };

    const modalFilter = (visible = false) => {
        setFilterState(prev => ({ ...prev, visible }));
    };

    const modalSend = (visible = false, data = {}) => {
        setSendState({ visible, data });
    };

    return {
        action,
        table,
        filterState,
        sendState,
        fetchTable,
        fetchData,
        setTempTableFilter,
        setTableFilterFromTemp,
        modalFilter,
        modalSend,
    };
};

export default useList;
