import React from 'react';
import { connect } from 'react-redux'
import Config from "../../Config";
import { _getToken, isAuth, _error, _success, _validationFormMsg, _setAxios, _tableLogActivity, _getDate } from '../../lib/Helper';

import { Select, Input, Tooltip, Breadcrumb, Table, Row, Col, Divider, Popover, Modal, Button, Radio, Space, Menu, Dropdown, message, Tag } from 'antd';
import { PageHeader } from 'components/header';
import { CloseCircleOutlined, CheckCircleOutlined, InfoCircleOutlined, SearchOutlined, DoubleRightOutlined, SettingOutlined, ArrowUpOutlined, ArrowDownOutlined, PlusOutlined, SortAscendingOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined, AntDesignOutlined, ReloadOutlined } from '@ant-design/icons';
import axios from 'axios';
import AuthRedirect from '../../components/AuthRedirect'
import validate from 'validate.js';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import Stack from '@mui/material/Stack';

const { Option } = Select;

const schema = {
  name: {
    presence: { allowEmpty: false, message: 'wajib diisi' },
    length: {
      maximum: 100
    }
  },
  no_telp: {
    length: {
      maximum: 14,
      message: "terlalu panjang (maksimal 14 karakter)"
    },
    format: {
      pattern: /^$|(\+62 ((\d{3}([ -]\d{3,})([- ]\d{4,})?)|(\d+)))|(\(\d+\) \d+)|\d{3}( \d+)+|(\d+[ -]\d+)|\d+/,
      message: function (value) {
        return validate.format("^No telp %{phone} tidak valid", {
          phone: value
        });
      }
    },
  },
  tgl_lahir: {
    presence: { allowEmpty: false, message: 'harus diisi' },
  },
  email: {
    presence: { allowEmpty: false, message: 'wajib diisi' },
    length: {
      maximum: 150
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'wajib diisi' },
    length: {
      maximum: 150
    }
  },
  password_confirm: {
    presence: { allowEmpty: false, message: 'wajib diisi' },
    length: {
      maximum: 150
    },
    equality: {
      attribute: "password",
      message: "wajib sama dengan password",
      comparator: function (v1, v2) {
        return JSON.stringify(v1) === JSON.stringify(v2);
      }
    },
  }
};
const schemaUpdate = {
  name: {
    presence: { allowEmpty: false, message: 'wajib diisi' },
    length: {
      maximum: 100
    }
  },
  email: {
    presence: { allowEmpty: false, message: 'wajib diisi' },
    length: {
      maximum: 150
    }
  },
  no_telp: {
    length: {
      maximum: 14,
      message: "terlalu panjang (maksimal 14 karakter)"
    },
    format: {
      pattern: /^$|(\+62 ((\d{3}([ -]\d{3,})([- ]\d{4,})?)|(\d+)))|(\(\d+\) \d+)|\d{3}( \d+)+|(\d+[ -]\d+)|\d+/,
      message: function (value) {
        return validate.format("^No telp %{phone} tidak valid", {
          phone: value
        });
      }
    },
  },
  tgl_lahir: {
    presence: { allowEmpty: false, message: 'harus diisi' },
  },
};

class ConfUser extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      tableOption: {
        sortField: "id",
        sortValue: "ASC",
      },
      data: [],
      search: "",
      pagination: {
        current: 1,
        pageSize: 10,
      },
      loading: false,
      id: 0,
      values: {},
      errors: {},
      create: {
        visible: false,
        loading: false,
      },
      update: {
        visible: false,
        loading: false,
      },
      delete: {
        visible: false,
        loading: false,
      },
      reset: {
        visible: false,
        loading: false,
      },
      assignRole: {
        visible: false,
        loading: false,
      },
      ddl: {
        role: []
      }

    };
  }

  componentDidMount() {
    this.fetchTable()
    this.getRoleDDL()
  }

  fetchTable = () => {
    var params = {
      search: this.state.search,
      pagination: { ...this.state.pagination },
      sorting: [{
        field: this.state.tableOption.sortField,
        sort: this.state.tableOption.sortValue,
        urutan: 1,
      }]
    }
    this.fetch(params);
  }


  handleTableChange = (pagination, filters, sorter) => {
    this.fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
      search: this.state.search,
      sorting: [{
        field: this.state.tableOption.sortField,
        sort: this.state.tableOption.sortValue,
        urutan: 1,
      }]
    });
  };

  handleTableSearch = (e) => {
    const { value } = e.target;

    this.setState({
      search: value,
    });

    var params = {
      search: value,
      pagination: { ...this.state.pagination, current: 1 },
      sorting: [{
        field: this.state.tableOption.sortField,
        sort: this.state.tableOption.sortValue,
        urutan: 1,
      }]
    }
    this.fetch(params);

  };

  handleTableFilter = e => {
    this.setState({
      tableOption: {
        ...this.state.tableOption,
        sortField: e.target.value
      },
    });

    this.fetch({
      search: this.state.search,
      pagination: { ...this.state.pagination },
      sorting: [{
        field: e.target.value,
        sort: this.state.tableOption.sortValue,
        urutan: 1,
      }]
    });
  };

  handleTableFiltering = (name, value) => {
    this.fetch({
      search: this.state.search,
      sorting: { ...this.state.sorting },
      pagination: { ...this.state.pagination },
      filtering: {
        ...this.state.filtering,
        [name]: value
      }
    });
  };


  handleTableSort = e => {
    this.setState({
      tableOption: {
        ...this.state.tableOption,
        sortValue: e.target.value
      },
    });

    this.fetch({
      search: this.state.search,
      pagination: { ...this.state.pagination },
      sorting: [{
        field: this.state.tableOption.sortField,
        sort: e.target.value,
        urutan: 1,
      }]
    });
  };

  fetch = async (params = {}) => {
    this.setState({ loading: true });
    const headers = {
      'Accept': 'application/json',
      'Authorization': _getToken()
    };

    axios.post(Config.api + '/user/table', JSON.stringify(params), { headers })
      .then((resp) => {
        this.setState({
          loading: false,
          data: resp.data.list,
          pagination: {
            ...params.pagination,
            total: resp.data.info.total,
          },
        });
      })
      .catch(function (error) {
        if (error.response.data.message) {
          _error('topRight', 'Failed', error.response.data.message)
        }
      });
  };

  getRoleDDL = () => {
    const headers = {
      'Accept': 'application/json',
      'Authorization': _getToken()
    };

    const params = {
      "pagination": {
        "current": 1,
        "pageSize": 1000,
        "total": 0
      },
      "search": "",
      "sorting": [
        {
          "field": "name",
          "sort": "ASC",
          "urutan": "1"
        }
      ]
    }

    axios.post(Config.api + '/role/table', JSON.stringify(params), { headers })
      .then((resp) => {

        this.setState({
          ddl: {
            ...this.state.ddl,
            role: resp.data.list
          },
        });
      })
      .catch(function (error) {
        if (error.response.data.message) {
          _error('topRight', 'Failed', error.response.data.message)
        }
      });
  }


  handleChange = (e) => {
    const { name, value } = e.target;
    this.setForm(name, value)
  };

  handleChangeSetValue = (name, value) => {
    this.setForm(name, value)
  };

  setForm = (name, value) => {
    const { values } = this.state;
    values[name] = value;

    // let schemaUser = schema
    // if (this.state.update.visible) {
    //   schemaUser = schemaUpdate
    // }

    // const errors = validate(values, schemaUser);
    this.setState({
      values: values,
      // errors: errors || {},
    });
  }

  hasError = field => {
    return this.state.errors[field] ? true : false;
  }

  resetForm = () => {
    this.setState({
      values: {},
      errors: {},
    });
  };


  // CREATE START ------------------------------------------
  showModalCreate = () => {
    this.setState({
      create: {
        ...this.state.create,
        visible: true,
      },
      values: {},
      errors: {},
    });
  };

  hideModalCreate = () => {
    this.setState({
      create: {
        visible: false,
        loading: false,
      },

    });
  };

  createSubmit = (e) => {
    e.preventDefault();
    const { values } = this.state;
    const errors = validate(values, schema);
    if (errors) {
      this.setState({
        errors: errors || {},
      });
      return _validationFormMsg(errors)
    }
    this.setState({
      create: {
        ...this.state.create,
        loading: true
      }
    });

    values["tgl_lahir"] = _getDate(values.tgl_lahir)
    _setAxios("user", "POST", values).then(resp => {
      if (resp.status === true) {
        this.hideModalCreate()
        this.fetchTable()
        _success('topRight', 'Success', resp.data.message)
      } else {
        this.setState({
          create: {
            ...this.state.create,
            loading: false
          }
        });
      }
    })
  };

  // CREATE END ------------------------------------------

  // UPDATE START ------------------------------------------
  showModalUpdate = (row) => {
    this.setState({
      update: {
        visible: true,
        loading: false
      },
      id: row.id,
      values: {
        name: row.name,
        email: row.email,
        no_telp: row.no_telp,
        tgl_lahir: row.tgl_lahir,
      },
      errors: {},
    });
  };

  hideModalUpdate = () => {
    this.setState({
      update: {
        visible: false,
        loading: false
      }
    });
  };

  updateSubmit = (e) => {
    e.preventDefault();
    const { values } = this.state;
    const errors = validate(values, schemaUpdate);
    if (errors) {
      this.setState({
        errors: errors || {},
      });
      return _validationFormMsg(errors)
    }

    const params = {
      name: values.name,
      email: values.email,
      no_telp: values.no_telp,
      tgl_lahir: _getDate(values.tgl_lahir),
    }

    this.setState({
      update: {
        ...this.state.update,
        loading: true
      }
    });
    _setAxios("user/" + this.state.id, "PUT", params).then(resp => {
      if (resp.status === true) {
        this.hideModalUpdate()
        this.resetForm()
        this.fetchTable()
        _success('topRight', 'Success', resp.data.message)
      } else {
        this.setState({
          update: {
            ...this.state.update,
            loading: false
          }
        });
      }
    })

  };
  // UPDATE END ------------------------------------------

  // DELETE START ------------------------------------------

  hideModalDelete = () => {
    this.setState({
      delete: {
        ...this.state.delete,
        visible: false
      },
    });
  };

  showModalDelete = (row) => {
    this.setState({
      delete: {
        ...this.state.delete,
        visible: true
      },
      id: row.id,
    });

  };

  deleteSubmit = (e) => {
    e.preventDefault();

    this.setState({
      delete: {
        ...this.state.delete,
        loading: true
      }
    });

    _setAxios("user/" + this.state.id, "DELETE").then(resp => {
      if (resp.status === true) {
        this.setState({
          delete: {
            visible: false,
            loading: false
          }
        });
        _success('topRight', 'Success', resp.data.message)
        this.fetchTable()
      } else {
        this.setState({
          delete: {
            ...this.state.delete,
            loading: false
          }
        });
      }
    })
  };

  // DELETE END ------------------------------------------

  // RESET START ------------------------------------------

  hideModalReset = () => {
    this.setState({
      reset: {
        ...this.state.reset,
        visible: false
      },
    });
  };

  showModalReset = (row) => {
    this.setState({
      reset: {
        ...this.state.reset,
        visible: true
      },
      id: row.id,
    });

  };

  resetSubmit = (e) => {
    e.preventDefault();

    this.setState({
      reset: {
        ...this.state.reset,
        loading: true
      }
    });

    _setAxios("user/reset-password/" + this.state.id, "POST").then(resp => {
      if (resp.status === true) {
        this.setState({
          reset: {
            visible: false,
            loading: false
          }
        });
        _success('topRight', 'Success', resp.data.message)
        this.fetchTable()
      } else {
        this.setState({
          reset: {
            ...this.state.reset,
            loading: false
          }
        });
      }
    })
  };

  // RESET END ------------------------------------------


  // ASSIGN ROLE START ------------------------------------------

  hideModalAssignRole = () => {
    this.setState({
      assignRole: {
        ...this.state.assignRole,
        visible: false
      },
    });
  };

  showModalAssignRole = (row) => {
    this.setState({
      assignRole: {
        ...this.state.assignRole,
        visible: true
      },
      id: row.id,
      values: {
        role_id: row.role_id
      }
    });

  };

  assignRoleSubmit = (e) => {
    e.preventDefault();
    const { values } = this.state;
    if (values.role_id === undefined || values.role_id === 0) {
      message.warning("Role wajib dipilih");
      return
    }

    this.setState({
      assignRole: {
        ...this.state.assignRole,
        loading: true
      }
    });

    _setAxios("user/assign-role/" + this.state.id, "PUT", {
      role_id: values.role_id
    }).then(resp => {
      if (resp.status === true) {
        this.setState({
          assignRole: {
            visible: false,
            loading: false
          }
        });
        _success('topRight', 'Success', resp.data.message)
        this.fetchTable()
      } else {
        this.setState({
          assignRole: {
            ...this.state.assignRole,
            loading: false
          }
        });
      }
    })
  };

  // ASSIGN ROLE END ------------------------------------------


  render() {
    const access = this.props.privilege.access["/configuration/user"]
    if (!isAuth(this.props.privilege) || access === undefined) {
      return <AuthRedirect />
    }
    const action = access.action
    const { data, pagination, loading, values, errors, tableOption, ddl } = this.state;

    const sortComponent = <>
      <Radio.Group onChange={this.handleTableFilter} value={tableOption.sortField} name="sort_field">
        <Space direction="vertical">
          <Radio value="id">Tanggal Pembuatan</Radio>
          <Radio value="name">Nama</Radio>
          <Radio value="is_deleted">Status Aktif</Radio>
        </Space>
      </Radio.Group>

      <Divider orientation="left"></Divider>

      <Radio.Group onChange={this.handleTableSort} value={tableOption.sortValue} name="sort_value">
        <Space direction="vertical">
          <Radio value="ASC"><ArrowUpOutlined /> Ascending</Radio>
          <Radio value="DESC"><ArrowDownOutlined /> Descending</Radio>
        </Space>
      </Radio.Group>
    </>

    const formInput = <>
      <Row gutter={[24, 24]}>
        <Col xs={24}>
          <TextField
            fullWidth
            label="Nama"
            size="small"
            placeholder="Nama lengkap"
            InputLabelProps={{
              shrink: true,
            }}
            name="name"
            type="text"
            onChange={this.handleChange}
            value={values.name || ''}
            error={this.hasError('name')}
            helperText={
              this.hasError('name') ? errors.name[0] : null
            }
          />
        </Col>
        <Col xs={24}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3}>
              <MobileDatePicker
                label="Tanggal Lahir"
                value={values.tgl_lahir || null}
                inputFormat="dd-MM-yyyy"
                onChange={(newValue) => {
                  this.handleChangeSetValue("tgl_lahir", newValue)
                }}
                renderInput={(params) => <TextField
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText={
                    this.hasError('tgl_lahir') ? errors.tgl_lahir[0] : null
                  }
                  {...params} />}
              />
            </Stack>
          </LocalizationProvider>
        </Col>
        <Col xs={24}>
          <TextField
            fullWidth
            label="No.Telp/Wa"
            size="small"
            placeholder="No.Telp/Wa"
            InputLabelProps={{
              shrink: true,
            }}
            name="no_telp"
            type="text"
            onChange={this.handleChange}
            value={values.no_telp || ''}
            error={this.hasError('no_telp')}
            helperText={
              this.hasError('no_telp') ? errors.no_telp[0] : null
            }
          />
        </Col>
        <Col xs={24}>
          <TextField
            fullWidth
            label="Email"
            size="small"
            placeholder="Email"
            InputLabelProps={{
              shrink: true,
            }}
            name="email"
            type="text"
            onChange={this.handleChange}
            value={values.email || ''}
            error={this.hasError('email')}
            helperText={
              this.hasError('email') ? errors.email[0] : null
            }
          />
        </Col>
        <Col xs={24}>
          <TextField
            fullWidth
            label="Password"
            size="small"
            placeholder="password"
            InputLabelProps={{
              shrink: true,
            }}
            name="password"
            type="password"
            onChange={this.handleChange}
            value={values.password || ''}
            error={this.hasError('password')}
            helperText={
              this.hasError('password') ? errors.password[0] : null
            }
          />
        </Col>
        <Col xs={24}>
          <TextField
            fullWidth
            label="Konfirmasi Password"
            size="small"
            placeholder="password_confirm"
            InputLabelProps={{
              shrink: true,
            }}
            name="password_confirm"
            type="password"
            onChange={this.handleChange}
            value={values.password_confirm || ''}
            error={this.hasError('password_confirm')}
            helperText={
              this.hasError('password_confirm') ? errors.password_confirm[0] : null
            }
          />
        </Col>
      </Row>
    </>

    const modalCreate = <Modal
      title="Tambah User"
      visible={this.state.create.visible}
      onCancel={this.hideModalCreate}
      footer={<>
        <Button onClick={this.hideModalCreate}>Batal</Button>
        <Button type="primary" htmlType="submit" onClick={this.createSubmit} loading={this.state.create.loading}>Simpan</Button>
      </>}
    >
      <form autoComplete="off" onSubmit={this.createSubmit}>
        {formInput}
      </form>
    </Modal>

    const modalUpdate = <Modal
      title="Update User"
      onCancel={this.hideModalUpdate}
      visible={this.state.update.visible}
      footer={<>
        <Button onClick={this.hideModalUpdate}>Batal</Button>
        <Button type="primary" htmlType="submit" onClick={this.updateSubmit} loading={this.state.update.loading}>Simpan</Button>
      </>}

    >
      <form autoComplete="off" onSubmit={this.updateSubmit}>
        <Row gutter={[24, 24]}>
          <Col xs={24}>
            <TextField
              fullWidth
              label="Nama"
              size="small"
              placeholder="Nama lengkap"
              InputLabelProps={{
                shrink: true,
              }}
              name="name"
              type="text"
              onChange={this.handleChange}
              value={values.name || ''}
              error={this.hasError('name')}
              helperText={
                this.hasError('name') ? errors.name[0] : null
              }
            />
          </Col>
          <Col xs={24}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack spacing={3}>
                <MobileDatePicker
                  label="Tanggal Lahir"
                  value={values.tgl_lahir || null}
                  inputFormat="dd-MM-yyyy"
                  onChange={(newValue) => {
                    this.handleChangeSetValue("tgl_lahir", newValue)
                  }}
                  renderInput={(params) => <TextField
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    helperText={
                      this.hasError('tgl_lahir') ? errors.tgl_lahir[0] : null
                    }
                    {...params} />}
                />
              </Stack>
            </LocalizationProvider>
          </Col>
          <Col xs={24}>
            <TextField
              fullWidth
              label="No.Telp/Wa"
              size="small"
              placeholder="No.Telp/Wa"
              InputLabelProps={{
                shrink: true,
              }}
              name="no_telp"
              type="text"
              onChange={this.handleChange}
              value={values.no_telp || ''}
              error={this.hasError('no_telp')}
              helperText={
                this.hasError('no_telp') ? errors.no_telp[0] : null
              }
            />
          </Col>
          <Col xs={24}>
            <TextField
              fullWidth
              label="Email"
              size="small"
              placeholder="Email"
              InputLabelProps={{
                shrink: true,
              }}
              name="email"
              type="text"
              onChange={this.handleChange}
              value={values.email || ''}
              error={this.hasError('email')}
              helperText={
                this.hasError('email') ? errors.email[0] : null
              }
              InputProps={{
                readOnly: true
              }}

            />
          </Col>
        </Row>
      </form>
    </Modal>

    const modalDelete = <Modal
      title={<><ExclamationCircleOutlined /> Konfirmasi</>}
      visible={this.state.delete.visible}
      onCancel={this.hideModalDelete}
      width={240}
      footer={<>
        <Button onClick={this.hideModalDelete}>Batal</Button>
        <Button type="primary" htmlType="submit" onClick={this.deleteSubmit} loading={this.state.delete.loading}>Lanjutkan</Button>
      </>}
    >
      Apakah Anda Yakin?

    </Modal>

    const modalReset = <Modal
      title={<><ExclamationCircleOutlined /> Konfirmasi</>}
      visible={this.state.reset.visible}
      onCancel={this.hideModalReset}
      // width={240}
      footer={<>
        <Button onClick={this.hideModalReset}>Batal</Button>
        <Button type="primary" htmlType="submit" onClick={this.resetSubmit} loading={this.state.reset.loading}>Lanjutkan</Button>
      </>}
    >
      Apakah Anda Yakin?<br />
      <span>Password akan di reset sesuai tanggal lahir dengan format YMD (ex: 19941204)</span>

    </Modal>


    const modalAssignRole = <Modal
      title={<>Tambah / Update Role User</>}
      visible={this.state.assignRole.visible}
      onCancel={this.hideModalAssignRole}
      footer={<>
        <Button onClick={this.hideModalAssignRole}>Batal</Button>
        <Button type="primary" htmlType="submit" onClick={this.assignRoleSubmit} loading={this.state.assignRole.loading}>Simpan</Button>
      </>}
    >
      <form autoComplete="off" onSubmit={this.assignRoleSubmit}>
        <Row gutter={[24, 24]}>
          <Col xs={24}>
            <TextField
              select
              fullWidth
              label="Role (Jabatan)"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              name="role_id"
              onChange={this.handleChange}
              value={values.role_id || ''}
              error={this.hasError('role_id')}
              helperText={
                this.hasError('role_id') ? errors.role_id[0] : null
              }
            >
              <MenuItem key={0} value={0}></MenuItem>
              {ddl.role.map((row) =>
                <MenuItem key={row.id} value={row.id}>
                  {row.name}
                </MenuItem>
              )}
            </TextField>
          </Col>
        </Row>
      </form>

    </Modal>


    return (
      <>
        <PageHeader
          style={{
            padding: 0
          }}
          onBack={() => window.history.back()}
          subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
            <Breadcrumb.Item>Konfigurasi</Breadcrumb.Item>
            <Breadcrumb.Item>Pengguna</Breadcrumb.Item>
          </Breadcrumb>}
          extra={action.create === true ? <Tooltip title="Tambah">
            <Button type='primary' onClick={this.showModalCreate}><PlusOutlined /> Tambah</Button>
          </Tooltip> : ""}
        />
        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
          <PageHeader
            className="site-page-header"
            title="Pengguna"
            subTitle="Data konfigurasi pengguna"
          />
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12} lg={17}>
              <Input
                name="search"
                className='search-table'
                placeholder="Search..."
                prefix={<SearchOutlined className="site-form-item-icon" />}
                onChange={this.handleTableSearch}
                value={this.state.search || ""}
                suffix={
                  <Tooltip title="Cari berdasarkan nama">
                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                  </Tooltip>
                }
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={4}>
              <Select
                showSearch
                allowClear
                name="Tipe account"
                placeholder="Tipe account"
                optionFilterProp="children"
                onChange={(e, newValue) => {
                  this.handleTableFiltering("tipe", newValue ? newValue.value : null)
                }}

                style={{ width: '100%' }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {["Internal", "Eksternal"].map((row, i) => <Option key={i} value={row}>{row}</Option>)}
              </Select>
            </Col>
            <Col xs={12} sm={12} md={6} lg={3} className="text-align-right">
              <Popover placement="bottom" content={sortComponent} trigger="click">
                <Button block><SortAscendingOutlined /> Sort</Button>
              </Popover>
            </Col>
          </Row>

          <Divider orientation="left"></Divider>

          <Row>
            <Col span={24}>
              <Table
                columns={[
                  {
                    title: 'No',
                    dataIndex: 'rownum',
                    sorter: false,
                    width: '3%',
                  },
                  {
                    title: 'Name',
                    dataIndex: 'name',
                    sorter: false,
                  },
                  {
                    title: 'Email',
                    dataIndex: 'email',
                    sorter: false,
                  },
                  {
                    title: 'Tipe',
                    dataIndex: 'tipe',
                    sorter: false,
                    render: (a, row) => row.tipe ? row.tipe : "-"
                  },
                  {
                    title: 'Role',
                    dataIndex: 'role_name',
                    sorter: false,
                    render: (a, row) => row.role_name ? <Tag style={{ minWidth: "200px" }} color="rgb(18 120 74)"><AntDesignOutlined /> {row.role_name}</Tag> : "-",
                  },
                  {
                    title: 'Status',
                    dataIndex: 'is_deleted',
                    sorter: false,
                    render: (a, row) => <>
                      {row.is_deleted === "0" ? <Tag color="rgb(18 120 74)"><CheckCircleOutlined /> Aktif</Tag> : <Tag color="rgb(13 79 53 / 48%)"><CloseCircleOutlined /> Tidak Aktif</Tag>}
                    </>,
                  },
                  {
                    title: <SettingOutlined />,
                    align: "center",
                    key: 'operation',
                    width: '2%',
                    render: (a, row) => <Dropdown trigger={['click']} overlay={<Menu>
                      {action.update === true ? (<Menu.Item onClick={this.showModalUpdate.bind(this, row)}><EditOutlined /> Update</Menu.Item>) : ""}
                      {action.delete === true ? (<Menu.Item onClick={this.showModalDelete.bind(this, row)}><DeleteOutlined /> Delete</Menu.Item>) : ""}
                      {action.update === true ? (<Menu.Item onClick={this.showModalAssignRole.bind(this, row)}><AntDesignOutlined /> Assign Role</Menu.Item>) : ""}
                      {action.update === true ? (<Menu.Item onClick={this.showModalReset.bind(this, row)}><ReloadOutlined /> Reset Password</Menu.Item>) : ""}
                    </Menu>}>
                      <div className="ant-dropdown-link tb-action">
                        <Button type='primary'>Opsi</Button>
                      </div>
                    </Dropdown>,
                  },
                  {
                    title: 'LogActivity',
                    key: 'operation',
                    width: '10%',
                    render: (a, row) => _tableLogActivity(row),
                  },
                ]}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={this.handleTableChange}
                size="small"
              />
            </Col>
          </Row>
        </div>

        {modalCreate}

        {modalUpdate}

        {modalDelete}

        {modalAssignRole}
        {modalReset}
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(ConfUser);
