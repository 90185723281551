import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, BackTop, Breadcrumb } from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';
import { PageHeader } from 'components/header';
import { isAuth } from 'lib/Helper';
import { SortingTableWithFilter } from 'components/sorting-table';
import { TableList } from 'components/list';
import AuthRedirect from 'components/AuthRedirect';
import Filter from '../modal/Filter';
import SearchTable from 'components/search-table/SearchTable';
import columns from './Columns';
import useList from '../hooks/useList';

const sortFields = [{
  value: "id",
  label: "Tanggal Pembuatan",
}];

const PenghargaanSiswa = ({ privilege }) => {
  const {
    action,
    table,
    filterState,
    fetchTable,
    fetchData,
    setTempTableFilter,
    setTableFilterFromTemp,
    modalFilter,
  } = useList(privilege);

  if (!isAuth(privilege) || (action === undefined)) {
    return <AuthRedirect />
  }


  return (
    <>
      <PageHeader
        style={{
          padding: 0
        }}
        onBack={() => window.history.back()}
        title={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
          <Breadcrumb.Item>Kesiswaan</Breadcrumb.Item>
          <Breadcrumb.Item>Penghargaan Siswa </Breadcrumb.Item>
        </Breadcrumb>}
      />
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <PageHeader
          className="site-page-header"
          title="Penghargaan Siswa"
          subTitle="Data penghargaan siswa" />
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={18} md={19}>
            <SearchTable table={table} fetch={fetchData} />
          </Col>
          <Col xs={24} sm={6} md={5}>
            <SortingTableWithFilter
              table={table}
              fetch={fetchData}
              modalFilter={modalFilter}
              sortFields={sortFields}
            />
          </Col>
          <Col xs={24}>
            <TableList
              state={{ table, filterState }}
              fetch={fetchData}
              fetchTable={fetchTable}
              columns={columns(action)}
            />
          </Col>
        </Row>
      </div>
      {filterState.visible && (
        <Filter
          visible={filterState.visible}
          hideModal={() => modalFilter(false)}
          setTempTableFilter={setTempTableFilter}
          setTableFilterFromTemp={setTableFilterFromTemp}
          values={filterState.values}
        />
      )}
      <BackTop />
    </>
  );
};

const mapStateToProps = (state) => ({
  privilege: state.privilege,
});

export default connect(mapStateToProps)(PenghargaanSiswa);