import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { _setAxios } from '../../lib/Helper';

async function fetchList(tahunAjaranId) {
  if (!tahunAjaranId) {
    return []
  }

  return await _setAxios("kaldik-kegiatan/ekstrakurikuler/master/dropdown", "POST", {
    tahun_ajaran_id: tahunAjaranId || null
})
    .then(body => {
      let results = []
      body.data?.data?.forEach(row => {
        results.push({
          label: row.nama,
          value: row.id,
        })
      });

      return results
    })
}

const SelectEkstrakurikuler = ({ ...props }) => {
  const [options, setOptions] = useState([]);
  const tahunAjaranId = props.tahunAjaranId || props.tahunAjaranId === 0 ? props.tahunAjaranId : null

  useEffect(() => {
    fetchList(tahunAjaranId).then((newOptions) => {
      setOptions(newOptions);
    });
  }, [tahunAjaranId]);

  return (
    <Select
      {...props}
      placeholder="Pilih ekstrakurikuler"
      style={{
        width: '100%',
      }}
      options={options}
    />
  );
};
export default SelectEkstrakurikuler;
