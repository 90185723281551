import React, { useEffect, useState } from 'react';
import { _setAxios } from '../../../lib/Helper';
import { Autocomplete, TextField } from '@mui/material';

async function fetchList() {

  return await _setAxios("setting/dropdown/modul/2")
    .then(body => {
      let results = []
      body.data?.data?.forEach(row => {
        results.push({
          label: row.list,
          value: row.id,
        })
      });

      return results
    })
}

const SelectJenisBantuanLembaga = ({ ...props }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    fetchList().then((newOptions) => {
      setOptions(newOptions);
    });
  }, []);

  if (!options.length) return "Loading..."

  return (
    <Autocomplete
      {...props}
      value={options.find(option => option.value === props.value)}
      options={options}
      noOptionsText="Tidak ada data"
      renderInput={(params) => <TextField {...params}
        fullWidth
        label="Jenis bantuan"
        size="small"
        InputLabelProps={{
          shrink: true,
        }}
        name="jenis_bantuan"
        type="text"
        helperText={
          props.errors.jenis_bantuan ? props.errors.jenis_bantuan[0] : null
        }
        error={
          props.errors.jenis_bantuan
        }
      />}
    />
  );
};
export default SelectJenisBantuanLembaga;
