import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { _setAxios } from 'lib/Helper';

async function fetchList() {

  return await _setAxios("setting/dropdown/modul/40")
    .then(body => {
      let results = []
      body.data?.data?.forEach(row => {
        results.push({
          label: row.list,
          value: row.id,
        })
      });

      return results
    })

  // return new Promise((resolve) => {
  //   resolve([
  //     { label: 'Madrasah Akademik (MAN IC)', value: 'Madrasah Akademik (MAN IC)' },
  //     { label: 'Madrasah Kejuruan', value: 'Madrasah Kejuruan' },
  //     { label: 'Madrasah Keagamaan', value: 'Madrasah Keagamaan' },
  //     { label: 'Madrasah Reguler', value: 'Madrasah Reguler' },
  //     { label: 'Madrasah Plus Keterampilan (PK)', value: 'Madrasah Plus Keterampilan (PK)' },
  //   ]);
  // });
}

const SelectKategoriLembaga = ({ ...props }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    fetchList().then((newOptions) => {
      setOptions(newOptions);
    });
  }, []);

  if (!options.length) return "Loading..."

  return (
    <Autocomplete
      {...props}
      value={options.find(option => option.value === props.value)}
      options={options}
      noOptionsText="Tidak ada data"
      renderInput={(params) => <TextField {...params}
        fullWidth
        label="Ketegori lembaga"
        size="small"
        InputLabelProps={{
          shrink: true,
        }}
        name="kategori"
        type="text"
        helperText={
          props.errors.kategori ? props.errors.kategori[0] : null
        }
        error={
          props.errors.kategori
        }
      />}
    />
  );
};
export default SelectKategoriLembaga;
