import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, BackTop, Button, Alert, Divider, InputNumber, Typography, Breadcrumb } from 'antd';
import { TableList } from 'components/list';
import { PageHeader } from 'components/header';
import columns from './Columns';
import useList from '../hooks/useList';
import useForm from '../hooks/useForm';
import SearchTable from 'components/search-table/SearchTable';
import { MobileView } from 'react-device-detect';
import { DoubleRightOutlined, DownloadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import Header from './Header';
import { isAuth } from 'lib/Helper';
import AuthRedirect from 'components/AuthRedirect';
import ModalExport from '../modal/Export';
const { Title } = Typography;

const PenilaianNilaiMapelPasPat = ({ match, privilege }) => {
  const {
    action,
    headerData,
    table,
    exportState,
    setTable,
    fetchTable,
    fetchData,
    modalExport,
  } = useList(privilege, match);

  const {
    loading: loadingBtn,
    headValues,
    values,
    handleInputChange,
    handleSubmit
  } = useForm(headerData, table, setTable, fetchTable);

  const fetchDataUpdate = (param, isNew) => {
    fetchData(param, isNew, headValues);
  }

  if (!isAuth(privilege) || (action === undefined || action?.paspat_view === undefined)) {
    return <AuthRedirect />
  }


  return (
    <>
      <PageHeader
        style={{
          padding: 0
        }}
        onBack={() => window.history.back()}
        title={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
          <Breadcrumb.Item>Penilaian</Breadcrumb.Item>
          <Breadcrumb.Item><Link to="/penilaian/nilai-mapel">Nilai Mata Pelajaran</Link> </Breadcrumb.Item>
          <Breadcrumb.Item><Link to={`/penilaian/nilai-mapel/detail/${match.params.tugas_id}`}>Detail</Link> </Breadcrumb.Item>
          <Breadcrumb.Item>PAS/PAT </Breadcrumb.Item>
        </Breadcrumb>}
      />

      <Header data={headerData} />

      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <PageHeader
          className="site-page-header"
          title="Nilai PAS/PAT"
          extra={[
            action?.paspat_export && (
              <Button
                onClick={() => modalExport(true)}
                icon={<DownloadOutlined />}>
                Export
              </Button>
            ),
          ]} />        <Alert
          message="Pastikan untuk menyimpan perubahan sebelum melanjutkan agar data yang telah dimasukkan tidak hilang."
          type="info"
          showIcon
        />
        <Divider />
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <SearchTable table={table} fetch={fetchDataUpdate} />
          </Col>
          <Col xs={24}>
            <MobileView style={{ textAlign: "center" }}>
              <Title level={5}>Perbarui Massal</Title>
              <InputNumber
                style={{ width: '100%' }}
                placeholder="0"
                min={0}
                onChange={(value) => handleInputChange(0, 'nilai', value)}
              />
              <Divider />
            </MobileView>
            <TableList
              state={{ table }}
              fetch={fetchDataUpdate}
              fetchTable={fetchTable}
              columns={columns(action, handleInputChange)}
              isPagination={false}
            />
          </Col>
          {action?.paspat_update && <Col xs={24} style={{ textAlign: "center" }}>
            <Button type="primary" onClick={handleSubmit} loading={loadingBtn} disabled={loadingBtn || values.length === 0} style={{ marginTop: 16 }}>
              Simpan Perubahan
            </Button>
          </Col>}
        </Row>
      </div >
      <BackTop />
      {exportState.visible && (
        <ModalExport
          visible={exportState.visible}
          hideModal={() => modalExport(false)}
          headerData={headerData}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  privilege: state.privilege,
});

export default connect(mapStateToProps)(PenilaianNilaiMapelPasPat);