import { Row, Col, Typography, Card, Tag, Flex } from 'antd';
import { useEffect, useState } from 'react';

const { Title, Text } = Typography;
const colorPredikat = {
    A: "green",
    B: "blue",
    C: "orange",
    D: "red"
}
let boxStyle = {
    width: '100%',
    minHeight: 200,
    borderRadius: 6,
    textAlign: 'center'
};




const Dashboard = ({ statisticState }) => {
    const [colorTingkat10, setColorTingkat10] = useState(null);
    const [colorTingkat11, setColorTingkat11] = useState(null);
    const [colorTingkat12, setColorTingkat12] = useState(null);

    useEffect(() => {
        statisticState?.tingkatan10?.predikat?.list.forEach(el => {
            if (parseInt(statisticState?.tingkatan10?.predikat?.nilai) >= parseInt(el.start_value) && parseInt(statisticState?.tingkatan10?.predikat?.nilai) <= parseInt(el.end_value)) {
                setColorTingkat10(colorPredikat[el.label])
            }
        });
        statisticState?.tingkatan11?.predikat?.list.forEach(el => {
            if (parseInt(statisticState?.tingkatan11?.predikat?.nilai) >= parseInt(el.start_value) && parseInt(statisticState?.tingkatan11?.predikat?.nilai) <= parseInt(el.end_value)) {
                setColorTingkat11(colorPredikat[el.label])
            }
        });
        statisticState?.tingkatan12?.predikat?.list.forEach(el => {
            if (parseInt(statisticState?.tingkatan12?.predikat?.nilai) >= parseInt(el.start_value) && parseInt(statisticState?.tingkatan12?.predikat?.nilai) <= parseInt(el.end_value)) {
                setColorTingkat12(colorPredikat[el.label])
            }
        });

    }, [statisticState]);


    if(statisticState.tingkatan10.kkm_status !== "1" && statisticState.tingkatan11.kkm_status !== "1" && statisticState.tingkatan12.kkm_status !== "1"){
        boxStyle = {
            minHeight: 50,
        }
    }

    return (
        <Row gutter={16} style={{ marginTop: 16 }}>
            <Col xs={24} sm={8} style={{ marginBottom: 16 }}>
                <Card title="Tingkatan 10" bordered={false} style={{ textAlign: 'center' }}>
                    {
                        statisticState.tingkatan10 && statisticState.tingkatan10.kkm_status === "1" && (
                            <>
                                <Title style={{ marginTop: 0 }} level={5}>KKM/KTTP : <Tag color={colorTingkat10}>{statisticState?.tingkatan10?.predikat?.nilai || 0}</Tag></Title>
                                <Title style={{ marginTop: 0, textAlign: 'left' }} level={5}>Predikat</Title>
                                {
                                    statisticState?.tingkatan10?.predikat?.list && statisticState?.tingkatan10?.predikat?.list.map((item, index) => (
                                        <Flex wrap gap="small" style={{ marginBottom: 8 }}>
                                            <Tag color={colorPredikat[item.label]}>{item.label}</Tag>
                                            <Text style={{ minWidth: 30, textAlign: 'right' }}>{item.start_value}</Text>
                                            -
                                            <Text>{item.end_value}</Text>
                                        </Flex>
                                    ))
                                }
                            </>
                        )
                    }
                    {
                        statisticState.tingkatan10 && statisticState.tingkatan10.kkm_status !== "1" && (
                            <Flex style={boxStyle} justify="center" align="center" >
                                {statisticState.tingkatan10.kkm_message}
                            </Flex>)
                    }
                </Card>
            </Col>
            <Col xs={24} sm={8} style={{ marginBottom: 16 }}>
                <Card title="Tingkatan 11" bordered={false} style={{ textAlign: 'center' }}>
                    {
                        statisticState.tingkatan11 && statisticState.tingkatan11.kkm_status === "1" && (
                            <>
                                <Title style={{ marginTop: 0 }} level={5}>KKM/KTTP : <Tag color={colorTingkat11}>{statisticState?.tingkatan11?.predikat?.nilai || 0}</Tag></Title>
                                <Title style={{ marginTop: 0, textAlign: 'left' }} level={5}>Predikat</Title>
                                {
                                    statisticState?.tingkatan11?.predikat?.list && statisticState?.tingkatan11?.predikat?.list.map((item, index) => (
                                        <Flex wrap gap="small" style={{ marginBottom: 8 }}>
                                            <Tag color={colorPredikat[item.label]}>{item.label}</Tag>
                                            <Text style={{ minWidth: 30, textAlign: 'right' }}>{item.start_value}</Text>
                                            -
                                            <Text>{item.end_value}</Text>
                                        </Flex>
                                    ))
                                }
                            </>
                        )
                    }
                    {
                        statisticState.tingkatan11 && statisticState.tingkatan11.kkm_status !== "1" && (
                            <Flex style={boxStyle} justify="center" align="center" >
                                {statisticState.tingkatan11.kkm_message}
                            </Flex>)
                    }
                </Card>
            </Col>
            <Col xs={24} sm={8} style={{ marginBottom: 16 }}>
                <Card title="Tingkatan 12" bordered={false} style={{ textAlign: 'center' }}>
                    {
                        statisticState.tingkatan12 && statisticState.tingkatan12.kkm_status === "1" && (
                            <>
                                <Title style={{ marginTop: 0 }} level={5}>KKM/KTTP : <Tag color={colorTingkat12}>{statisticState?.tingkatan12?.predikat?.nilai || 0}</Tag></Title>
                                <Title style={{ marginTop: 0, textAlign: 'left' }} level={5}>Predikat</Title>
                                {
                                    statisticState?.tingkatan12?.predikat?.list && statisticState?.tingkatan12?.predikat?.list.map((item, index) => (
                                        <Flex wrap gap="small" style={{ marginBottom: 8 }}>
                                            <Tag color={colorPredikat[item.label]}>{item.label}</Tag>
                                            <Text style={{ minWidth: 30, textAlign: 'right' }}>{item.start_value}</Text>
                                            -
                                            <Text>{item.end_value}</Text>
                                        </Flex>
                                    ))
                                }
                            </>
                        )
                    }
                    {
                        statisticState.tingkatan12 && statisticState.tingkatan12.kkm_status !== "1" && (
                            <Flex style={boxStyle} justify="center" align="center" >
                                {statisticState.tingkatan12.kkm_message}
                            </Flex>)
                    }
                </Card>
            </Col>
        </Row >
    )
}

export default Dashboard