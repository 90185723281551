import React from 'react';
import { connect } from 'react-redux'
import { _setAxios, isAuth } from '../../../lib/Helper';
import { Breadcrumb, Row, Col, Tabs } from 'antd';
import { PageHeader } from 'components/header';
import { DoubleRightOutlined } from '@ant-design/icons';
import PenilaianWalikelasPresensiSiswa from './presensi-siswa/list/index'
import PenilaianWalikelasCatatan from './catatan-wali-kelas/list/index'
import AuthRedirect from '../../../components/AuthRedirect';
import HeaderWaliKelas from './HeaderWaliKelas';
import PreloadContent from 'components/preload/PreloadContent';
import Error403 from 'pages/errors/403';

const { TabPane } = Tabs;
class PenilaianWalikelas extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            tabIndex: "",
            header: null,
            loading: true
        }
    }

    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);

        this.setState({
            tabIndex: query.get("tab") ? query.get("tab") : ""
        })

        this.getDetail()
    }

    handleChangeTab = (key) => {
        this.props.history.push({
            pathname: window.location.pathname,
            search: "?" + new URLSearchParams({ tab: key }).toString()
        })

        this.setState({
            tabIndex: key
        })
    }

    getDetail = () => {
        return _setAxios("penilaian/wali-kelas/show-rombel", "GET", null).then(resp => {

            if (!resp.status) {
                this.setState({
                    loading: false
                })

                return
            }

            this.setState({
                header: resp?.data?.data || null,
                loading: false
            })
        })
    }

    render() {
        const { privilege } = this.props
        const { access } = privilege
        const action = access["/penilaian/wali-kelas"]?.action

        if (!isAuth(privilege) || access === undefined || action === undefined) {
            return <AuthRedirect />
        }

        const { tabIndex, loading, header } = this.state

        const tabData = []

        if (action?.presensi_siswa_view) {
            tabData.push({
                key: "absensiSiswa",
                title: "Absensi Siswa",
                component: <PenilaianWalikelasPresensiSiswa
                    action={action}
                    headerData={this.state.header}
                    {...this.props} />
            });
        }
        if (action?.catatan_wali_kelas_view) {
            tabData.push({
                key: "catatanWalikelas",
                title: "Catatan Wali Kelas",
                component: <PenilaianWalikelasCatatan
                    action={action}
                    headerData={this.state.header}
                    {...this.props} />
            });
        }

        return (
            <>
                <PageHeader
                    style={{
                        padding: 0
                    }}
                    onBack={() => window.history.back()}
                    title={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
                        <Breadcrumb.Item>Penilaian</Breadcrumb.Item>
                        <Breadcrumb.Item>Wali Kelas </Breadcrumb.Item>
                    </Breadcrumb>}
                />

                <HeaderWaliKelas data={header} loading={loading} />

                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    {
                        !loading && !action?.presensi_siswa_view && !action?.catatan_wali_kelas_view ? <Error403 /> :
                        (loading ? <PreloadContent /> : <Row gutter={[16, 16]}>
                            <Col xs={24}>
                                <Tabs defaultActiveKey={tabIndex ? tabIndex : tabData[0]?.key} activeKey={tabIndex ? tabIndex : tabData[0]?.key} onChange={this.handleChangeTab}>
                                    {tabData.map((row, i) => <TabPane tab={row.title} key={row.key}> {row.component} </TabPane>)}
                                </Tabs>
                            </Col>
                        </Row>)
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(PenilaianWalikelas);