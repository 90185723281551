import React from 'react';
import { Row, Col, BackTop, Button, Divider, InputNumber, Typography } from 'antd';
import { TableList } from 'components/list';
import columns from './Columns';
import useList from '../hooks/useList';
import useForm from '../hooks/useForm';
import SearchTable from 'components/search-table/SearchTable';
import { MobileView } from 'react-device-detect';
const { Title } = Typography;

const SumatifFormList = (props) => {
  const {
    table,
    setTable,
    fetchTable,
    fetchData,
  } = useList(props?.privilege, props?.headerData, props?.sumatifId);

  const {
    loading: loadingBtn,
    headValues,
    handleInputChange,
    handleSubmit
  } = useForm(table, setTable, props?.setValuesList, props?.valuesList);

  const fetchDataUpdate = (param, isNew) => {
    fetchData(param, isNew, headValues);
  }

  return (
    <>
      <Divider />
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <SearchTable table={table} fetch={fetchDataUpdate} />
        </Col>
        <Col xs={24}>
          <MobileView style={{ textAlign: "center" }}>
            <Title level={5}>Perbarui Massal</Title>
            <InputNumber
            style={{ width: "100%" }}
              placeholder="0"
              min={0}
              onChange={(value) => handleInputChange(0, 'nilai', value)}
            />
            <Divider />
          </MobileView>
          <TableList
            state={{ table }}
            fetch={fetchDataUpdate}
            fetchTable={fetchTable}
            columns={columns(props?.action, handleInputChange)}
            isPagination={false}
          />
        </Col>
        {props?.action?.presensi_siswa_update && <Col xs={24} style={{ textAlign: "center" }}>
          <Button type="primary" onClick={handleSubmit} loading={loadingBtn} disabled={loadingBtn || props?.valuesList.length === 0} style={{ marginTop: 16 }}>
            Simpan Perubahan
          </Button>
        </Col>}
      </Row>
      <BackTop />
    </>
  );
};

export default SumatifFormList;