import React from 'react';
import { Button, Dropdown } from 'antd';
import { CloseCircleOutlined, DownCircleOutlined, EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom/cjs/react-router-dom';

class Action extends React.Component {
    render() {
        const { row, action, modalForm, modalDelete } = this.props;
        const items = [];

        if (!action.update && !action.delete && !action.detail) {
            return "-"
        }

        if (action.detail) {
            items.push({
                key: '3',
                label: "Detail",
                icon: <InfoCircleOutlined />,
                onClick: () => {
                    this.props.history.push("/penilaian/kkm-kttp/detail/" + row.id)
                },
            })
        }

        if (action.update) {
            items.push({
                key: '1',
                label: "Update",
                onClick: () => {
                    modalForm(true, row)
                },
                icon: <EditOutlined />,
            })
        }

        if (action.delete) {
            items.push({
                key: '2',
                label: "Delete",
                icon: <CloseCircleOutlined />,
                onClick: () => {
                    modalDelete(true, row)
                },
            })
        }

        return (
            <Dropdown
                trigger={['click']}
                menu={{ items }}
            >
                <Button type="primary">
                    Opsi <DownCircleOutlined style={{ fontSize: 11 }} />
                </Button>
            </Dropdown >
        );
    }
}


export default React.memo(withRouter(Action));
