import React from 'react';
import { connect } from 'react-redux'

import { Row, Col, Divider, List, Spin, Dropdown, Menu, Button, Empty } from 'antd';
import { DownCircleOutlined, DownloadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom/cjs/react-router-dom';

class Mobile extends React.Component {
  componentDidMount() {
    this.props.fetchTable(true)
  }

  render() {
    const { table } = this.props.state
    const action = this.props.access.action

    return (
      <>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <InfiniteScroll
              dataLength={table.data.length}
              next={this.props.fetchTable}
              hasMore={table.pagination.total != null && table.data.length < table.pagination.total}
              loader={<div style={{ textAlign: "center" }}><Spin size="small" /></div>}
              endMessage={
                table.loading_first ? "" : (table.data.length ? <Divider /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Data tidak ditemukan" />)
              }
            >
              <List
                loading={table.loading_first}
                locale={{ emptyText: " " }}
                dataSource={table.data}
                renderItem={(row, i) => (
                  <List.Item
                    key={i}
                  >
                    <List.Item.Meta
                      title={row.siswa_nama}
                      description={
                        <small>
                          NISN: {row.siswa_nisn ? row.siswa_nisn : "-"}<br />
                          NIS: {row.siswa_nis ? row.siswa_nis : "-"}<br />
                          Rombel: {row.rombel_nama ? row.rombel_nama : "-"}<br />
                          Tahun Ajaran: {row.tahun_ajaran || "-"} | {row.tipe_semester || "-"}
                        </small>
                      }
                    />
                    <div>
                      {(!action.detail && !action.download ? "-" : <Dropdown trigger={['click']} overlay={<Menu>
                        {action.detail ? (<Menu.Item key={0}><Link to={"/ubudiyah/penilaian/" + row.siswa_id}><InfoCircleOutlined /> Detail</Link></Menu.Item>) : ""}
                        {action.download ? (<Menu.Item key={1} onClick={this.props.downloadCert.bind(this, true, row)}><DownloadOutlined /> Download Sertifikat</Menu.Item>) : ""}
                      </Menu>}>
                        <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                      </Dropdown>)}
                    </div>
                  </List.Item>
                )}
              />
            </InfiniteScroll>
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(Mobile);
