// useForm.js
import { useState } from 'react';
import { _setAxios, _success } from 'lib/Helper';

const useExport = (hideModal, headerData) => {
    const [loading, setLoading] = useState(false);

    const handleSubmit = () => {
        setLoading(true);
        let endpoint = "penilaian/mapel/sumatif/ekspor";
        let method = "POST";

        _setAxios(endpoint, method, {
            tahun_ajaran_id: headerData?.tahun_ajaran_id,
            semester_id: headerData?.semester_id,
            rombel_id: headerData?.rombel_id,
            mapel_id: headerData?.mapel_id,
        }).then(resp => {
            if (resp.status) {
                _success('topRight', 'Success', resp.data.message)
                hideModal();
                if (!resp.data?.data?.link) {
                    setLoading(false);
                    return
                }
                setTimeout(() => {
                    window.location.href = resp.data?.data?.link
                }, 300);
            }
            setLoading(false);
        }).catch(err => {
            setLoading(false);
        });
    };

    return {
        loading,
        handleSubmit,
    };
};

export default useExport;
