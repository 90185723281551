import React from 'react';

import { validate } from 'validate.js';
import { Row, Col, Modal, Button, Divider, Form, Typography, Input } from 'antd';
import { _success, _setAxios, _validationFormMsg, _fmtRupiah, _isNumber } from '../../../../lib/Helper';
import { UploadFile } from '../../../../components/upload-files';

const { Title, Text } = Typography;
const schema = {
    nilai_akhir: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    file: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
};

class NilaiEvaluasi extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,

            visible: false,
        };
    }

    componentDidMount() {
        this.setState({
            values: {
                ...this.props.data,
                file: null
            },
        })
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        const errors = validate(values, schema);

        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    handleSubmit = async () => {
        const { values } = this.state
        const errors = validate(values, schema);
        if (errors) {
            return _validationFormMsg(errors)
        }
        this.setState({
            loading: true
        });
        const params = {
            gtk_id: values.id ? values.id : null,
            penilai_id: values.penilai_id ? values.penilai_id : null,
            tahun_ajaran_id: values.tahun_ajaran_id ? values.tahun_ajaran_id : null,
            nilai_akhir: values.nilai_akhir ? _isNumber(values.nilai_akhir) : null,
            file: values.file ? values.file : null,
        }

        let endpoint = "supervisi/penilaian-kinerja-guru/penilaian/evaluasi"
        let method = "POST"

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false,
                    values: {}
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    render() {

        const { values } = this.state
        const { data } = this.props

        return (
            <Modal
                maskClosable={false}
                title={"Nilai Hasil Evaluasi"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                closable={false}
                footer={null}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                    onFinish={this.handleSubmit}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24}>
                            <center>
                                <Title level={4}>{(data.gtk_gelar_depan ? data.gtk_gelar_depan + " " : "") + (data.gtk_nama_lengkap ? data.gtk_nama_lengkap : "-") + (data.gtk_gelar_belakang ? " " + data.gtk_gelar_belakang : "")}</Title>
                                <Text type="secondary">NUPTK: {data.nuptk ? data.nuptk : "-"}</Text><br />
                            </center>
                            <Form.Item label="Nominal *">
                                <Input
                                    style={{ width: '100%' }}
                                    name="nilai_akhir"
                                    placeholder="0"
                                    defaultValue={data.nilai_akhir}
                                    value={values.nilai_akhir ? _fmtRupiah(values.nilai_akhir) : null}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("nilai_akhir", e.target.value)
                                    }} />
                            </Form.Item>
                            <Form.Item label="File *">
                                <UploadFile
                                    isValidation={true}
                                    type="pdf"
                                    onChange={(val) => {
                                        this.handleChangeSetValue("file", val ? val : null)
                                    }}
                                />
                            </Form.Item>

                        </Col>
                        <Col xs={24} style={{ textAlign: "right" }}>
                            <Divider />
                            <Button onClick={this.props.hideModal}>Batal</Button> &nbsp;
                            <Button type="primary" htmlType="submit" loading={this.state.loading}>Simpan</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
}

export default NilaiEvaluasi;
