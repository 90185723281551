import React from 'react';
import { connect } from 'react-redux'
import { Breadcrumb, Table, Row, Col, Divider, Tag, Button, Input, Tooltip, Modal, Dropdown, Menu } from 'antd';
import { PageHeader } from 'components/header';
import { InfoCircleOutlined, SearchOutlined, DoubleRightOutlined, SettingOutlined, DownloadOutlined, ExclamationCircleOutlined, DeleteOutlined, DownCircleOutlined } from '@ant-design/icons';

import { _setAxios, getParamTable, _success, isAuth } from '../../lib/Helper';
import AuthRedirect from '../../components/AuthRedirect';

class LogExport extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            table: {
                loading: true,
                data: [],
                search: "",
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                },
                sorting: [],
                filtering: {
                    tipe: "Export"
                },
            },

            id: 0,
            values: {},
            errors: {},
        };
    }

    componentDidMount() {
        this.fetchTable()
    }

    fetchTable = () => {
        const { table } = this.state
        const params = getParamTable("default", table)
        this.fetch(params);
    }

    fetch = async (params = {}) => {
        this.setState({
            table: {
                ...params,
                loading: true
            }
        });
        _setAxios("log/table", "POST", params).then(resp => {
            if (resp.status === true) {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                        data: resp.data.list,
                        pagination: {
                            ...params.pagination,
                            total: resp.data.info.total
                        }
                    }
                });
            } else {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                    }
                })
            }
        })
    };

    onInformation = (row) => {
        Modal.confirm({
            title: 'Informasi log',
            icon: false,
            content: row.informasi !== null ? row.informasi.map((row, i) => <>
                <ul>
                    <li>
                        Line: {row.line}
                        <ul>
                            {row.errors.map((error, i) => <li>{error}</li>)}
                        </ul>
                    </li>
                </ul>
            </>) : "-",
            cancelText: 'Tutup',
            okText: 'Ok',
            onCancel: () => {
                console.log("No action to process")
            },
        });
    }

    onDelete = (row) => {
        Modal.confirm({
            title: 'Hapus Data',
            width: 470,
            icon: <ExclamationCircleOutlined />,
            content: <>Data akan dihapus dari list, Apakah Anda Yakin ?</>,
            cancelText: 'Batal',
            okText: 'Simpan',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    _setAxios("log/" + row.id, "DELETE").then(resp => {
                        if (resp.status === true) {
                            _success('topRight', 'Success', resp.data.message)
                            this.fetchTable()
                        }
                        setTimeout(Math.random() > 0.5 ? resolve : reject, 100);
                    })
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel: () => {
                console.log("No action to process")
            },
        });
    }


    render() {
        const access = this.props.privilege.access["/log/export"]
        if (!isAuth(this.props.privilege) || access === undefined) {
          return <AuthRedirect />
        }
        const action = access.action
        const { table } = this.state;

        return (
            <>
                <Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
                    <Breadcrumb.Item><SettingOutlined /> Log</Breadcrumb.Item>
                    <Breadcrumb.Item>Export</Breadcrumb.Item>
                </Breadcrumb>
                <Divider className='breadcrumb-line' orientation="left"></Divider>
                <PageHeader
                    className="site-page-header"
                    title="Export"
                    subTitle="Data log export"
                />
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Input
                                name="search"
                                className='search-table'
                                placeholder="Search..."
                                prefix={<SearchOutlined className="site-form-item-icon" />}
                                onChange={(e) => {
                                    const params = getParamTable("search", table, e.target.value)
                                    this.fetch(params)
                                }}
                                value={table.search || ""}
                                suffix={
                                    <Tooltip title="Cari berdasarkan nama">
                                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                    </Tooltip>
                                }
                            />
                        </Col>
                    </Row>

                    <Divider />

                    <Row>
                        <Col span={24}>
                            <Table
                                columns={[
                                    {
                                        title: 'No',
                                        dataIndex: 'rownum',
                                        sorter: false,
                                        width: '3%',
                                    },
                                    {
                                        title: 'Requester',
                                        dataIndex: 'created_by',
                                        sorter: false,
                                    },
                                    {
                                        title: 'Date Time',
                                        dataIndex: 'created_at',
                                        sorter: false,
                                    },
                                    {
                                        title: 'Module',
                                        dataIndex: 'modul',
                                        sorter: false,
                                        width: '20%',
                                    },
                                    {
                                        title: 'Path',
                                        dataIndex: 'path',
                                        sorter: false,
                                        width: '20%',
                                    },
                                    {
                                        title: 'Informasi',
                                        dataIndex: 'path',
                                        sorter: false,
                                        render: (a, row) => row.informasi ? <Button size='small' onClick={this.onInformation.bind(this, row)}>Informasi</Button> : "-",
                                    },
                                    {
                                        title: 'Status',
                                        dataIndex: 'status',
                                        sorter: false,
                                        render: (a, row) => row.status ? <Tag color="#12784A "> {row.status}</Tag> : "-",
                                    },
                                    {
                                        title: 'Result',
                                        dataIndex: 'total',
                                        sorter: false,
                                        render: (a, row) => `${row.total_success} / ${row.total} ${row.total_failed === 0 ? " invalid data" : " valid data"}`
                                    },
                                    {
                                        title: 'File Result',
                                        dataIndex: '',
                                        render: (a, row) => <a rel="noopener noreferrer" href={row.main_path + row.path_result} style={{ fontSize: 11 }}>Download <DownloadOutlined /></a>,
                                    },
                                    {
                                        title: <SettingOutlined />,
                                        align: "center",
                                        key: 'operation',
                                        width: '2%',
                                        render: (a, row) => action.delete ? <Dropdown trigger={['click']} overlay={<Menu>
                                            {action.delete ?  (<Menu.Item key={0} onClick={this.onDelete.bind(this, row)}><DeleteOutlined /> Hapus</Menu.Item>) : ""}
                                        </Menu>}>
                                            <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                                        </Dropdown> : "-",
                                    },
                                ]}
                                rowKey={record => record.id}
                                dataSource={table.data}
                                pagination={table.pagination}
                                loading={table.loading}
                                onChange={(pagination) => {
                                    const params = getParamTable("change", table, null, null, pagination)
                                    this.fetch(params)
                                }}
                                size="small"
                            />
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(LogExport);
