import React from 'react';

import { Row, Col, Modal, Button, Form, Select, Typography } from 'antd';
import { _success, _setAxios } from '../../../../../lib/Helper';

const { Option } = Select;
const { Title } = Typography;

class ModalContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,
        };
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    handleSubmit = async () => {
        const { values } = this.state

        this.setState({
            loading: true
        });
        const params = {
            nilai_semester_ganjil: values.nilai_semester_ganjil ? values.nilai_semester_ganjil : null,
            nilai_semester_genap: values.nilai_semester_genap ? values.nilai_semester_genap : null
        }

        let endpoint = "kaldik-kegiatan/ekstrakurikuler/anggota"
        let method = "POST"
        if (this.props.data.id) {
            endpoint = "kaldik-kegiatan/ekstrakurikuler/anggota/" + this.props.data.id
            method = "PUT"
        }

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    componentDidMount() {
        this.setState({
            values: this.props.data
        })
    }

    render() {

        return (
            <Modal
                maskClosable={false}
                title={"Form Nilai Ekstrakurikuler"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                closable={false}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading}>Simpan</Button>
                </>}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                >
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} style={{textAlign: "center"}}>
                        <Title level={3}>Nama Peserta Didik: {this.props.data.nama}</Title>
                        </Col>
                        <Col xs={24} sm={24}>
                            <Form.Item label="Nilai Semester 1">
                                <Select
                                    allowClear
                                    name="nilai_semester_ganjil"
                                    placeholder="Pilih nilai"
                                    defaultValue={this.props.data.nilai_semester_ganjil}
                                    onChange={(e, newValue) => {
                                        this.handleChangeSetValue("nilai_semester_ganjil", newValue ? newValue.value : null)
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {["A", "B", "C", "D", "E"].map((row, i) => <Option key={i} value={row}>{row}</Option>)}
                                </Select>
                            </Form.Item>
                            <Form.Item label="Nilai Semester 2">
                                <Select
                                    allowClear
                                    name="nilai_semester_genap"
                                    placeholder="Pilih nilai"
                                    defaultValue={this.props.data.nilai_semester_genap}
                                    onChange={(e, newValue) => {
                                        this.handleChangeSetValue("nilai_semester_genap", newValue ? newValue.value : null)
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {["A", "B", "C", "D", "E"].map((row, i) => <Option key={i} value={row}>{row}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

            </Modal>
        )
    }
}

export default ModalContent;
