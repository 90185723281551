import { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { getParamTable, getParamTempTableFiltering, _setAxios } from 'lib/Helper';

const initialTableState = () => ({
    loading_first: false,
    loading: false,
    data: [],
    search: "",
    pagination: {
        current: 1,
        pageSize: 10,
        total: null,
    },
    sorting: [],
    filtering: {
    },
});

const useList = (privilege) => {
    const { tahun_ajaran, semester, access } = privilege
    const { action } = access["/penilaian/kkm-kttp"] || {}

    const [table, setTable] = useState({
        ...initialTableState(),
        filtering: {
            ...initialTableState().filtering,
            tahun_ajaran_id: tahun_ajaran?.id,
            semester_id: semester?.id
        },
    });
    const [formState, setFormState] = useState({ data: {}, visible: false });
    const [filterState, setFilterState] = useState({
        values: {
            ...table.filtering
        }, visible: false
    });
    const [deleteState, setDeleteState] = useState({ visible: false, data: {} });
    const [statisticState, setStatisticState] = useState({
        tingkatan10: {},
        tingkatan11: {},
        tingkatan12: {},
    });

    const fetchTable = (isNew = false) => {
        const params = getParamTable("default", table);
        fetchData(params, isNew);
    };

    const fetchData = useCallback(async (params = {}, isNew = false) => {
        updateTableLoadingState(isNew);
        params.pagination.current = isMobile ? (isNew ? 1 : params.pagination.current) : params.pagination.current;

        const resp = await _setAxios("penilaian/kkm/master/table", "POST", params);
        if (resp.status) {
            setTimeout(() => {
                updateTableData(resp.data, params, isNew);
            }, 0);
        } else {
            resetTableLoadingState();
        }
    }, []);

    const updateTableLoadingState = (isNew) => {
        setTable(prev => ({
            ...prev,
            loading_first: isMobile ? isNew : false,
            loading: true
        }));
    };

    const resetTableLoadingState = () => {
        setTable(prev => ({ ...prev, loading_first: false, loading: false }));
    };

    const updateTableData = (data, params, isNew) => {
        const updatedData = isNew ? [] : params.data;
        const current = params.pagination.current;
        const newCurrent = isMobile ? current + 1 : current;

        setTable(prev => ({
            ...prev,
            ...params,
            loading_first: false,
            loading: false,
            data: isMobile ? [...updatedData, ...data?.list] : data?.list,
            pagination: {
                ...prev.pagination,
                current: newCurrent,
                total: data.info.total,
            },
        }));
    };

    const setTempTableFilter = (name, value) => {
        setFilterState(prev => ({
            ...prev,
            values: { ...prev.values, [name]: value }
        }));
        setTable(prev => ({ ...prev, filtering: { ...prev.filtering, [name]: value } }));
    };

    const setTableFilterFromTemp = () => {
        const params = getParamTempTableFiltering(table, filterState.values);
        fetchData(params, isMobile);
        modalFilter(false);
    };

    const modalFilter = (visible = false) => {
        setFilterState(prev => ({ ...prev, visible }));
    };

    const modalForm = (visible = false, data = {}) => {
        setFormState({ visible, data });
    };

    const modalDelete = (visible = false, data = {}) => {
        setDeleteState({ visible, data });
    };

    const fetchNilaiKkm = useCallback(async (params = {}) => {
        await _setAxios("penilaian/kkm/show-penilaian", "POST", {
            ...params,
            tingkatan_kelas: 10
        }).then((resp) => {
            if (resp.status) {
                setStatisticState(prev => ({
                    ...prev,
                    tingkatan10: resp.data.data
                    //     tingkatan10: {
                    //         "tahun_ajaran_id": "1",
                    //         "semester_id": "1",
                    //         "tingkatan_kelas": "10",
                    //         "kkm_id": "1",
                    //         "kkm_status": "1",
                    //         "kkm_message": "KKM/KTP Seragam",
                    //         "predikat": {
                    //             "nilai": "75",
                    //             "list": [
                    //                 {
                    //                     "predikat_master_id": "1",
                    //                     "label": "A",
                    //                     "start_value": "90",
                    //                     "end_value": "100"
                    //                 },
                    //                 {
                    //                     "predikat_master_id": "1",
                    //                     "label": "B",
                    //                     "start_value": "75",
                    //                     "end_value": "89"
                    //                 },
                    //                 {
                    //                     "predikat_master_id": "1",
                    //                     "label": "C",
                    //                     "start_value": "74",
                    //                     "end_value": "60"
                    //                 },
                    //                 {
                    //                     "predikat_master_id": "1",
                    //                     "label": "D",
                    //                     "start_value": "0",
                    //                     "end_value": "74"
                    //                 }
                    //             ]
                    //         }
                    //     },
                }));
            }
        });
        await _setAxios("penilaian/kkm/show-penilaian", "POST", {
            ...params,
            tingkatan_kelas: 11
        }).then((resp) => {
            if (resp.status) {
                setStatisticState(prev => ({
                    ...prev,
                    tingkatan11: resp.data.data
                    // tingkatan11: {
                    //     "tahun_ajaran_id": "1",
                    //     "semester_id": "1",
                    //     "tingkatan_kelas": "11",
                    //     "kkm_id": "2",
                    //     "kkm_status": "2",
                    //     "kkm_message": "KKM/KTP Tidak Seragam Silahkan klik Detail Nilai KKM untuk melihat pengaturan nilai KKM/KTP",
                    //     "predikat": {
                    //         "nilai": null,
                    //         "list": []
                    //     }
                    // }
                }));
            }
        });
        await _setAxios("penilaian/kkm/show-penilaian", "POST", {
            ...params,
            tingkatan_kelas: 12
        }).then((resp) => {
            if (resp.status) {
                setStatisticState(prev => ({
                    ...prev,
                    tingkatan12: resp.data.data
                    // tingkatan12: {
                    //     "tahun_ajaran_id": "1",
                    //     "semester_id": "1",
                    //     "tingkatan_kelas": "12",
                    //     "kkm_id": null,
                    //     "kkm_status": "0",
                    //     "kkm_message": "Data belum ditambahkan",
                    //     "predikat": {
                    //         "nilai": null,
                    //         "list": []
                    //     }
                    // }
                }));
            }
        });
    }, []);

    useEffect(() => {
        fetchNilaiKkm({
            tahun_ajaran_id: tahun_ajaran?.id,
            semester_id: semester?.id
        });
    }, [fetchNilaiKkm, tahun_ajaran?.id, semester?.id]);

    return {
        action,
        table,
        formState,
        filterState,
        deleteState,
        fetchTable,
        fetchData,
        setTempTableFilter,
        setTableFilterFromTemp,
        statisticState,
        modalFilter,
        modalForm,
        modalDelete,
    };
};

export default useList;
