const columns = (action) => [
  {
    title: 'No',
    dataIndex: 'rownum',
    sorter: false,
    width: '2%',
  }, {
    title: 'Kode Mapel',
    dataIndex: 'mapel_singkatan',
  }, {
    title: 'Mata Pelajaran',
    dataIndex: 'mapel_nama',
  }, {
    title: 'Guru Mapel',
    dataIndex: 'guru_mapel',
  }, {
    title: 'Status Nilai (Semua Siswa)',
    dataIndex: 'nilai_status_complete',
  }, {
    title: 'Jumlah Siswa',
    dataIndex: 'total_penilaian_siswa',
  }
];

export default columns