import { Divider, Tooltip } from "antd";
import { _tableLogActivitySended } from "lib/Helper";

const columns = (action) => [
  {
    title: 'No',
    dataIndex: 'rownum',
    sorter: false,
    width: '2%',
  }, {
    title: 'Username',
    dataIndex: 'user_name',
    sorter: false,
  }, {
    title: 'Tipe User',
    dataIndex: 'user_type',
    sorter: false,
  }, {
    title: 'Jenis User',
    dataIndex: 'user_jenis',
    sorter: false,
  }, {
    title: 'No.Telp',
    dataIndex: 'no_telp',
    sorter: false,
  }, {
    title: 'Modul',
    dataIndex: 'modul',
    sorter: false,
  }, {
    title: 'Status',
    sorter: false,
    render: (a, row) => row.status ? row.status : "-",
  }, {
    title: 'Payload & Response',
    dataIndex: 'payload',
    sorter: false,
    render: (a, row) => {
      let payload = row.payload.replace(/\n/g, '\\n').replace(/\r/g, '\\r');
      payload = JSON.parse(payload);

      return <>
        Payload
        <div><b>Phone:</b> {payload ? payload.phone_no : "-"}</div>
        <div>
        <Tooltip title={payload ? payload.message : "-"} width={300}>
          <b>Message:</b> {payload ? payload.message.substring(1, 150) + "..." : "-"}
        </Tooltip>
      </div>
        <Divider />
        Response
        <div><b>Status:</b> {row.response ? (JSON.parse(row.response).status ? JSON.parse(row.response).status : "-") : "-"}</div>
        <div><b>Message:</b> {row.response ? (JSON.parse(row.response).message ? JSON.parse(row.response).message : "-") : "-"}</div>
      </>
    }
  },
  {
    title: 'LogActivity',
    key: 'operation',
    width: '10%',
    render: (a, row) => _tableLogActivitySended(row),
  },
];

export default columns