import React from 'react';
import { Modal, Button } from 'antd';

import { _success, _setAxios } from '../../../../lib/Helper';
import { InfoCircleOutlined } from '@ant-design/icons';

class DeleteMulti extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false
        };
    }

    handleSubmit = () => {
        this.setState({
            loading: true
        });

        _setAxios("notifikasi-wa/ppdb", "DELETE", {
            "id": this.props.ids
        }).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    render() {

        return (
            <Modal
                maskClosable={false}
                title={<><InfoCircleOutlined /> Konfirmasi</>}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                width={300}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading}>Simpan</Button>
                </>}
            >
                Apakah Anda Yakin?
            </Modal>
        )
    }
}

export default DeleteMulti;
