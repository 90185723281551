import { _tableLogActivity } from "lib/Helper";
import Action from "./Action";
import { SettingOutlined } from "@ant-design/icons";

const columns = (action, modalSend) => [
  {
    title: 'No',
    dataIndex: 'rownum',
    width: '2%',
  }, {
    title: 'Mata Pelajaran',
    dataIndex: 'mapel_nama',
  }, {
    title: 'Rombel',
    dataIndex: 'rombel_nama',
  }, {
    title: 'Kurikulum',
    dataIndex: 'kurikulum_nama',
  }, {
    title: 'Jumlah Siswa Dinilai',
    dataIndex: 'total_penilaian_siswa',
  }, {
    title: 'Status',
    dataIndex: 'status',
  }, {
    title: <SettingOutlined />,
    align: "center",
    key: 'operation',
    width: '2%',
    render: (a, row) => <Action row={row} action={action} modalSend={modalSend}/>,
  },
  {
    title: 'LogActivity',
    key: 'operation',
    width: '10%',
    render: (a, row) => _tableLogActivity(row),
  },
];

export default columns