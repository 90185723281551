const columns = () => [
  {
    title: 'No',
    dataIndex: 'rownum',
    sorter: false,
    width: '2%',
  }, {
    title: 'Nisn',
    dataIndex: 'nisn',
    render: (a, row) => <>{row.nisn ? row.nisn : "-"}</>,
  }, {
    title: 'Nis',
    dataIndex: 'nis',
    render: (a, row) => <>{row.nis ? row.nis : "-"}</>,
  }, {
    title: 'Nama Peserta Didik',
    dataIndex: 'nama',
  }, {
    title: 'Sumatif',
    dataIndex: 'sumatif_nilai',
    align: "center",
  }, {
    title: 'Pas/Pat',
    dataIndex: 'pas_nilai',
    align: "center",
  }, {
    title: 'Rapor',
    dataIndex: 'rapor_nilai',
  }, {
    title: 'Capaian Competensi',
    dataIndex: 'capaian_kompetensi',
    render: (a, row) => <>{row.capaian_kompetensi ? row.capaian_kompetensi : "-"}</>,
  }
];

export default columns