import { CloseCircleOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";

const columns = (table, action, modalForm, modalDelete) => {

  let columns = [
    {
      title: 'Nisn',
      dataIndex: 'nisn',
      render: (a, row) => <>{row.nisn ? row.nisn : "-"}</>,
    }, {
      title: 'Nis',
      dataIndex: 'nis',
      render: (a, row) => <>{row.nis ? row.nis : "-"}</>,
    }, {
      title: 'Nama Peserta Didik',
      dataIndex: 'nama',
    }, {
      title: 'Jenis Kelamin',
      dataIndex: 'jenis_kelamin',
    }
  ]

  let columnsChild = [];
  table?.sumatif?.forEach(item => {
    columnsChild.push({
      title: <>
        {item.nama}
        <Space size="small" style={{ float: "right" }}>
          {action?.sumatif_update && <Button type="primary" size="small" icon={<EditOutlined />} onClick={modalForm.bind(this, true, item)} />}
          {action?.sumatif_delete && <Button type="primary" size="small" icon={<CloseCircleOutlined />} onClick={modalDelete.bind(this, true, item)} />}
        </Space>
      </>,
      dataIndex: item.id,
    })
  });

  columns.push({
    title: 'Nilai',
    children: columnsChild,
  });

  return columns
};

export default columns