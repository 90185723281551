import { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { getParamTable, _setAxios, getParamTempTableFiltering } from 'lib/Helper';

const initialTableState = () => ({
    titleMobile: "nama",
    loading_first: false,
    loading: false,
    data: [],
    search: "",
    pagination: {
        current: 1,
        pageSize: 1000,
        total: null,
    },
    sorting: [],
    filtering: {
    },
});

const useList = (privilege, match) => {
    const { access } = privilege
    const action = access["/penilaian/nilai-mapel"]?.action

    const [table, setTable] = useState({
        ...initialTableState(),
        filtering: {
            ...initialTableState().filtering,
        },
    });
    const [headerData, setHeaderData] = useState([])
    const [exportState, setExportState] = useState({ visible: false });

    const fetchData = useCallback(async (params = {}, isNew = false, headValues = {}) => {
        updateTableLoadingState(isNew);
        params.pagination.current = isMobile ? (isNew ? 1 : params.pagination.current) : params.pagination.current;

        const resp = await _setAxios("penilaian/mapel/pas/table", "POST", params);
        if (resp.status) {
            setTimeout(() => {
                updateTableData(resp.data, params, isNew, headValues);
            }, 0);
        } else {
            resetTableLoadingState();
        }
    }, []);

    const fetchTable = useCallback((isNew = false, paramsCustom = null) => {
        const params = getParamTable("default", table);
        fetchData(paramsCustom || params, isNew);
    }, [table, fetchData]);

    const updateTableLoadingState = (isNew) => {
        setTable(prev => ({
            ...prev,
            loading_first: isMobile ? isNew : false,
            loading: true
        }));
    };

    const resetTableLoadingState = () => {
        setTable(prev => ({ ...prev, loading_first: false, loading: false }));
    };

    const updateTableData = (data, params, isNew, headValues) => {
        const updatedData = isNew ? [] : params.data;
        const current = params.pagination.current;
        const newCurrent = isMobile ? current + 1 : current;

        if (headValues) {
            data.list = data?.list.map(item => {
                if (headValues.sakit) {
                    item.sakit = headValues.sakit
                }
                if (headValues.izin) {
                    item.izin = headValues.izin
                }
                if (headValues.alpha) {
                    item.alpha = headValues.alpha
                }
                return item
            })
        }

        setTable(prev => ({
            ...prev,
            ...params,
            loading_first: false,
            loading: false,
            data: isMobile ? [...updatedData, ...data?.list] : data?.list,
            pagination: {
                ...prev.pagination,
                current: newCurrent,
                total: data.info.total,
            },
        }));
    };

    const getHeader = useCallback(async (tugasId) => {
        const resp = await _setAxios("penilaian/mapel/rombel/" + tugasId, "GET");
        if (resp.status) {
            const data = resp?.data?.data;

            const params = getParamTempTableFiltering(table, {
                tahun_ajaran_id: data?.tahun_ajaran_id,
                semester_id: data?.semester_id,
                rombel_id: data?.rombel_id,
                mapel_id: data?.mapel_id
            });
            setHeaderData(data || []);

            const isNew = isMobile ? true : false;
            fetchTable(isNew, params);
            setTable(prev => ({
                ...prev,
                filtering: {
                    ...prev.filtering,
                    tahun_ajaran_id: data?.tahun_ajaran_id,
                    semester_id: data?.semester_id,
                    rombel_id: data?.rombel_id,
                    mapel_id: data?.mapel_id
                }
            }));
        }
    }, [fetchTable, table]);

    const modalExport = (visible = false) => {
        setExportState({ visible });
    };

    useEffect(() => {
        getHeader(match.params.tugas_id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match.params.tugas_id])

    return {
        action,
        headerData,
        table,
        exportState,
        setTable,
        fetchTable,
        fetchData,
        modalExport,
    };
};

export default useList;
