import React, { useEffect, useState } from 'react';
import { List, Skeleton, Divider } from 'antd';
import { _setAxios } from '../../lib/Helper';
import { useParams } from 'react-router-dom';

async function getSiswaByID(siswaId, rombelId) {
    if (!siswaId || !rombelId) {
        return []
    }
    return await _setAxios("pelanggaran-siswa/table-header/" + siswaId + "/" + rombelId, "GET", null).then(resp => {
        return resp
    })
}

const HeaderPelanggaranSiswa = () => {

    const [loading, setLoading] = useState(true);
    const [header, setHeader] = useState();
    const { id, rombel_id } = useParams();

    useEffect(() => {
        getSiswaByID(id, rombel_id).then(resp => {
            let data = resp.data.data
            console.log("data", data);
            setLoading(false)
            setHeader([{
                title: 'NISN',
                description: data.nisn ? data.nisn : "-",
            },
            {
                title: 'Nama Peserta Didik',
                description: data.nama ? data.nama : "-",
            },
            {
                title: "Jenis Kelamin",
                description: data.jenis_kelamin ? data.jenis_kelamin : "-",
            },
            {
                title: "Rombel",
                description: data.rombel_nama ? data.rombel_nama : "-",
            },
            {
                title: "Total Poin Pelanggaran",
                description: data.total_poin ? data.total_poin : "-",
            }
            ])
        })
    }, [id, rombel_id]);

    return (
        <>
            <div className="site-layout-background">
                {
                    !loading ?
                        <List
                            itemLayout="horizontal"
                            dataSource={header}
                            renderItem={item => (
                                <List.Item>
                                    <List.Item.Meta
                                        title={<><div style={{ float: "left", minWidth: 50 }}>{item.title}</div> : <small style={{ color: "rgb(119 105 105)" }}>{item.description}</small></>}
                                    />
                                </List.Item>
                            )}
                        />
                        :
                        <Skeleton />
                }
            </div>

            <Divider />
        </>
    );
};
export default HeaderPelanggaranSiswa;
