import { useCallback, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { getParamTable, _setAxios } from 'lib/Helper';

const initialTableState = () => ({
    loading_first: false,
    loading: false,
    data: [],
    search: "",
    pagination: {
        current: 1,
        pageSize: 1000,
        total: null,
    },
    sorting: [],
    filtering: {
    },
});

const useList = (privilege, headerData) => {
    const { tahun_ajaran, semester } = privilege
    const [table, setTable] = useState({
        ...initialTableState(),
        filtering: {
            ...initialTableState().filtering,
            tahun_ajaran_id: tahun_ajaran?.id,
            semester_id: semester?.id,
            rombel_id: headerData?.rombel_id
        },
    });

    const fetchTable = (isNew = false, headValues = {}) => {
        const params = getParamTable("default", table);
        fetchData(params, isNew, headValues);
    };

    const fetchData = useCallback(async (params = {}, isNew = false, headValues = {}) => {
        updateTableLoadingState(isNew);
        params.pagination.current = isMobile ? (isNew ? 1 : params.pagination.current) : params.pagination.current;

        const resp = await _setAxios("penilaian/wali-kelas/catatan/table", "POST", params);
        if (resp.status) {
            setTimeout(() => {
                updateTableData(resp.data, params, isNew, headValues);
            }, 0);
        } else {
            resetTableLoadingState();
        }
    }, []);

    const updateTableLoadingState = (isNew) => {
        setTable(prev => ({
            ...prev,
            loading_first: isMobile ? isNew : false,
            loading: true
        }));
    };

    const resetTableLoadingState = () => {
        setTable(prev => ({ ...prev, loading_first: false, loading: false }));
    };

    const updateTableData = (data, params, isNew, headValues) => {
        const updatedData = isNew ? [] : params.data;
        const current = params.pagination.current;
        const newCurrent = isMobile ? current + 1 : current;

        if (headValues) {
            data.list = data?.list.map(item => {
                if(headValues.catatan){
                    item.sakit = headValues.catatan
                }
                return item
            })
        }

        setTable(prev => ({
            ...prev,
            ...params,
            loading_first: false,
            loading: false,
            data: isMobile ? [...updatedData, ...data?.list] : data?.list,
            pagination: {
                ...prev.pagination,
                current: newCurrent,
                total: data.info.total,
            },
        }));
    };

    return {
        table,
        setTable,
        fetchTable,
        fetchData,
    };
};

export default useList;
