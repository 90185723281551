import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { _setAxios } from 'lib/Helper';

async function fetchList() {

  return await _setAxios("ruangan/dropdown", "POST", {
    jenis_ruangan_id: 5,
    is_booking: 0
})
    .then(body => {
      let results = []
      body.data?.data?.forEach(row => {
        results.push({
          label: row.nama,
          value: row.id,
        })
      });

      return results
    })
}

const SelectRuanganKelas = ({ ...props }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    fetchList().then((newOptions) => {
      setOptions(newOptions);
    });
  }, []);

  return (
    <Select
      {...props}
      placeholder="Pilih ruangan"
      style={{
        width: '100%',
      }}
      options={options}
    />
  );
};
export default SelectRuanganKelas;
