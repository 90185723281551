import { InputNumber } from "antd";

const columns = (action, handleInputChange) => [
  {
    title: 'No',
    dataIndex: 'rownum',
    width: '5%',
  }, {
    title: 'Nisn',
    dataIndex: 'nisn',
  }, {
    title: 'Nis',
    dataIndex: 'nis',
    render: (a, row) => <>{row.nis ? row.nis : "-"}</>,
  }, {
    title: 'Nama Peserta Didik',
    dataIndex: 'nama',
  }, {
    title: 'Jenis Kelamin',
    dataIndex: 'jenis_kelamin',
  }, {
    titleMobile: <span style={{lineHeight: "40px"}}>Nilai</span>,
    title: action?.sumatif_update ? <>
    Nilai<br />
    <InputNumber
      placeholder="0"
      min={0}
      onChange={(value) => handleInputChange(0, 'nilai', value)}
    />
  </> : "Nilai",
    align: "center",
    dataIndex: 'nilai',
    key: 'nilai',
    render: (i, row) => (
      action?.sumatif_update ? <InputNumber
        min={0}
        defaultValue={row.nilai || 0}
        value={row.nilai}
        onChange={(value) => handleInputChange(row.id, 'nilai', value)}
      /> : (row.nilai || 0)
    ),
  },
];

export default columns