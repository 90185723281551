
// PROD MAN
// var config = {
//     "base_url": "https://intsatria.man1gresik.sch.id",
//     "host_api": "https://apisatria.man1gresik.sch.id",
//     "api": "https://apisatria.man1gresik.sch.id/api",
//     "landing_page": "https://satria.man1gresik.sch.id",
//     "app_internal": "https://intsatria.man1gresik.sch.id",
//     "app_external": "https://extsatria.man1gresik.sch.id",
//     "app_ppdb": "https://ppdb.man1gresik.sch.id",
//     "secret_key": "m4nD1g1t4l",
//     "roles": {
//         "super_user": [
//             "developer", "admin", "operator", "quality control"
//         ]
//     },
//     "location": {
//         "lat": -7.052637478800448,
//         "lng": 112.57071828401445
//     }
// }


// TRAINING MAN
// var config = {
//     "base_url": "https://internal.training.mandigital.man1gresik.sch.id",
//     "host_api": "https://api.training.mandigital.man1gresik.sch.id",
//     "api": "https://api.training.mandigital.man1gresik.sch.id/api",
//     "landing_page": "https://dev.mandigital.man1gresik.sch.id",
//     "app_internal": "https://internal.training.mandigital.man1gresik.sch.id",
//     "app_external": "https://external.training.mandigital.man1gresik.sch.id",
//     "app_ppdb": "https://ppdb.training.man1gresik.sch.id",
//     "secret_key": "m4nD1g1t4l",
//     "roles": {
//         "super_user": [
//             "developer", "admin", "operator", "quality control"
//         ]
//     }
// }


// DEV MAN
var config = {
    "base_url": "https://internal.dev.mandigital.man1gresik.sch.id",
    "host_api": "https://api.dev.mandigital.man1gresik.sch.id",
    "api": "https://api.dev.mandigital.man1gresik.sch.id/api",
    "landing_page": "https://dev.mandigital.man1gresik.sch.id",
    "app_internal": "https://internal.dev.mandigital.man1gresik.sch.id",
    "app_external": "https://external.dev.mandigital.man1gresik.sch.id",
    "app_ppdb": "https://ppdb.dev.man1gresik.sch.id",
    "secret_key": "m4nD1g1t4l",
    "roles": {
        "super_user": [
            "developer", "admin", "operator", "quality control"
        ]
    },
    "location": {
        "lat": -7.052637478800448,
        "lng": 112.57071828401445
    }
}

// LOCAL DEV 
// var config = {
//     "base_url": "http://localhost:3000",
//     // "base_url": "http://192.168.18.76:3000",
//     "host_api": "https://api.dev.mandigital.man1gresik.sch.id",
//     // "host_api": "https://apisatria.man1gresik.sch.id",
//     "api": "https://api.dev.mandigital.man1gresik.sch.id/api",
//     // "api": "https://apisatria.man1gresik.sch.id/api",
//     "landing_page": "https://dev.mandigital.man1gresik.sch.id",
//     "app_internal": "https://internal.dev.mandigital.man1gresik.sch.id",
//     "app_external": "https://external.dev.mandigital.man1gresik.sch.id",
//     "app_ppdb": "https://ppdb.dev.man1gresik.sch.id",
//     "secret_key": "m4nD1g1t4l",
//     "roles": {
//         "super_user": [
//             "developer", "admin", "operator", "quality control"
//         ],
//     },
//     "location": {
//         "lat": -7.052637478800448,
//         "lng": 112.57071828401445
//     }
// }

export default config
