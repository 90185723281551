import React from 'react';
import { List, Skeleton, Divider, Col, Row } from 'antd';

const HeaderWaliKelas = ({ data, loading }) => {

    return (
        <>
            <div className="site-layout-background">
                <Row>
                    <Col span={12}>
                        {
                            !loading ?
                                <List
                                    itemLayout="horizontal"
                                    dataSource={[{
                                        title: 'Tahun Ajaran',
                                        description: data?.tahun_ajaran || "-",
                                    },
                                    {
                                        title: 'Semester',
                                        description: data?.tipe_semester || "-",
                                    },
                                    {
                                        title: "Tingkatan Kelas",
                                        description: data?.tingkatan_kelas || "-",
                                    },
                                    {
                                        title: "Rombel",
                                        description: data?.rombel_nama || "-",
                                    }]}
                                    renderItem={item => (
                                        <List.Item >
                                            <List.Item.Meta
                                                title={<><div style={{ float: "left", minWidth: 50 }}>{item.title}</div> : <small style={{ color: "rgb(119 105 105)" }}>{item.description}</small></>}
                                            />
                                        </List.Item>
                                    )}
                                />
                                :
                                <Skeleton />
                        }
                    </Col>
                    <Col span={12}>
                        {
                            !loading ?
                                <List
                                    itemLayout="horizontal"
                                    dataSource={[{
                                        title: "Ruang Kelas",
                                        description: data?.ruangan_nama || "-",
                                    },
                                    {
                                        title: "Kurikulum",
                                        description: data?.kurikulum_nama || "-",
                                    },
                                    {
                                        title: "Jurusan",
                                        description: data?.jurusan_nama || "-",
                                    },
                                    ]}
                                    renderItem={item => (
                                        <List.Item >
                                            <List.Item.Meta
                                                title={<><div style={{ float: "left", minWidth: 50 }}>{item.title}</div> : <small style={{ color: "rgb(119 105 105)" }}>{item.description}</small></>}
                                            />
                                        </List.Item>
                                    )}
                                />
                                :
                                <Skeleton />
                        }
                    </Col>
                </Row>
            </div>

            <Divider />
        </>
    );
};
export default HeaderWaliKelas;
