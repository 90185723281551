import React from 'react';
import { connect } from 'react-redux';
import { isAuth } from 'lib/Helper';
import { Row, Col, BackTop, Breadcrumb, Button } from 'antd';
import { DoubleRightOutlined, FileDoneOutlined } from '@ant-design/icons';
import { MobileInfiniteScroll, TableList } from 'components/list';
import { PageHeader } from 'components/header';
import SearchTable from 'components/search-table/SearchTable';
import columns from './Columns';
import useList from '../hooks/useList';
import AuthRedirect from 'components/AuthRedirect';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import Header from './Header';


const PenilaianNilaiMapelDetail = ({ privilege, match }) => {
  const {
    action,
    headerData,
    bobotData,
    kkmData,
    table,
    fetchTable,
    fetchData,
  } = useList(privilege, match);

  if (!isAuth(privilege) || (action === undefined || action?.detail === undefined)) {
    return <AuthRedirect />
  }

  return (
    <>
      <PageHeader
        style={{
          padding: 0
        }}
        onBack={() => window.history.back()}
        title={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
          <Breadcrumb.Item>Penilaian</Breadcrumb.Item>
          <Breadcrumb.Item><Link to="/penilaian/nilai-mapel">Nilai Mata Pelajaran</Link> </Breadcrumb.Item>
          <Breadcrumb.Item>Detail </Breadcrumb.Item>
        </Breadcrumb>}
      />

      <Header data={headerData} bobotData={bobotData} kkmData={kkmData} />

      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>

        <PageHeader
          className="site-page-header"
          title={`Detail Nilai Mata Pelajaran`}
          extra={[
            action?.sumatif_view && <Link to={`/penilaian/nilai-mapel/detail/sumatif/${match?.params?.tugas_id}`}><Button icon={<FileDoneOutlined />} type="primary" >Sumatif</Button></Link>,
            action?.paspat_view && <Link to={`/penilaian/nilai-mapel/detail/pas-pat/${match?.params?.tugas_id}`}><Button icon={<FileDoneOutlined />} type="primary" >PAS/PAT</Button></Link>,
          ]} />

        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <SearchTable table={table} fetch={fetchData} />
          </Col>
          <Col xs={24}>
            {/* <MobileInfiniteScroll/> */}
            <TableList
              isFetchFirst={false}
              state={{ table }}
              fetch={fetchData}
              fetchTable={fetchTable}
              columns={columns()}
            />
          </Col>
        </Row>
      </div>
      <BackTop />
    </>
  );
};

const mapStateToProps = (state) => ({
  privilege: state.privilege,
});

export default connect(mapStateToProps)(PenilaianNilaiMapelDetail);