// useForm.js
import { useEffect, useState } from 'react';
import { _getDate, _setAxios, _success } from 'lib/Helper';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useParams } from 'react-router-dom';

dayjs.extend(customParseFormat);

const useForm = (data, fetchTable, hideModal) => {
    const { id, tahun_ajaran_id, semester_id, rombel_id } = useParams();
    const [values, setValues] = useState({
        ...data,
        tahun_ajaran_id: tahun_ajaran_id,
        semester_id: semester_id,
        rombel_id: rombel_id,
        tgl: data?.tgl ? dayjs(data?.tgl) : dayjs(),
        penghargaan_id: data?.penghargaan_id ? data?.penghargaan_id : null
    });
    const [loading, setLoading] = useState(false);
    const handleChangeSetValue = (name, value) => {
        setValues(prevValues => ({ ...prevValues, [name]: value }));
    };

    const handleSubmit = (val) => {

        let tgl = _getDate(values.tgl)

        const params = {
            tgl: tgl,
            siswa_id: id,
            penghargaan_id: values.penghargaan_id ? values.penghargaan_id : null,
            keterangan: values.keterangan ? values.keterangan : null,
            tahun_ajaran_id: tahun_ajaran_id,
            semester_id: semester_id,
            rombel_id: rombel_id
        };

        let endpoint = "penghargaan-siswa";
        let method = "POST";

        if (data?.id) {
            endpoint = `${endpoint}/${data?.id}`;
            method = "PUT";
        }

        setLoading(true);
        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status) {
                _success('topRight', 'Success', resp.data.message)
                fetchTable(true);
                hideModal();
            }
            setLoading(false);
        }).catch(err => {
            setLoading(false);
        });
    };

    return {
        values,
        loading,
        handleChangeSetValue,
        handleSubmit,
    };
};

export default useForm;
