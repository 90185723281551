import Desktop from './Desktop';
import Mobile from './Mobile';
import DesktopOnly from './DesktopOnly';
import MobileInfiniteScroll from './MobileInfiniteScroll';

const TableList = (props) => {
    return <>
        <Desktop {...props} />
        <Mobile {...props} />
    </>
}


export { Desktop, Mobile, TableList, DesktopOnly, MobileInfiniteScroll }