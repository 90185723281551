import { _tableLogActivity } from "lib/Helper";
import Action from "./Action";
import { SettingOutlined } from "@ant-design/icons";
import { Tag } from "antd";

const columns = (action, modalForm, modalDelete, modalApproval, modalDetail) => [
  {
    title: 'No',
    dataIndex: 'rownum',
    sorter: false,
    width: '2%',
  }, {
    title: 'Nilai KKM/KTTP',
    dataIndex: 'nilai',
    width: '20%',
    align: "center",
    render: (a, row) => <Tag>{row.nilai ? row.nilai : "0"}</Tag>,
  }, {
    title: 'Mapel',
    dataIndex: 'mapel',
  }, {
    title: <SettingOutlined />,
    align: "center",
    key: 'operation',
    width: '2%',
    render: (a, row) => <Action row={row} action={action} modalForm={modalForm} modalDelete={modalDelete} modalDetail={modalDetail}/>,
  },
  {
    title: 'LogActivity',
    key: 'operation',
    width: '10%',
    render: (a, row) => _tableLogActivity(row),
  },
];

export default columns