import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Row, Col, Modal, Button, Form, Input, Divider, Select } from 'antd';
import { _success, _setAxios } from '../../../../lib/Helper';
import SelectTenagaPendidik from '../../../../components/select/SelectTenagaPendidik';
import "./style.css";
import { daysOptions } from '../../../../data/options';
import SelectTahunAjaran from '../../../../components/select/SelectTahunAjaran';
import { SelectRuanganKelas } from 'components/select';

const { TextArea } = Input;
const { Option } = Select;

const FormContent = (props) => {
    const [values, setValues] = useState({ ...props.data, nama_ekstrakurikuler: props.data.nama });
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm(); // Initialize form instance



    const handleChangeSetValue = (name, value) => {
        setValues(prevValues => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleSubmit = async () => {
        setLoading(true);
        const jadwals = Array.isArray(values.jadwal) ? values.jadwal.map(el => el.value || el) : values.jadwal;
        const params = {
            nama: values.nama_ekstrakurikuler || null,
            tk_id: values.tk_id || null,
            tk_eksternal: values.tk_eksternal || null,
            ruangan_id: values.ruangan || null,
            tahun_ajaran_id: values.tahun_ajaran_id || null,
            keterangan: values.keterangan || null,
            jadwal: jadwals.length > 0 ? jadwals.toString() : null,
        };

        const endpoint = values.id ? `kaldik-kegiatan/ekstrakurikuler/master/${values.id}` : "kaldik-kegiatan/ekstrakurikuler/master";
        const method = values.id ? "PUT" : "POST";

        const response = await _setAxios(endpoint, method, params);

        if (response.status === true) {
            _success('topRight', 'Success', response.data.message);
            props.fetchTable(true);
            props.hideModal();
        }

        setLoading(false);
    };

    return (
        <Modal
            maskClosable={false}
            title="Form Ekstrakurikuler"
            open={props.visible}
            onCancel={props.hideModal}
            closable={false}
            footer={null}
        >
            <Form
                form={form}
                name="basic"
                autoComplete="off"
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={values}
            >
                <Row gutter={[16, 0]}>
                    <Col xs={24}>
                        <Form.Item
                            label="Nama Ekstrakurikuler"
                            name="nama_ekstrakurikuler"
                            rules={[{ required: true, message: 'Nama Ekstrakurikuler wajib diisi' }]}>
                            <Input
                                placeholder="Nama Ekstrakurikuler"
                                defaultValue={props.data.nama}
                                onChange={(e) => handleChangeSetValue("nama_ekstrakurikuler", e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Tahun Ajaran"
                            name={"tahun_ajaran_id"}
                            rules={[{ required: true, message: 'Tahun Ajaran wajib dipilih' }]}>
                            <SelectTahunAjaran
                                onChange={(val) => handleChangeSetValue("tahun_ajaran_id", val || null)}
                            />
                        </Form.Item>
                        <Form.Item label="Guru Ekstrakurikuler Internal">
                            <SelectTenagaPendidik
                                setValue={values.tk_id}
                                placeholder="Guru ekstrakurikuler"
                                setLabel={(values.gelar_depan ? values.gelar_depan + ". " : "") + values.nama_lengkap + (values.gelar_belakang ? ". " + values.gelar_belakang : "")}
                                onChange={(val) => handleChangeSetValue("tk_id", val)}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Guru Ekstrakurikuler Eksternal"
                            name="tk_eksternal">
                            <Input
                                placeholder="Nama guru ekstrakurikuler eksternal"
                                defaultValue={props.data.tk_eksternal}
                                onChange={(e) => handleChangeSetValue("tk_eksternal", e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item label="Nama Ruangan" name="ruangan">
                            <SelectRuanganKelas
                                defaultValue={props.data.ruangan_id}
                                onChange={(value) => handleChangeSetValue("ruangan", value ? value : null)}
                            />
                        </Form.Item>
                        <Form.Item
                            name="jadwal"
                            label="Jadwal Pertemuan"
                            rules={[{ required: true, message: 'Jadwal pertemuan wajib dipilih' }]}
                        >
                            <Select
                                allowClear
                                mode="multiple"
                                placeholder="Jadwal pertemuan"
                                defaultValue={props.data.jadwal || []}
                                onChange={(value, option) => handleChangeSetValue("jadwal", option)}
                                style={{ width: '100%' }}
                            >
                                {daysOptions.map((row, i) => <Option key={i} value={row.value}>{row.value}</Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item label="Keterangan">
                            <TextArea
                                rows={4}
                                placeholder="Keterangan"
                                defaultValue={props.data.keterangan}
                                onChange={(e) => handleChangeSetValue("keterangan", e.target.value)}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} style={{ textAlign: "right" }}>
                        <Divider />
                        <Button onClick={props.hideModal}>Batal</Button> &nbsp;
                        <Button type="primary" htmlType="submit" loading={loading}>Simpan</Button>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    privilege: state.privilege,
});

export default connect(mapStateToProps)(FormContent);
