import React from 'react';
import { Modal, Button, Form, Row, Col } from 'antd';
import { SelectTenagaPendidik, SelectTahunAjaran } from '../../../../components/select';

class Filter extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,
        };
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    render() {
        return (
            <Modal
                title="Filter"
                width={300}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Tutup</Button>
                    <Button type='primary' onClick={this.props.setTableFilterFromTemp}>Terapkan</Button>
                </>}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                >
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Form.Item label="Tahun Ajaran">
                                <SelectTahunAjaran
                                    defaultValue={this.props.data.tahun_ajaran_id}
                                    onChange={(val) => {
                                        this.props.setTempTableFilter("tahun_ajaran_id", val ? val : null)
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label="Nama GTK">
                                <SelectTenagaPendidik
                                    value={this.props.data.gtk_id ? this.props.data.gtk_id : null}
                                    allowClear
                                    onChange={(val) => {
                                        this.props.setTempTableFilter("gtk_id", val ? val : null)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form >
            </Modal >
        )
    }
}

export default Filter;
