import { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { getParamTable, getParamTempTableFiltering, _setAxios } from 'lib/Helper';

const initialTableState = () => ({
    titleMobile: "nama",
    loading_first: false,
    loading: false,
    data: [],
    search: "",
    pagination: {
        current: 1,
        pageSize: 2,
        total: null,
    },
    sorting: [],
    filtering: {
    },
});

const useList = (privilege, match) => {
    const { access } = privilege
    const { action } = access["/penilaian/nilai-mapel"] || {}

    const [headerData, setHeaderData] = useState([])
    const [bobotData, setBobotData] = useState([])
    const [kkmData, setKkmData] = useState([])
    const [table, setTable] = useState({
        ...initialTableState(),
        filtering: {
            ...initialTableState().filtering,
        },
    });
    const [filterState, setFilterState] = useState({
        values: {
            ...table.filtering
        }, visible: false
    });

    const fetchData = useCallback(async (params = {}, isNew = false) => {
        updateTableLoadingState(isNew);
        params.pagination.current = isMobile ? (isNew ? 1 : params.pagination.current) : params.pagination.current;

        const resp = await _setAxios("penilaian/mapel/rombel/table-summary", "POST", params);
        if (resp.status) {
            setTimeout(() => {
                updateTableData(resp.data, params, isNew);
            }, 0);
        } else {
            resetTableLoadingState();
        }
    }, []);

    const fetchTable = useCallback((isNew = false, paramsCustom = null) => {
        const params = getParamTable("default", table);
        fetchData(paramsCustom || params, isNew);
    }, [table, fetchData]);

    const updateTableLoadingState = (isNew) => {
        setTable(prev => ({
            ...prev,
            loading_first: isMobile ? isNew : false,
            loading: true
        }));
    };

    const resetTableLoadingState = () => {
        setTable(prev => ({ ...prev, loading_first: false, loading: false }));
    };

    const updateTableData = (data, params, isNew) => {
        const updatedData = isNew ? [] : params.data;
        const current = params.pagination.current
        const newCurrent = isMobile ? current + 1 : current;

        setTable({
            ...params,
            loading_first: false,
            loading: false,
            data: isMobile ? [...updatedData, ...data?.list] : data?.list,
            pagination: {
                ...params.pagination,
                current: newCurrent,
                total: data.info.total,
            },
        });
    };

    const setTempTableFilter = (name, value) => {
        setFilterState(prev => ({
            ...prev,
            values: { ...prev.values, [name]: value }
        }));
        setTable(prev => ({ ...prev, filtering: { ...prev.filtering, [name]: value } }));
    };

    const setTableFilterFromTemp = () => {
        const params = getParamTempTableFiltering(table, filterState.values);
        fetchData(params, isMobile);
    };

    const getHeader = useCallback(async (tugasId) => {
        const resp = await _setAxios("penilaian/mapel/rombel/" + tugasId, "GET");
        if (resp.status) {
            const data = resp?.data?.data;

            getBobot(data);
            getNilaiKKM(data);

            const params = getParamTempTableFiltering(table, {
                tahun_ajaran_id: data?.tahun_ajaran_id,
                semester_id: data?.semester_id,
                rombel_id: data?.rombel_id,
                mapel_id: data?.mapel_id
            });
            setHeaderData(data || []);

            const isNew = isMobile ? true : false;
            fetchTable(isNew, params);
            setTable(prev => ({
                ...prev, filtering: {
                    ...prev.filtering,
                    tahun_ajaran_id: data?.tahun_ajaran_id,
                    semester_id: data?.semester_id,
                    rombel_id: data?.rombel_id,
                    mapel_id: data?.mapel_id
                }
            }));
        }
    }, [fetchTable, table]);

    const getBobot = async (headerData) => {
        const resp = await _setAxios("penilaian/mapel/bobot/show-bobot", "POST", {
            "tahun_ajaran_id": headerData?.tahun_ajaran_id,
            "semester_id": headerData?.semester_id,
            "rombel_id": headerData?.rombel_id,
            "mapel_id": headerData?.mapel_id
        });
        if (resp.status) {
            const data = resp?.data?.data;
            setBobotData(data || []);
        }
    };
    const getNilaiKKM = async (headerData) => {
        // TODO 
        // const resp = await _setAxios("penilaian/kkm/show-penilaian-mapel", "POST", {
        const resp = await _setAxios("penilaian/kkm/show-penilaian", "POST", {
            tahun_ajaran_id: headerData?.tahun_ajaran_id,
            semester_id: headerData?.semester_id,
            mapel_id: headerData?.mapel_id,
            tingkatan_kelas: 1 // TODO
        });
        if (resp.status) {
            const data = resp?.data?.data;
            setKkmData(data || []);
        }
    };

    useEffect(() => {
        getHeader(match.params.tugas_id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match.params.tugas_id])

    return {
        action,
        headerData,
        bobotData,
        kkmData,
        table,
        filterState,
        fetchTable,
        fetchData,
        setTempTableFilter,
        setTableFilterFromTemp,
    };
};

export default useList;
