import React from 'react';
import { Row, Col, Modal, Button, Divider, Form, Input } from 'antd';
import useForm from '../hooks/useForm';
import { SelectPenghargaanSiswa, SelectRombel, SelectSemester, SelectTahunAjaran } from 'components/select';
import { Date } from 'components/datetime';
import { useParams } from 'react-router-dom';

const { TextArea } = Input;
const ModalForm = ({ data = {}, visible, hideModal, fetchTable }) => {
    const [form] = Form.useForm(); // Initialize form instance
    const { tahun_ajaran_id, semester_id, rombel_id } = useParams();

    const {
        values,
        loading,
        handleChangeSetValue,
        handleSubmit,
    } = useForm(data, fetchTable, hideModal);

    return (
        <Modal
            maskClosable={false}
            keyboard={false}
            title="Form Penghargaan Siswa"
            open={visible}
            onCancel={hideModal}
            closable={false}
            footer={null} // Remove footer from Modal
        >
            <Form
                form={form}
                name="basic"
                autoComplete="off"
                layout="vertical"
                onFinish={handleSubmit}
                loading={loading}
                initialValues={values}
            >
                <Row gutter={[16, 0]}>
                    <Col xs={24}>
                    <Form.Item
                            label="Tahun Ajaran"
                            name="tahun_ajaran_id"
                            rules={[{ required: true, message: 'Tahun Ajaran harus diisi!' }]}>
                            <SelectTahunAjaran
                                allowClear
                                disabled={true}
                                defaultValue={tahun_ajaran_id || data.tahun_ajaran_id || null}
                                onChange={(val) => {
                                    handleChangeSetValue("tahun_ajaran_id", val || null);
                                    handleChangeSetValue("semester_id", null);
                                    handleChangeSetValue("rombel_id", null);
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Semester"
                            name="semester_id"
                            rules={[{ required: true, message: 'Semester harus diisi!' }]}>
                            <SelectSemester
                                allowClear
                                tahunAjaranId={tahun_ajaran_id || data.tahun_ajaran_id || null}
                                disabled={true}
                                defaultValue={semester_id || data.semester_id || null}
                                onChange={(val) => {
                                    handleChangeSetValue("semester_id", val || null);
                                    handleChangeSetValue("rombel_id", null);
                                }}
                            />
                        </Form.Item>
                        <Form.Item label="Rombel" name="rombel_id" rules={[{ required: true, message: 'Rombel harus diisi!' }]}>
                            <SelectRombel
                                allowClear
                                tahunAjaranId={tahun_ajaran_id || data.tahun_ajaran_id || null}
                                semesterId={semester_id || data.semester_id || null}
                                disabled={true}
                                defaultValue={rombel_id || data.rombel_id || null}
                                onChange={(val) => {
                                    handleChangeSetValue("rombel_id", val || null);
                                }}
                            />
                        </Form.Item>
                        <Form.Item label="Tanggal" name="tgl" rules={[{ required: true, message: 'Tanggal harus diisi!' }]}>
                            <Date onChange={(val) => handleChangeSetValue('tgl', val)} />
                        </Form.Item>
                        <Form.Item label="Penghargaan Siswa" name="penghargaan_id" rules={[{ required: true, message: 'Penghargaan harus dipilih!' }]}>
                            <SelectPenghargaanSiswa onChange={(val) => handleChangeSetValue('penghargaan_id', val)} />
                        </Form.Item>
                        <Form.Item
                            label="Keterangan"
                            name="keterangan">
                            <TextArea rows={4} onChange={(e) => handleChangeSetValue('keterangan', e.target.value)} />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Divider />
                        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
                            <Button onClick={hideModal} disabled={loading}>Batal</Button>
                            <Button type="primary" htmlType="submit" disabled={loading} loading={loading}>Simpan</Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default ModalForm;