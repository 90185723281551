// useForm.js
import { useEffect, useState } from 'react';
import { _setAxios, _success } from 'lib/Helper';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useParams } from 'react-router-dom';

dayjs.extend(customParseFormat);

const useForm = (data, fetchTable, hideModal, form) => {
    const [values, setValues] = useState({
        ...data,
    });
    const [loading, setLoading] = useState(false);
    const { id } = useParams();

    const handleChangeSetValue = (name, value) => {
        setValues(prevValues => ({ ...prevValues, [name]: value }));
    };

    const handleSubmit = (val) => {

        const params = {
            kkm_id: id,
            predikat: {
                nilai: val?.nilai,
                list: [
                    {
                        label: "A",
                        start_value: val.A.start_value,
                        end_value: val.A.end_value
                    },
                    {
                        label: "B",
                        start_value: val.B.start_value,
                        end_value: val.B.end_value
                    },
                    {
                        label: "C",
                        start_value: val.C.start_value,
                        end_value: val.C.end_value
                    },
                    {
                        label: "D",
                        start_value: val.D.start_value,
                        end_value: val.D.end_value
                    }
                ]
            },
            list_mata_pelajaran: val.list_mata_pelajaran,
        };

        let endpoint = "penilaian/kkm/detail";
        let method = "POST";

        if (data?.id) {
            endpoint = `${endpoint}/${data?.id}`;
            method = "PUT";
        }

        setLoading(true);
        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status) {
                _success('topRight', 'Success', resp.data.message)
                fetchTable(true);
                hideModal();
            }
            setLoading(false);
        }).catch(err => {
            setLoading(false);
        });
    };

    useEffect(() => {
        if (data?.id) {
            _setAxios(`penilaian/kkm/detail/${data?.id}`).then(resp => {
                if (resp.status) {
                    form.setFieldsValue({
                        nilai: resp.data?.data?.predikat?.nilai,
                        list_mata_pelajaran: resp.data?.data?.list_mata_pelajaran.map(option => parseInt(option.mapel_id)),
                        A: {
                            start_value: resp.data?.data?.predikat?.list[0]?.start_value,
                            end_value: resp.data?.data?.predikat?.list[0]?.end_value
                        },
                        B: {
                            start_value: resp.data?.data?.predikat?.list[1]?.start_value,
                            end_value: resp.data?.data?.predikat?.list[1]?.end_value
                        },
                        C: {
                            start_value: resp.data?.data?.predikat?.list[2]?.start_value,
                            end_value: resp.data?.data?.predikat?.list[2]?.end_value
                        },
                        D: {
                            start_value: resp.data?.data?.predikat?.list[3]?.start_value,
                            end_value: resp.data?.data?.predikat?.list[3]?.end_value
                        },
                    })
                }
            })
        }
    }, [data]);

    return {
        values,
        loading,
        handleChangeSetValue,
        handleSubmit,
    };
};

export default useForm;
