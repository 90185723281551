import { _tableLogActivity } from "lib/Helper";
import Action from "./Action";
import { SettingOutlined } from "@ant-design/icons";

const columns = (action, modalLockUnlock) => [
  {
    title: 'No',
    dataIndex: 'rownum',
    width: '2%',
  }, {
    title: 'Rombel',
    dataIndex: 'rombel_nama',
  }, {
    title: 'Wali Kelas',
    dataIndex: 'wali_nama',
  }, {
    title: 'Nilai Terkirim (Semua Mapel)',
    dataIndex: 'nilai_status_complete',
  }, {
    title: 'Status',
    dataIndex: 'nilai_status_edit',
  }, {
    title: <SettingOutlined />,
    align: "center",
    key: 'operation',
    width: '2%',
    render: (a, row) => <Action row={row} action={action} modalLockUnlock={modalLockUnlock}/>,
  },
];

export default columns