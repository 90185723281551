import { Input } from "antd";

const columns = (action, handleInputChange) => [
  {
    title: 'No',
    dataIndex: 'rownum',
    width: '2%',
  }, {
    title: 'Nisn',
    dataIndex: 'nisn',
  }, {
    title: 'Nis',
    dataIndex: 'nis',
    render: (a, row) => <>{row.nis ? row.nis : "-"}</>,
  }, {
    title: 'Nama Peserta Didik',
    dataIndex: 'nama',
  }, {
    title: 'Jenis Kelamin',
    dataIndex: 'jenis_kelamin',
  }, {
    titleMobile: <span style={{lineHeight: "40px"}}>Catatan</span>,
    title: action?.catatan_wali_kelas_update ? <>
      Catatan<br />
      <Input
        placeholder="catatan"
        onChange={(e) => handleInputChange(0, 'catatan', e.target.value)}
      />
    </> : "Catatan",
    dataIndex: 'catatan',
    key: 'catatan',
    render: (_, row) => (
      action?.catatan_wali_kelas_update ? <Input
        placeholder="catatan"
        defaultValue={row.catatan || ""}
        value={row.catatan || ""}
        onChange={(e) => handleInputChange(row.id, 'catatan', e.target.value)}
      /> : (row.catatan || "-")
    ),
  }
];

export default columns