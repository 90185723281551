import React from 'react';
import { Button, Dropdown } from 'antd';
import { DownCircleOutlined, InfoCircleOutlined, ReloadOutlined, SendOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom/cjs/react-router-dom';

class Action extends React.Component {
    render() {
        const { row, action, modalSend } = this.props;
        const items = [];

        if (!action.send_value && !action.cancel_value && !action.detail) {
            return "-"
        }

        if (action.detail) {
            items.push({
                key: '0',
                label: "Detail",
                icon: <InfoCircleOutlined />,
                onClick: () => {
                    this.props.history.push("/penilaian/nilai-mapel/detail/" + row.tugas_id)
                },
            })
        }

        if (action.send_value || action.cancel_value) {
            if(action.send_value && row.status?.toLowerCase() !== "nilai terkirim"){
                // Action mengirim
                items.push({
                    key: '1',
                    label: "Kirim Penilaian",
                    icon: <SendOutlined rotate={-45} />,
                    onClick: () => {
                        modalSend(true, row)
                    },
                })
            }
            if(action.cancel_value && row.status?.toLowerCase() === "nilai terkirim"){
                // Action batal
                items.push({
                    key: '1',
                    label: "Batalkan Penilaian",
                    icon: <ReloadOutlined style={{transform: "scaleX(-1)"}} />,
                    onClick: () => {
                        modalSend(true, row)
                    },
                })
            }
        }

        return (
            <Dropdown
                trigger={['click']}
                menu={{ items }}
            >
                <Button type="primary">
                    Opsi <DownCircleOutlined style={{ fontSize: 11 }} />
                </Button>
            </Dropdown >
        );
    }
}


export default React.memo(withRouter(Action));
