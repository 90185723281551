import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Alert, Typography, message, Space } from 'antd';
import { CameraOutlined, FileDoneOutlined } from '@ant-design/icons';
import { _getBase64Upload, _setAxios, _success, _getDistance, _error } from '../../lib/Helper';
import moment from 'moment';
import { Camera, FACING_MODES } from "react-html5-camera-photo";
import 'react-html5-camera-photo/build/css/index.css';
import "./Absensi.css";
import { isMobile } from 'react-device-detect';
import { MapComponentBasic } from 'components/maps';

const { Text } = Typography;
const dateNow = moment(new Date()).format("DD MMM YYYY");

const AbsensiCheckLog = ({ visible, hideModal, privilege }) => {
  const [loading, setLoading] = useState(false);
  const [typeAbsensi, setTypeAbsensi] = useState(null);
  const [coordinate, setCoordinate] = useState({ latitude: "", longitude: "" });
  const [capturImage, setCapturImage] = useState(null);
  const [currentTime, setCurrentTime] = useState(new Date());

  const { presensi, profile, tahun_ajaran, semester } = privilege;
  const presensiSetting = presensi?.setting;


  const getMyLocation = () => {
    const location = navigator.geolocation;
    if (location) {
      location.getCurrentPosition(
        (position) => {
          setCoordinate({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          setCoordinate({ latitude: 'err-latitude', longitude: 'err-longitude' });
        }
      );
    }
  };

  const handleSubmit = async () => {
    if (!capturImage) {
      return;
    }

    let file = await _getBase64Upload(capturImage);
    if (!file) {
      setLoading(false);
      return message.warning("Foto selfi wajib ada.");
    }

    let lat = coordinate.latitude;
    let long = coordinate.longitude;
    const distance = _getDistance([presensiSetting.lat, presensiSetting.long], [lat, long]);

    if (!lat || !long) {
      return message.warning("Mohon maaf lokasi Anda tidak terdeteksi, silahkan aktifkan GPS pada browser yang Anda gunakan.");
    }

    setLoading(true);
    const params = {
      tahun_ajaran_id: tahun_ajaran?.id,
      semester_id: semester?.id,
      gtk_id: profile?.tk_id,
      tgl: dateNow,
      waktu: currentTime.toLocaleTimeString(),
      lat: lat,
      long: long,
      radius: distance,
      foto: file,
    };

    if (presensiSetting.radius_active === "1" && distance > parseFloat(presensiSetting.radius)) {
      setLoading(false);
      return message.warning("Mohon maaf jarak kamu tidak terjangkau, pastikan lokasi kamu sudah sesuai.");
    }

    let endpoint = "presensi/gtk/temporary";
    let method = "POST";
    _setAxios(endpoint, method, params).then((resp) => {
      if (resp.status === true && resp.data?.status === true) {
        _success('topRight', 'Success', resp.data.message);
        setTimeout(() => {
          window.location.reload(false);
        }, 1000);
      } else {
        _error('topRight', 'Failed', resp.message);
        setLoading(false);
      }
    });
  };

  const handleType = (typeAbsensi) => {
    setTypeAbsensi(typeAbsensi);

    if (typeAbsensi) {
      setTimeout(() => {
        setLoading(false);
        setCapturImage(null);
      }, 3000);
      handleSubmit();
    } else {
      setCapturImage(null)
    }
  };

  const capture = (imgSrc) => {
    setCapturImage(imgSrc);
  };

  useEffect(() => {
    getMyLocation();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId); // Membersihkan interval saat komponen dilepas
  }, []);


  const btnBack = (
    <Button onClick={() => handleType(null)}>Batal</Button>
  );

  const btnClock = (
    <Button type="primary" htmlType="submit" onClick={() => handleType("clock")} loading={loading}>
      Clock
    </Button>
  );

  const style = {
    textAlign: "center",
    ...(isMobile && { top: 0 }),
  };

  return (
    <Modal
      className='absensi-modal'
      style={style}
      maskClosable={false}
      title={<><FileDoneOutlined /> Rekam Kehadiran</>}
      open={visible}
      onCancel={hideModal}
      footer={
        <div style={{ textAlign: "center" }}>
          {presensi?.is_allow ?
            (typeAbsensi === "clock" ? <Space align="center">{btnBack}{(capturImage ? btnClock : null)}</Space>
              : <Space align="center">{btnClock}</Space>) : null}
        </div>
      }
    >
      {!typeAbsensi ? (
        <div style={{ textAlign: "center", padding: "0 24px" }}>
          <Text type="secondary">Jadwal: {dateNow + " " + currentTime.toLocaleTimeString()}</Text>
          <br />
          <Text strong>{presensiSetting.jam_masuk} - {presensiSetting.jam_pulang}</Text>
          <Alert style={{ fontSize: "70%" }} message="Foto selfi di perlukan untuk Clock In/Out" type="info" showIcon />
        </div>
      ) : (
        <>
          <MapComponentBasic
            marker={{ lat: coordinate.latitude, lng: coordinate.longitude }}
            setMarker={(val) => {
              if (val.length > 1) {
                setCoordinate({
                  latitude: val[0],
                  longitude: val[1]
                });
              }
            }}
            setAddress={(val) => {
              console.log("Address selected: ", val);
            }}
          />

          <br />
          {!capturImage ? (
            <div className="content-camera-absensi">
              <Camera
                style={{ display: loading ? "block" : "none" }}
                idealFacingMode={FACING_MODES.USER}
                isImageMirror={true}
                isFullScreen={true}
                isMaxResolution={false}
                sizeFactor={0.5}
                isSilentMode={true}
                onTakePhoto={capture}
                imageType="jpg"
                imageCompression={0}
              />
            </div>
          ) : (
            <div style={{ textAlign: "center", padding: "0 24px" }}>
              <img src={capturImage} alt="" style={{ width: '100%' }} />
              <b>Waktu Rekam Kehadiran</b>: {currentTime.toLocaleTimeString()}
              <br />
              <Button type="dashed" icon={<CameraOutlined />} onClick={() => setCapturImage(null)}>
                Ganti Foto
              </Button>
            </div>
          )}
        </>
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  privilege: state.privilege,
});

export default connect(mapStateToProps)(AbsensiCheckLog);
