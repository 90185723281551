import React from 'react';
import { Modal, Button, Form } from 'antd';
import { SelectSemester, SelectTahunAjaran } from 'components/select';

const ModalFilter = ({ values, visible, hideModal, setTempTableFilter, setTableFilterFromTemp }) => {

    return (
        <Modal
            title="Filter"
            width={300}
            open={visible}
            onCancel={hideModal}
            footer={<>
                <Button onClick={hideModal}>Tutup</Button>
                <Button type='primary' onClick={setTableFilterFromTemp}>Terapkan</Button>
            </>}
        >
            <Form
                name="basic"
                autoComplete="off"
                layout="vertical"
            >
                <Form.Item label="Tahun Ajaran">
                    <SelectTahunAjaran
                        allowClear
                        value={values.tahun_ajaran_id}
                        onChange={(val) => {
                            setTempTableFilter("tahun_ajaran_id", val || null);
                            setTempTableFilter("semester_id", null);
                            setTempTableFilter("rombel_id", null);
                        }}
                    />
                </Form.Item>
                <Form.Item label="Semester">
                    <SelectSemester
                        allowClear
                        value={values.semester_id}
                        tahunAjaranId={values.tahun_ajaran_id}
                        disabled={!values.tahun_ajaran_id}
                        onChange={(val) => {
                            setTempTableFilter("semester_id", val || null);
                            setTempTableFilter("rombel_id", null);
                        }}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalFilter;
