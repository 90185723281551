import { _tableLogActivity } from "lib/Helper";
import Action from "./Action";
import { DownloadOutlined, SettingOutlined } from "@ant-design/icons";

const columns = (action, modalForm, modalDelete) => [
  {
    title: 'No',
    dataIndex: 'rownum',
    width: '3%',
  },
  {
    title: 'Tanggal',
    dataIndex: 'tgl',
  },
  {
    title: 'Kode',
    dataIndex: 'kode',
  },
  {
    title: 'Pelanggaran',
    dataIndex: 'pelanggaran',
  },
  {
    title: 'Poin',
    dataIndex: 'poin',
  },
  {
    title: 'Tahun Ajaran',
    render: (a, row) => <>{row.tahun_ajaran ? row.tahun_ajaran : "-"} | {row.tipe_semester ? row.tipe_semester : "-"}</>
  },
  {
    title: 'Rombel',
    dataIndex: 'rombel_nama',
  },
  {
    title: 'Keterangan',
    dataIndex: 'keterangan',
  },
  {
    title: 'Dokumentasi',
    dataIndex: 'file',
    render: (a, row) => row.file ? <a target="_blank" rel="noopener noreferrer" href={row.main_path + row.file} style={{ fontSize: 11 }}>Download <DownloadOutlined /></a> : "-",
  },
  {
    title: <SettingOutlined />,
    align: "center",
    key: 'operation',
    width: '2%',
    render: (a, row) => <Action row={row} action={action} modalForm={modalForm} modalDelete={modalDelete} />,
  },
  {
    title: 'LogActivity',
    key: 'operation',
    width: '10%',
    render: (a, row) => _tableLogActivity(row),
  },
];

export default columns