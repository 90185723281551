import React from 'react';
import { connect } from 'react-redux'

import { validate } from 'validate.js';
import { Row, Col, Modal, Button, Form, Divider, Select } from 'antd';
import { _success, _setAxios, _validationFormMsg } from '../../../../lib/Helper';
import { tingkatanKelasOptions } from '../../../../data/options';
import "./style.css"
import SelectTahunAjaran from '../../../../components/select/SelectTahunAjaran';

const { Option } = Select;

const schema = {
    tahun_ajaran_id: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    tingkatan_kelas: {
        presence: { allowEmpty: false, message: 'wajib dipilih' },
    },
};

class FormContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,
            visible: false,
        };
    }

    componentDidMount() {
        this.setState({
            values: {
                ...this.props.data,
            },
        })
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        const errors = validate(values, schema);

        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    handleSubmit = async () => {
        const { values } = this.state
        const errors = validate(values, schema);
        if (errors) {
            _validationFormMsg(errors)
            return
        }

        this.setState({
            loading: true
        });
        let jadwals = []
        if (Array.isArray(values.jadwal) && values.jadwal.length > 0) {
            values.jadwal.forEach(el => {
                if (el.value) {
                    jadwals.push(el.value)
                } else {
                    jadwals.push(el)
                }
            });
        }
        const params = {
            tahun_ajaran_id: values.tahun_ajaran_id ? values.tahun_ajaran_id : null,
            tingkatan_kelas: values.tingkatan_kelas ? values.tingkatan_kelas : null,
        }

        let endpoint = "keuangan/pemasukan/master"
        let method = "POST"
        if (values.id) {
            endpoint = endpoint + "/" + values.id
            method = "PUT"
        }

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };


    render() {
        return (
            <Modal
                maskClosable={false}
                title={"Form Keuangan Pemasukan"}
                open={this.props.visible}
                onCancel={this.props.hideModal}
                closable={false}
                footer={null}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                    onFinish={this.handleSubmit}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24}>
                            <Form.Item label="Tahun Ajaran *">
                                <SelectTahunAjaran
                                    defaultValue={this.props.data.tahun_ajaran_id}
                                    onChange={(val) => {
                                        this.handleChangeSetValue("tahun_ajaran_id", val ? val : null)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item label="Tingkatan Kelas">
                                <Select
                                    allowClear
                                    name="tingkatan_kelas"
                                    placeholder="Tingkatan kelas"
                                    defaultValue={this.props.data.tingkatan_kelas}
                                    onChange={(e, newValue) => {
                                        this.handleChangeSetValue("tingkatan_kelas", newValue ? newValue.value : null)
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {tingkatanKelasOptions.map((row, i) => <Option key={i} value={row.value}>{row.label}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} style={{ textAlign: "right" }}>
                            <Divider />
                            <Button onClick={this.props.hideModal}>Batal</Button> &nbsp;
                            <Button type="primary" htmlType="submit" loading={this.state.loading}>Simpan</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(FormContent);
