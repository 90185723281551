import React from 'react';
import { Modal, Button, Form, Divider } from 'antd';
import { _success, _setAxios } from '../../../../lib/Helper';
import SelectTahunAjaran from '../../../../components/select/SelectTahunAjaran';
import { SelectEkstrakurikuler } from 'components/select';


class Export extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            values: {},
        };
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    handleSubmit = () => {
        this.setState({
            loading: true
        });
        _setAxios("kaldik-kegiatan/ekstrakurikuler/master/ekspor", "POST", {
            tahun_ajaran_id: this.state.values.tahun_ajaran_id ? this.state.values.tahun_ajaran_id : null,
            ekstrakurikuler_id: this.state.values.ekstrakurikuler ? this.state.values.ekstrakurikuler : null
        }).then(resp => {
            if (resp.status === true) {
                this.props.hideModal()
                _success('topRight', 'Success', resp.data.message)
                setTimeout(function () {
                    window.location.href = resp.data.data.link;
                }, 1000);
            }
            this.setState({
                loading: false
            });
        })
    };

    render() {
        return (
            <Modal
                title="Export data ekstrakurikuler"
                open={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading}>Export</Button>
                </>}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                >
                    <Form.Item label="Tahun Ajaran">
                        <SelectTahunAjaran
                            onChange={(val) => {
                                this.handleChangeSetValue("tahun_ajaran_id", val ? val : null)
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="Ekstrakurikuler">
                        <SelectEkstrakurikuler
                            tahunAjaranId={this.state.values.tahun_ajaran_id}
                            onChange={(val) => {
                                this.handleChangeSetValue("ekstrakurikuler", val ? val : null)
                            }}
                        />
                    </Form.Item>
                    <Divider />
                    <div style={{ textAlign: "left" }}>
                        Apakah Anda yakin? data ekstrakurikuler akan di export <br />
                        Data yang di export akan langsung ke download, tanpa melalui log export. </div>
                </Form>
            </Modal>
        )
    }
}

export default Export;
