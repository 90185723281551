import React from 'react';
import { Row, Col, Modal, Button, Divider, Form, Input } from 'antd';
import useForm from '../hooks/useForm';
import SumatifFormList from '../form-list/list/index';
const { TextArea } = Input;
const ModalForm = (props) => {
    const [form] = Form.useForm(); // Initialize form instance

    const {
        valuesList,
        loading,
        setValuesList,
        handleSubmit,
    } = useForm(form, props?.headerData, props?.data, props?.fetchTable, props?.hideModal);

    return (
        <Modal
            maskClosable={false}
            keyboard={false}
            title="Form Nilai Sumatif"
            open={props?.visible}
            onCancel={props?.hideModal}
            closable={false}
            footer={null}
            width={800}
        >
            <Form
                form={form}
                name="basic"
                autoComplete="off"
                layout="vertical"
                onFinish={handleSubmit}
                loading={loading}
            >
                <Row gutter={[16, 0]}>
                    <Col xs={24}>
                        <Form.Item
                            label="Nama Nilai"
                            name="nama"
                            rules={[{ required: true, message: 'Nama Nilai harus diisi!' }]}>
                            <Input
                                placeholder='Nama Nilai'
                            />
                        </Form.Item>
                        <Form.Item
                            label="Materi"
                            name="materi"
                            rules={[{ required: true, message: 'Materi harus diisi!' }]}>
                            <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
                        </Form.Item>
                    </Col>

                    <Col xs={24}>
                        <SumatifFormList {...props} setValuesList={setValuesList} valuesList={valuesList} sumatifId={props?.data?.id}/>
                    </Col>

                    <Col xs={24}>
                        <Divider />
                        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
                            <Button onClick={props?.hideModal} disabled={loading}>Batal</Button>
                            <Button type="primary" htmlType="submit" disabled={loading} loading={loading}>Simpan</Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default ModalForm;