import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Form, DatePicker, Input, Divider } from 'antd';
import { _success, _setAxios } from '../../../../../lib/Helper';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { SelectKecakapan, SelectRombel, SelectSemester, SelectTahunAjaran } from 'components/select';
dayjs.extend(customParseFormat);

const dateFormat = 'DD-MM-YYYY';

const ModalContent = ({ visible, hideModal, fetchTable, getSiswaPramukaByID, data, dataSiswa, privilege }) => {
    const { tahun_ajaran, semester } = privilege;
    const [values, setValues] = useState({
        ...data,
        kecakapan: data.sku_id,
        tanggal_lulus: data.tgl_lulus ? dayjs(data.tgl_lulus) : null,
        tahun_ajaran_id: tahun_ajaran?.id,
        semester_id: semester?.id,
    });
    const [form] = Form.useForm(); // Initialize form instance
    const [loading, setLoading] = useState(false);

    const handleChangeSetValue = (name, value) => {
        setValues(prevValues => ({ ...prevValues, [name]: value }));
    };

    const handleSubmit = async () => {
        setLoading(true);
        const params = {
            siswa_id: values.siswa_id || null,
            pramuka_master_sku_id: values.kecakapan || null,
            tgl_lulus: values.tanggal_lulus || null,
            pembina: form.getFieldValue("pembina") || null,
            tahun_ajaran_id: form.getFieldValue("tahun_ajaran_id") || null,
            semester_id: form.getFieldValue("semester_id") || null,
            rombel_id: form.getFieldValue("rombel_id") || null
        };

        let endpoint = "kaldik-kegiatan/pramuka/penilaian";
        let method = "POST";
        if (data.id) {
            endpoint = `kaldik-kegiatan/pramuka/penilaian/${data.id}`;
            method = "PUT";
        }

        try {
            const resp = await _setAxios(endpoint, method, params);
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message);
                fetchTable(true);
                getSiswaPramukaByID();
                hideModal();
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setValues({
            ...data,
            kecakapan: data.sku_id,
            tanggal_lulus: data.tgl_lulus
        });
    }, [data, dataSiswa]);

    console.log("values", values);
    return (
        <Modal
            maskClosable={false}
            title="Form Syarat Kecakapan Umum Siswa"
            open={visible}
            onCancel={hideModal}
            closable={false}
            footer={null}
        >
            <Form
                form={form}
                name="basic"
                autoComplete="off"
                onFinish={handleSubmit}
                initialValues={values}
                layout="vertical">
                <Form.Item
                    label="Tahun Ajaran"
                    name="tahun_ajaran_id"
                    rules={[{ required: true, message: 'Tahun Ajaran harus diisi!' }]}>
                    <SelectTahunAjaran
                        allowClear
                        value={tahun_ajaran?.id || null}
                        onChange={(val) => {
                            form.setFieldValue("tahun_ajaran_id", val || null);
                            form.setFieldValue("semester_id", null);
                            form.setFieldValue("rombel_id", null);
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label="Semester"
                    name="semester_id"
                    rules={[{ required: true, message: 'Semester harus diisi!' }]}>
                    <SelectSemester
                        allowClear
                        tahunAjaranId={values.tahun_ajaran_id || null}
                        value={values.semester_id || null}
                        onChange={(val) => {
                            form.setFieldValue("semester_id", val || null);
                            form.setFieldValue("rombel_id", null);
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label="Rombel"
                    name="rombel_id"
                    rules={[{ required: true, message: 'Rombel harus diisi!' }]}>
                    <SelectRombel
                        allowClear
                        tahunAjaranId={values.tahun_ajaran_id || null}
                        semesterId={values.semester_id || null}
                        value={values.rombel_id || null}
                        onChange={(val) => {
                            form.setFieldValue("rombel_id", val || null);
                        }}
                    />
                </Form.Item>
                <Form.Item label="Kecakapan" name="kecakapan" rules={[{ required: true, message: 'Kecakapan wajib diisi' }]}>
                    <SelectKecakapan
                        total_bantara={dataSiswa && dataSiswa.total_bantara}
                        onChange={(value, option) => handleChangeSetValue("kecakapan", option ? option.value : null)}
                        defaultValue={data.sku_id}
                    />
                </Form.Item>
                <Form.Item label="Tanggal Lulus" name="tanggal_lulus" rules={[{ required: true, message: 'Tanggal Lulus wajib diisi' }]}>
                    <DatePicker
                        defaultValue={data.tgl_lulus || null}
                        format={dateFormat}
                        style={{ width: "100%" }}
                        onChange={(date, dateString) => handleChangeSetValue("tanggal_lulus", dateString)}
                    />
                </Form.Item>
                <Form.Item label="Pembina" name="pembina" rules={[{ required: true, message: 'Pembina wajib diisi' }]}>
                    <Input
                        placeholder="Pembina"
                        defaultValue={data.pembina}
                        onChange={(e) => handleChangeSetValue("pembina", e.target.value)}
                    />
                </Form.Item>
                <Divider />
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
                    <Button onClick={hideModal} disabled={loading}>Batal</Button>
                    <Button type="primary" htmlType="submit" disabled={loading} loading={loading}>Simpan</Button>
                </div>
            </Form>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    privilege: state.privilege,
});

export default connect(mapStateToProps)(ModalContent);