import React, { useEffect, useState } from 'react';
import { List, Skeleton, Divider } from 'antd';
import { _setAxios } from '../../../../lib/Helper';
import { useParams } from 'react-router-dom';

async function getKKMDetail(kkmId) {
    if (!kkmId) {
        return []
    }
    return await _setAxios("penilaian/kkm/master/" + kkmId, "GET", null).then(resp => {
        return resp
    })
}

const HeaderKKM = () => {

    const [loading, setLoading] = useState(true);
    const [header, setHeader] = useState();
    const { id } = useParams();

    useEffect(() => {
        getKKMDetail(id).then(resp => {
            let data = resp.data.data
            setLoading(false)
            setHeader([{
                title: 'Tahun Ajaran',
                description: data?.tahun_ajaran || "-",
            },
            {
                title: 'Semester',
                description: data?.tipe_semester || "-",
            },
            {
                title: "Tingkatan Kelas",
                description: data?.tingkatan_kelas || "-",
            },
            ])
        })
    }, [id]);

    return (
        <>
            <div className="site-layout-background">
                {
                    !loading ?
                        <List
                            itemLayout="horizontal"
                            dataSource={header}
                            renderItem={item => (
                                <List.Item>
                                    <List.Item.Meta
                                        title={<><div style={{ float: "left", minWidth: 50 }}>{item.title}</div> : <small style={{ color: "rgb(119 105 105)" }}>{item.description}</small></>}
                                    />
                                </List.Item>
                            )}
                        />
                        :
                        <Skeleton />
                }
            </div>

            <Divider />
        </>
    );
};
export default HeaderKKM;
