import React, { useEffect, useState } from 'react';
import { Form, Input, InputNumber, Button, Typography } from 'antd';
import { _setAxios, _success } from 'lib/Helper';

const { Title } = Typography;

const BobotForm = ({ bobotData }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {

    const params = {
      tahun_ajaran_id: bobotData?.tahun_ajaran_id,
      semester_id: bobotData?.semester_id,
      rombel_id: bobotData?.rombel_id,
      mapel_id: bobotData?.mapel_id,
      nilai_sumatif: values?.sumatif,
      nilai_pas: values?.paspat,
    };

    let endpoint = "penilaian/mapel/bobot";
    let method = "POST";

    setLoading(true);
    _setAxios(endpoint, method, params).then(resp => {
      if (resp.status) {
        _success('topRight', 'Success', resp.data.message)
      }
      setLoading(false);
    }).catch(err => {
      setLoading(false);
    });
  };

  useEffect(() => {
    // Pastikan data ada sebelum memperbarui form
    if (bobotData) {
      form.setFieldsValue({
        jumlahJamPerMinggu: bobotData?.total_jtm, 
        sumatif: bobotData?.nilai_sumatif || 0,
        paspat: bobotData?.nilai_pas || 0,
      });
    }
  }, [bobotData, form]); // Dependensi adalah `data` dan `form`


  return (
    <div style={{ maxWidth: 400, margin: '0 auto' }}>
      <Form form={form} onFinish={onFinish} layout="vertical">
        {/* Input Text Disabled */}
        <Form.Item
          label="Jumlah jam per minggu"
          name="jumlahJamPerMinggu"
        >
          <Input disabled />
        </Form.Item>

        {/* Title for Bobot Nilai Pengetahuan */}
        <Title level={5}>Bobot Nilai Pengetahuan</Title>

        {/* Input for Sumatif % */}
        <Form.Item
          label="Sumatif (%)"
          name="sumatif"
          rules={[
            { required: true, message: 'Mohon inputkan nilai sumatif!' },
          ]}        >
          <InputNumber min={0} max={100} style={{ width: '100%' }}/>
        </Form.Item>

        {/* Input for PAS/PAT % */}
        <Form.Item
          label="PAS/PAT (%)"
          name="paspat"
          rules={[
            { required: true, message: 'Mohon inputkan nilai PAS/PAT!' },
          ]}
        >
          <InputNumber min={0} max={100} style={{ width: '100%' }} />
        </Form.Item>

        {/* Submit Button */}
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={loading} disabled={loading}>
            Simpan
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default BobotForm;
