import React, { useEffect, useState } from 'react';
import { List, Skeleton, Divider } from 'antd';

const Header = ({ data }) => {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (data) {
            setLoading(false);
        }
    }, [data]);

    return (
        <>
            <div className="site-layout-background">
                {
                    !loading ?
                        <List
                            itemLayout="horizontal"
                            dataSource={[
                                {
                                    title: 'Tahun Ajaran',
                                    description: (data?.tahun_ajaran || "-") + " " + (data?.tipe_semester || "-"),
                                },
                                {
                                    title: 'Mata Pelajaran',
                                    description: data?.mapel_nama || "-",
                                },
                                {
                                    title: 'Rombel',
                                    description: data?.rombel_nama || "-",
                                },
                                // {
                                //     title: 'Kurikulum',
                                //     description: data?.kurikulum_nama || "-",
                                // },
                                // {
                                //     title: "Jumlah Siswa",
                                //     description: data?.total_siswa || "0",
                                // },
                                // {
                                //     title: "Wali Kelas",
                                //     description: data?.wali_rombel || "-",
                                // },
                            ]}
                            renderItem={item => (
                                <List.Item>
                                    <List.Item.Meta
                                        title={<><div style={{ float: "left", minWidth: 50 }}>{item.title}</div> : <small style={{ color: "rgb(119 105 105)" }}>{item.description}</small></>}
                                    />
                                </List.Item>
                            )}
                        />
                        :
                        <Skeleton />
                }
            </div>

            <Divider />
        </>
    );
};
export default Header;
