import React from 'react';
import { connect } from 'react-redux'
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { Row, Col, BackTop, Breadcrumb, Flex, Button } from 'antd';
import { PageHeader } from 'components/header';

import { isAuth, _setAxios, getParamTable, getParamTempTableFiltering } from '../../../../lib/Helper';

import AuthRedirect from '../../../../components/AuthRedirect'
import Filter from '../modal/Filter';
import Desktop from './Desktop';
import Mobile from './Mobile';
import SearchTable from '../../../../components/search-table/SearchTable';
import { DoubleRightOutlined } from '@ant-design/icons';
import SortingTableMobileWithFilter from '../../../../components/sorting-table/SortingTableMobileWithFilter';
import SortingTableDesktopWithFilter from '../../../../components/sorting-table/SortingTableDesktopWithFilter';
import DeleteMulti from '../modal/DeleteMulti';
import Reset from '../modal/Reset';

const sortFields = [{
  value: "id",
  label: "Tanggal Pembuatan",
}, {
  value: "nama",
  label: "Nama",
}, {
  value: "jenis",
  label: "Jenis",
}, {
  value: "status",
  label: "Status",
}]

class ConfWhatsapp extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      values: {},
      table: {
        loading_first: false,
        loading: false,
        data: [],
        search: "",
        pagination: {
          current: 1,
          pageSize: 10,
          total: null,
        },
        sorting: [],
        filtering: {
        },
      },
      filter: {
        values: {},
        visible: false,
      },
      selectedRowKeys: [],
      deleteMulti: {
        ids: [],
        visible: false
      },
      reset: {
        visible: false
      },
    };
  }

  fetchTable = (isNew = false) => {
    const { table } = this.state
    const params = getParamTable("default", table)
    this.fetch(params, isNew);
  }

  fetch = async (params = {}, isNew = false) => {
    this.setState({
      table: {
        ...params,
        loading_first: isMobile ? (isNew ? true : false) : false,
        loading: true
      }
    });

    params = {
      ...params,
      pagination: {
        ...params.pagination,
        current: isMobile ? (isNew ? 1 : params.pagination.current) : params.pagination.current
      }
    }
    _setAxios("notifikasi-wa/ppdb/table", "POST", params).then(resp => {

      if (resp.status === true) {
        let data = isNew ? [] : this.state.table.data

        let current = params.pagination.current
        this.setState({
          table: {
            ...params,
            loading_first: false,
            loading: false,
            data: isMobile ? [...data, ...resp.data.list] : resp.data.list,
            pagination: {
              ...params.pagination,
              current: isMobile ? current + 1 : current,
              total: resp.data.info.total
            }
          }
        });
      } else {
        this.setState({
          table: {
            ...params,
            loading_first: false,
            loading: false,
          }
        })
      }
    })
  };

  handleChangeSetValue = (name, value) => {
    const { values } = this.state;
    this.setForm(values, name, value)
  };

  setForm = (values, name, value) => {
    values[name] = value;

    this.setState({
      values: values,
    });
  }


  // FILTER START ------------------------------------------
  setTempTableFilter = (name, value) => {

    let values = this.state.filter.values
    values[name] = value

    this.setState({
      filter: {
        ...this.state.filter,
        values: values
      }
    })

  };

  setTableFilterFromTemp = () => {
    const params = getParamTempTableFiltering(this.state.table, this.state.filter.values)
    this.fetch(params, isMobile ? true : false)
    this.modalFilter(false)
  };

  modalFilter = (visible = false) => {
    this.setState({
      filter: {
        ...this.state.filter,
        visible: visible,
        values: {
          status: null,
        }
      },
    })
  }
  // FILTER END ------------------------------------------

  modalDeleteMulti = (visible = false) => {
    this.setState({
      deleteMulti: {
        ids: this.state.selectedRowKeys,
        visible: visible
      },
    })
  }
  modalReset = (visible = false) => {
    this.setState({
      reset: {
        visible: visible
      },
    })
  }

  onSelectChange = (newSelectedRowKeys) => {
    this.setState({
      selectedRowKeys: newSelectedRowKeys
    })
  }

  render() {

    const access = this.props.privilege.access["/notification/ppdb"]
    if (!isAuth(this.props.privilege) || access === undefined) {
      return <AuthRedirect />
    }
    const { table } = this.state;
    const { selectedRowKeys } = this.state
    const hasSelected = selectedRowKeys.length > 0;

    return (
      <>
        <PageHeader
          style={{
            padding: 0
          }}
          onBack={() => window.history.back()}
          subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
            <Breadcrumb.Item>Notifikasi</Breadcrumb.Item>
            <Breadcrumb.Item>PPDB </Breadcrumb.Item>
          </Breadcrumb>}
        />
        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>

          <PageHeader
            className="site-page-header"
            title="Notifikasi PPDB"
          />
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={18} md={19} >
              <SearchTable table={table} fetch={this.fetch} />
            </Col>

            {/* SORTING LIST/TABLE */}
            <Col xs={24} sm={6} md={5}>
              <SortingTableMobileWithFilter isFilter={true} table={table} fetch={this.fetch} modalFilter={this.modalFilter} sortFields={sortFields} />
              <SortingTableDesktopWithFilter table={table} fetch={this.fetch} modalFilter={this.modalFilter} sortFields={sortFields} />
            </Col>

            {/* LIST/TABLE DATA */}
            <Col xs={24}>
              <BrowserView>
                <Flex gap="middle" vertical>
                  <Flex align="center" gap="middle">
                    <Button type="primary" onClick={() => this.modalReset(true)}>
                      Reset Data
                    </Button>
                    <Button type="primary" disabled={!hasSelected} onClick={() => this.modalDeleteMulti(true)}>
                      Delete Multiple
                    </Button>
                    {hasSelected ? `Selected ${selectedRowKeys.length} items` : null}
                  </Flex>
                </Flex>
                <Desktop
                  access={access}
                  state={this.state}
                  fetch={this.fetch}
                  fetchTable={this.fetchTable}
                  onSelectChange={this.onSelectChange}
                />
              </BrowserView>
              <MobileView>
                <Mobile
                  access={access}
                  state={this.state}
                  fetch={this.fetch}
                  fetchTable={this.fetchTable}
                />
              </MobileView>
            </Col>
          </Row>
        </div>
        {
          this.state.filter.visible ?
            <Filter
              visible={this.state.filter.visible}
              hideModal={this.modalFilter.bind(this, false)}
              setTempTableFilter={this.setTempTableFilter}
              setTableFilterFromTemp={this.setTableFilterFromTemp}
            /> : ""
        }
        {
          this.state.deleteMulti.visible ?
            <DeleteMulti
              ids={this.state.deleteMulti.ids}
              visible={this.state.deleteMulti.visible}
              hideModal={this.modalDeleteMulti.bind(this, false)}
              fetchTable={this.fetchTable}
            /> : ""
        }
        {
          this.state.reset.visible ?
            <Reset
              visible={this.state.reset.visible}
              hideModal={this.modalReset.bind(this, false)}
              fetchTable={this.fetchTable}
            /> : ""
        }
        <BackTop />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(ConfWhatsapp);
