import React from 'react';
import { connect } from 'react-redux';
import { isAuth } from 'lib/Helper';
import { Row, Col, BackTop, Breadcrumb, Button } from 'antd';
import { DoubleRightOutlined, DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import { DesktopOnly } from 'components/list';
import { PageHeader } from 'components/header';
import SearchTable from 'components/search-table/SearchTable';
import columns from './Columns';
import useList from '../hooks/useList';
import AuthRedirect from 'components/AuthRedirect';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import Header from './Header';
import ModalForm from '../modal/Form';
import ModalDelete from '../modal/Delete';
import ModalExport from '../modal/Export';

const PenilaianNilaiMapelDetailSumatif = ({ privilege, match }) => {
  const {
    action,
    headerData,
    table,
    formState,
    deleteState,
    exportState,
    fetchTable,
    fetchData,
    modalForm,
    modalDelete,
    modalExport,
  } = useList(privilege, match);

  if (!isAuth(privilege) || (action === undefined || action?.detail === undefined)) {
    return <AuthRedirect />
  }

  return (
    <>
      <PageHeader
        style={{
          padding: 0
        }}
        onBack={() => window.history.back()}
        title={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
          <Breadcrumb.Item>Penilaian</Breadcrumb.Item>
          <Breadcrumb.Item><Link to="/penilaian/nilai-mapel">Nilai Mata Pelajaran</Link> </Breadcrumb.Item>
          <Breadcrumb.Item><Link to={`/penilaian/nilai-mapel/detail/${match.params.tugas_id}`}>Detail</Link> </Breadcrumb.Item>
          <Breadcrumb.Item>Sumatif </Breadcrumb.Item>
        </Breadcrumb>}
      />

      <Header data={headerData} />
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <PageHeader
          className="site-page-header"
          title={`Nilai Sumatif Lingkup Materi`}
          extra={[
            action?.sumatif_create && (
              <Button
                type='primary'
                onClick={() => modalForm(true)}
                icon={<PlusOutlined />}>
                Tambah
              </Button>
            ),
            action?.sumatif_export && (
              <Button
                onClick={() => modalExport(true)}
                icon={<DownloadOutlined />}>
                Export
              </Button>
            ),
          ]} />

        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <SearchTable table={table} fetch={fetchData} />
          </Col>
          <Col xs={24}>
            <DesktopOnly
              isFetchFirst={false}
              state={{ table }}
              fetch={fetchData}
              fetchTable={fetchTable}
              columns={columns(table, action, modalForm, modalDelete)}
            />
          </Col>
        </Row>
      </div>
      <BackTop />
      {exportState.visible && (
        <ModalExport
          visible={exportState.visible}
          hideModal={() => modalExport(false)}
          headerData={headerData}
        />
      )}
      {
        formState.visible && (
          <ModalForm
            data={formState.data}
            visible={formState.visible}
            hideModal={() => modalForm(false)}
            fetchTable={fetchTable}
            action={action}
            privilege={privilege}
            headerData={headerData}
          />
        )
      }
      {
        deleteState.visible && (
          <ModalDelete
            data={deleteState.data}
            visible={deleteState.visible}
            hideModal={() => modalDelete(false)}
            fetchTable={fetchTable}
          />
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  privilege: state.privilege,
});

export default connect(mapStateToProps)(PenilaianNilaiMapelDetailSumatif);