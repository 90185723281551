import Action from "./Action";
import { SettingOutlined } from "@ant-design/icons";

const columns = (action, modalForm, modalDelete, modalApproval, modalDetail) => [
  {
    title: 'No',
    dataIndex: 'rownum',
    width: '3%',
  },
  {
    title: 'Siswa',
    render: (a, row) => <>{row.nama ? row.nama : "-"}<br /><small>NISN: {row.nisn ? row.nisn : "-"}</small></>,
  },
  {
    title: 'Jenis Kelamin',
    dataIndex: 'jenis_kelamin',
  },
  {
    title: 'Tahun Ajaran',
    render: (a, row) => <>{row.tahun_ajaran ? row.tahun_ajaran : "-"} | {row.tipe_semester ? row.tipe_semester : "-"}</>,
  },
  {
    title: 'Rombel',
    dataIndex: 'rombel_nama',
  },
  {
    title: 'Jumlah Pelanggaran',
    render: (a, row) => <>{row.total_pelanggaran ? row.total_pelanggaran : "0"}</>,
  },
  {
    title: 'Total Poin',
    dataIndex: 'total_poin',
  },
  {
    title: 'Tindakan',
    render: (a, row) => <>{row.tindakan ? row.tindakan : "-"}</>,
  },
  {
    title: <SettingOutlined />,
    align: "center",
    key: 'operation',
    width: '2%',
    render: (a, row) => <Action row={row} action={action} />,
  },
];

export default columns