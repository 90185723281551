// useForm.js
import { useEffect, useState } from 'react';
import { _setAxios, _success } from 'lib/Helper';

const useForm = (form, headerData, data, fetchTable, hideModal) => {
    const [valuesList, setValuesList] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleSubmit = (val) => {

        const list = valuesList.map(({ ...rest }) => ({
            siswa_id: rest.id,
            nilai: rest.nilai,
          }));

        const params = {
            "tahun_ajaran_id" : headerData?.tahun_ajaran_id,
            "semester_id" : headerData?.semester_id,
            "rombel_id" : headerData?.rombel_id,
            "mapel_id" : headerData?.mapel_id,
            "nama" : val.nama,
            "materi" : val.materi,
            "list" : list
        };

        let endpoint = "penilaian/mapel/sumatif/detail";
        let method = "POST";

        if (data?.id) {
            endpoint = `${endpoint}/${data?.id}`;
            method = "PUT";
        }

        setLoading(true);
        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status) {
                _success('topRight', 'Success', resp.data.message)
                fetchTable(true);
                hideModal();
            }
            setLoading(false);
        }).catch(err => {
            setLoading(false);
        });
    };

    const getSumatifByID = (id) => {
        if (!id) {
            return;
        }
        let endpoint = "penilaian/mapel/sumatif/detail/" + id;
        let method = "GET";

        _setAxios(endpoint, method).then(resp => {
            if (resp.status) {
                form.setFieldsValue({
                    nama: resp.data.data.nama,
                    materi: resp.data.data.materi,
                });
            }
        });
    };

    useEffect(() => {
        getSumatifByID(data?.id);
    }, [data?.id]);

    return {
        valuesList,
        loading,
        setValuesList,
        handleSubmit,
    };
};

export default useForm;
