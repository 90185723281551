import React, { useEffect, useState } from 'react';
import { List, Skeleton, Divider, Col, Row } from 'antd';

const Header = ({ data }) => {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (data) {
            setLoading(false);
        }
    }, [data]);

    return (
        <>
            <div className="site-layout-background" style={{ marginTop: 16 }}>
                {
                    !loading ?
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12}>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={[
                                        {
                                            title: 'Tahun Ajaran',
                                            description: (data?.tahun_ajaran || "-") + " " + (data?.tipe_semester || "-"),
                                        },
                                        {
                                            title: 'Rombel',
                                            description: data?.rombel_nama || "-",
                                        },
                                        {
                                            title: 'Tingkatan Kelas',
                                            description: data?.tingkatan_kelas || "-",
                                        }
                                    ]}
                                    renderItem={item => (
                                        <List.Item>
                                            <List.Item.Meta
                                                title={<><div style={{ float: "left", minWidth: 50 }}>{item.title}</div> : <small style={{ color: "rgb(119 105 105)" }}>{item.description}</small></>}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </Col>
                            <Col xs={24} sm={12}>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={[
                                        {
                                            title: "Wali Kelas",
                                            description: data?.wali_nama || "-",
                                        },
                                        {
                                            title: "Status",
                                            description: data?.nilai_status_edit || "-",
                                        },
                                    ]}
                                    renderItem={item => (
                                        <List.Item>
                                            <List.Item.Meta
                                                title={<><div style={{ float: "left", minWidth: 50 }}>{item.title}</div> : <small style={{ color: "rgb(119 105 105)" }}>{item.description}</small></>}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </Col>
                        </Row>
                        :
                        <Skeleton />
                }
            </div>

            <Divider />
        </>
    );
};
export default Header;
