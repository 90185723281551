import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, BackTop, Breadcrumb } from 'antd';
import { TableList } from 'components/list';
import Filter from '../modal/Filter';
import SearchTable from 'components/search-table/SearchTable';
import { PageHeader } from 'components/header';
import { SortingTableWithFilter } from 'components/sorting-table';
import columns from './Columns';
import useList from '../hooks/useList';
import { DoubleRightOutlined } from '@ant-design/icons';
import AuthRedirect from 'components/AuthRedirect';
import { isAuth } from 'lib/Helper';

const sortFields = [{
  value: "siswa_nama",
  label: "Nama Peserta Didik",
}];

const NotifPresensiPramuka = ({privilege }) => {
  const {
    table,
    filter,
    fetchTable,
    fetchData,
    setTempTableFilter,
    setTableFilterFromTemp,
    modalFilter,
  } = useList(privilege?.tahun_ajaran, privilege?.semester);

  const access = privilege.access["/notification/presensi-pramuka"]
  const action = access.action

  if (!isAuth(privilege) || (access === undefined)) {
      return <AuthRedirect />
  }

  return (
    <>
      <PageHeader
        style={{
          padding: 0
        }}
        onBack={() => window.history.back()}
        title={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
          <Breadcrumb.Item>Notification</Breadcrumb.Item>
          <Breadcrumb.Item>Presensi Pramuka </Breadcrumb.Item>
        </Breadcrumb>}
      />

      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <PageHeader
          className="site-page-header"
          title="Notifikasi Pramuka"
          subTitle="Data notifikasi presensi pramuka"
        />
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={18} md={19}>
            <SearchTable table={table} fetch={fetchData} />
          </Col>
          <Col xs={24} sm={6} md={5}>
            <SortingTableWithFilter
              table={table}
              fetch={fetchData}
              modalFilter={modalFilter}
              sortFields={sortFields}
            />
          </Col>
          <Col xs={24}>
            <TableList
              state={{ table, filter }}
              fetch={fetchData}
              fetchTable={fetchTable}
              columns={columns(action)}
            />
          </Col>
        </Row>
        {filter.visible && (
          <Filter
            visible={filter.visible}
            hideModal={() => modalFilter(false)}
            setTempTableFilter={setTempTableFilter}
            setTableFilterFromTemp={setTableFilterFromTemp}
            values={filter.values}
          />
        )}
        <BackTop />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  privilege: state.privilege,
});

export default connect(mapStateToProps)(NotifPresensiPramuka);