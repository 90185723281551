// useForm.js
import { useState } from 'react';
import { _setAxios, _success } from 'lib/Helper';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

const useForm = (data, fetchTable, hideModal) => {
    const [values, setValues] = useState({
        ...data,
    });
    const [loading, setLoading] = useState(false);

    const handleChangeSetValue = (name, value) => {
        setValues(prevValues => ({ ...prevValues, [name]: value }));
    };

    const handleSubmit = (val) => {

        const params = {
            tahun_ajaran_id: val?.tahun_ajaran_id,
            semester_id: val?.semester_id,
            tingkatan_kelas: val?.tingkatan_kelas,
        };

        let endpoint = "penilaian/kkm/master";
        let method = "POST";

        if (data?.id) { 
            endpoint = `${endpoint}/${data?.id}`;
            method = "PUT";
        }

        setLoading(true);
        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status) {
                _success('topRight', 'Success', resp.data.message)
                fetchTable(true);
                hideModal();
            }
            setLoading(false);
        }).catch(err => {
            setLoading(false);
        });
    };

    return {
        values,
        loading,
        handleChangeSetValue,
        handleSubmit,
    };
};

export default useForm;
