import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { _setAxios } from '../../lib/Helper';

async function fetchList() {

  return await _setAxios("tatibsi/pelanggaran/table", "POST", {
    "pagination": {
      "current": 1,
      "pageSize": 10000,
    },
    "search": "",
    "filtering": {
      "tahun_ajaran": null
    },
    "sorting": [
      {
        "field": "id",
        "sort": "DESC",
        "urutan": "1"
      }
    ]
  })
    .then(body => {
      let results = []
      body.data?.list?.forEach(row => {
        results.push({
          label: "[" + row.kode + "] " + row.pelanggaran,
          data: row,
          value: row.id,
        })
      });

      return results
    })
}

const SelectPelanggaranSiswa = ({ ...props }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    fetchList().then((newOptions) => {
      setOptions(newOptions);
    });
  }, []);

  return (
    <Select
      {...props}
      allowClear
      placeholder="Pilih pelanggaran"
      style={{
        width: '100%',
      }}
      options={options}
    />
  );
};
export default SelectPelanggaranSiswa;
