// useForm.js
import { _setAxios, _success } from 'lib/Helper';
import { useState } from 'react';

const useForm = (headerData, table, setTable, fetchTable) => {
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState([]);
    const [isFirstSetValues, setIsFirstSetValues] = useState(true);
    const [headValues, setHeadValues] = useState({});

    const handleInputChange = (key, field, value) => {
      // Pastikan ada properti `list` dan `list` adalah array
      const list = Array.isArray(table.data) ? table.data : [];
  
      // Perbarui data di dalam `list`
      const updatedList = list.map((item) => {
        if(key === 0){
          setHeadValues({ ...headValues, [field]: value })
          return { ...item, [field]: value }
        }
        return item.id === key ? { ...item, [field]: value } : item
      });
  
      setTable({
        ...table,
        data: updatedList
      })

      if(isFirstSetValues){
        setValues(updatedList);
        setIsFirstSetValues(false);
        return
      }

      // mapping karena updatedList bisa jadi datanya lebih sidikit dari values
      const updatedValues = values.map((item) => {
        return updatedList.find((row) => row.id === item.id) || item
      });

      setValues(updatedValues);

    };
  
    const handleSubmit = () => {
      const siswa = values.map(({ ...rest }) => ({
        siswa_id: rest.id,
        nilai: rest.nilai
      }));

      const param = {
        tahun_ajaran_id: headerData?.tahun_ajaran_id,
        semester_id: headerData?.semester_id,
        rombel_id: headerData?.rombel_id,
        mapel_id: headerData?.mapel_id,
        list: siswa
      };
  
      let endpoint = "penilaian/mapel/pas";
      let method = "POST";

      setLoading(true);
      _setAxios(endpoint, method, param).then(resp => {
          if (resp.status) {
              _success('topRight', 'Success', resp.data.message)
              fetchTable(true);
          }
          setLoading(false);
      }).catch(err => {
          setLoading(false);
      });
    };

    return {
        loading,
        headValues,
        values,
        handleInputChange,
        handleSubmit,
    };
};

export default useForm;
