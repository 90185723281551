import React, { useEffect, useState } from 'react';
import { List, Skeleton, Divider, Col, Row, Card } from 'antd';
import BobotForm from './BobotForm';
import NilaiKKM from './NilaiKKM';

const Header = ({ data, bobotData, kkmData }) => {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (data) {
            setLoading(false);
        }
    }, [data]);

    return (
        <>
            <div className="site-layout-background">
                {
                    !loading ?
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={8}>
                                <Card title="Info" bordered={false} style={{ minHeight: 435 }}>
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={[
                                            {
                                                title: 'Tahun Ajaran',
                                                description: (data?.tahun_ajaran || "-") + " " + (data?.tipe_semester || "-"),
                                            },
                                            {
                                                title: 'Mata Pelajaran',
                                                description: data?.mapel_nama || "-",
                                            },
                                            {
                                                title: 'Rombel',
                                                description: data?.rombel_nama || "-",
                                            },
                                            {
                                                title: 'Kurikulum',
                                                description: data?.kurikulum_nama || "-",
                                            },
                                            {
                                                title: "Jumlah Siswa",
                                                description: data?.total_siswa || "0",
                                            },
                                            {
                                                title: "Wali Kelas",
                                                description: data?.wali_rombel || "-",
                                            },
                                        ]}
                                        renderItem={item => (
                                            <List.Item>
                                                <List.Item.Meta
                                                    title={<><div style={{ float: "left", minWidth: 50 }}>{item.title}</div> : <small style={{ color: "rgb(119 105 105)" }}>{item.description}</small></>}
                                                />
                                            </List.Item>
                                        )}
                                    />
                                </Card>
                            </Col>
                            <Col xs={24} sm={8}>
                                <Card title="Bobot" bordered={false} style={{ minHeight: 435 }}>
                                    {bobotData && <BobotForm bobotData={bobotData} />}
                                </Card>
                            </Col>
                            <Col xs={24} sm={8}>
                                <Card title="Nilai KKM" bordered={false} style={{ minHeight: 435 }}>
                                    <NilaiKKM kkmData={kkmData} />
                                </Card>
                            </Col>
                        </Row>
                        :
                        <Skeleton />
                }
            </div>

            <Divider />
        </>
    );
};
export default Header;
