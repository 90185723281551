import React from 'react';
import { Modal, Button } from 'antd';

import { _success, _setAxios, _warn } from '../../../../../lib/Helper';
import { InfoCircleOutlined } from '@ant-design/icons';

class ConfirmTambahAnggota extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false
        };
    }

    handleSubmit = () => {
        if(!this.props.data.rombel_id) {
            _warn('topRight', 'Warning', 'Pilih rombel terlebih dahulu');
            return
        }
        if(!this.props.data.ekstrakurikuler_id) {
            _warn('topRight', 'Warning', 'Pilih ekstrakurikuler terlebih dahulu');
            return
        }
        
        const { data } = this.props
        this.setState({
            loading: true
        });

        let payload = {
            "ekstrakurikuler_id": data.ekstrakurikuler_id,
            "siswa": []
        }

        data?.siswa_ids?.forEach(siswaId => {
            payload.siswa.push({
                siswa_id: siswaId,
                rombel_id: this.props.data.rombel_id
            })
        });

        let ekstrakurikuler_id = this.props.data.ekstrakurikuler_id

        _setAxios("kaldik-kegiatan/ekstrakurikuler/anggota", "POST", this.props.data).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                setTimeout(function () {
                    window.location.href = '/ekstrakurikuler-pramuka/ekstrakurikuler/detail/' + ekstrakurikuler_id;
                }, 1000);
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    render() {

        return (
            <Modal
                maskClosable={false}
                title={<><InfoCircleOutlined /> Konfirmasi</>}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                width={300}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading}>Simpan</Button>
                </>}
            >
                Data akan ditrambahkan ke ekstrakurikuler, Apakah Anda Yakin?
            </Modal>
        )
    }
}

export default ConfirmTambahAnggota;
