import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, BackTop, Button, Alert, Divider, Input, Typography } from 'antd';
import { TableList } from 'components/list';
import { PageHeader } from 'components/header';
import columns from './Columns';
import useList from '../hooks/useList';
import useForm from '../hooks/useForm';
import SearchTable from 'components/search-table/SearchTable';
import { MobileView } from 'react-device-detect';
const { Title } = Typography;

const PenilaianWalikelasCatatan = ({ privilege, action, headerData }) => {
  const {
    table,
    setTable,
    fetchTable,
    fetchData,
  } = useList(privilege, headerData);

  const {
    loading: loadingBtn,
    headValues,
    values,
    handleInputChange,
    handleSubmit
  } = useForm(privilege, headerData, table, setTable, fetchTable);

  const fetchDataUpdate = (param, isNew) => {
    fetchData(param, isNew, headValues);
  }
  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Catatan Wali Kelas"
        subTitle="Data catatan wali kelas"
      />
      <Alert
        message="Pastikan untuk menyimpan perubahan sebelum melanjutkan agar data yang telah dimasukkan tidak hilang."
        type="info"
        showIcon
      />
      <Divider />
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <SearchTable table={table} fetch={fetchDataUpdate} />
        </Col>
        <Col xs={24}>
          <MobileView style={{ textAlign: "center" }}>
            <Title level={5}>Perbarui Massal</Title>
            <Input
              style={{ width: "100%" }}
              placeholder="catatan"
              onChange={(e) => handleInputChange(0, 'catatan', e.target.value)}
            />
            <Divider />
          </MobileView>
          <TableList
            state={{ table }}
            fetch={fetchDataUpdate}
            fetchTable={fetchTable}
            columns={columns(action, handleInputChange)}
            isPagination={false}
          />
        </Col>
        <Col xs={24} style={{ textAlign: "center" }}>
          <Button type="primary" onClick={handleSubmit} loading={loadingBtn} disabled={loadingBtn || values.length === 0} style={{ marginTop: 16 }}>
            Simpan Perubahan
          </Button>

        </Col>
      </Row>
      <BackTop />
    </>
  );
};

const mapStateToProps = (state) => ({
  privilege: state.privilege,
});

export default connect(mapStateToProps)(PenilaianWalikelasCatatan);