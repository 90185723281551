import React, { useEffect } from 'react';
import { MobileView, BrowserView } from 'react-device-detect';
import { Popover, Button, Divider } from 'antd';
import { FilterOutlined, SortAscendingOutlined } from '@ant-design/icons';

import SortingTable from './SortingTable';
import { _scrollFloatBtn } from '../../lib/Helper';

const FilterTable = ({ table, sortFields, fetch, modalFilter }) => {

  useEffect(() => {
    _scrollFloatBtn();
  }, []);

  return (
    <>
      <BrowserView>
        <Button.Group className='ant-btn-group-fullwidth'>
          <Button
            className='btn-border-radius-left'
            block
            onClick={() => modalFilter(true)}
          >
            <FilterOutlined /> Filter
          </Button>
        </Button.Group>
      </BrowserView>
      <MobileView>
        <div className='float-btn-center' id='float-btn-center' align='center'>
          <div className="float-btn-div">
            <Button
              type='primary'
              className='btn-border-radius-left float-btn'
              onClick={() => modalFilter(true)}
            >
              <FilterOutlined />Filter
            </Button>
          </div>
        </div>
      </MobileView>
    </>
  );
}

export default FilterTable;
